import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { Exercicios2Props } from "../../interfaces/exercicio.interface";
import {
	PacientesProps,
	UserProps,
} from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "./../../utils/types";
import { DashBoardActions, Types } from "../../store/ducks/dashboard";
import { loadStorage, persistStorage } from "../../utils/base_async_storage";
import { ExerciciosActions } from "../ducks/exer";
import { ManageProtocolActions } from "../ducks/manage_protocols";
import { AppActions } from "../ducks/app";
import { getTimeExecFunction, getTimeout } from "../../utils/funcs";
import { getDatabase, ref, push } from "firebase/database";
import moment from "moment";
import * as Sentry from "@sentry/react";
import { Console } from "console";
import { TextsProps } from "../../database/txt.interface";

function* getAvaliadores(): any {
	let status = 0;
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const parametros = yield select(
		(state: any) => state.dashboard.filtros_avaliadores_request
	);

	

	parametros["TOKEN"] = user_logado.token;

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(parametros),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_usuarios_filtro.php`,
				requestOptions
			);
			status = res.status;
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_usuarios_filtro.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);

		if (status == 200) {
			yield put(DashBoardActions.set_avaliadores(response.usuarios));
			yield put(DashBoardActions.total_avaliadores(response.total));
		} else {
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: "Erro ao buscar avaliadores " + status,
				})
			);
		}
	} catch (e) {
		console.log("Erro ao sincronizar avaliadores", e);
	}
}

function* getPacientes(): any {
	let status = 0;
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const patientEtiqueta = yield select((state)=> state.app.set_patient_in_etiqueta);
	const parametros = yield select(
		(state: any) => state.dashboard.filtros_pacintes_request
	);
	parametros["TOKEN"] = user_logado.token;

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(parametros),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_usuarios_filtro.php`,
				requestOptions
			);
			status = res.status;
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_usuarios_filtro.php", "error");
			throw e;
		}
	}
	
	try {
		const response = yield call(apiCall);
		yield put(DashBoardActions.set_button_search(false));
		if (status == 200) {
			if(patientEtiqueta){
				yield put(AppActions.set_get_patient_in_etiqueta(false, response.usuarios))
			}else{
				yield put(DashBoardActions.paciente_success(response.usuarios));
				if(response.total > 1){
					yield put(DashBoardActions.total_pacientes(response.total));
				}
			}			
		} else {
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: "Erro ao buscar pacientes " + status,
				})
			);
		}
	} catch (e) {
		yield put(DashBoardActions.paciente_stop_request());
	}
}

function* getPacientesNotification(): any {
	let status = 0;
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const parametros = yield select(
		(state: any) => state.dashboard.filtros_pacintes_notification_request
	);
	parametros["TOKEN"] = user_logado.token;
	
	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(parametros),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_usuarios_filtro.php`,
				requestOptions
			);
			status = res.status;
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_usuarios_filtro.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		if (status == 200) {
			yield put(DashBoardActions.paciente_notification_success(response.usuarios));
		} else {
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: "Erro ao buscar pacientes " + status,
				})
			);
		}
	} catch (e) {
		yield put(DashBoardActions.paciente_stop_request());
	}
}

function* getMaisVisitados(): any {
	let status = 0;
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const parametros = {
		TOKEN: user_logado.token,
		limite: 10,
	};

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(parametros),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_acessos_pacientes.php`,
				requestOptions
			);
			status = res.status;
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_acessos_pacientes.php", "error");
			throw e;
		}
	}

	try {
		const response = yield call(apiCall);
		if (status == 200) {
			yield put(
				DashBoardActions.set_pacientes_mais_visitados(
					response.config_lista_usuarios
				)
			);
		} else {
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: "Erro ao buscar pacientes mais visitados" + status,
				})
			);
		}
	} catch (e) {
		console.log("Erro ao buscar pacientes mais visitados", e);
	}
}

function* getProtocolos(): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
				PROTOCOLO_LIBERADO: true
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_protocolos.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_protocolos.php", "error");
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		yield put(ManageProtocolActions.set_protocolos(data.protocolos));
		yield put(DashBoardActions.set_search_protocol(data.protocolos));
		yield put(DashBoardActions.call_categorias_protocolos())
	} catch (e) {}
}

function* getExercises() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_exercicios.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_exercicios.php", "error");
			throw e;
		}
	}

	try {
		const { exercicios, agrupamentos } = yield call(apiCall);
		const exerNotDeleted = exercicios.filter(
			(i: Exercicios2Props) => i.EXERCICIO_EXCLUIDO == false
		);
		const exerDeleted = exercicios.filter(
			(i: Exercicios2Props) =>
				i.EXERCICIO_EXCLUIDO == true && i.ID_MUSCULO != null
		);
		yield put(ExerciciosActions.set_exercicios(exerNotDeleted));
		yield put(ExerciciosActions.set_search_exercise(exerNotDeleted));
		yield put(ExerciciosActions.set_exercicios_deletado(exerDeleted));
		yield put(DashBoardActions.set_agrupamentos(agrupamentos));
	} catch (e) {}
}

function* getExecucoes() {
	interface Request{
		TOKEN: string,
		EXCLUIDAS?: string
		ID_USUARIO?: number
	}
	const TimeInitial = new Date().getTime();
	yield put(DashBoardActions.execucoes_request_loading(true));
	yield put(DashBoardActions.execucoes_request_sucess([]));

	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const paciente: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	const execucoes_excluidas: PacientesProps = yield select(
		(state: any) => state.dashboard.execucoes_excluidas
	);
	async function apiCall() {
		try {
			const data:Request = {
				TOKEN: user_logado.token,
			}
			if(execucoes_excluidas){
				data.EXCLUIDAS = "S"
			}else{
				data.ID_USUARIO = paciente.ID_USUARIO
			}
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_execucoes.php`,
				requestOptions
			);
			if (res.status == 400) {
				try {
					const dataAtual = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
					const reference = getDatabase();
					push(ref(reference, "Sync_execucoes"), {
						current_date: dataAtual,
						status: `${res.status}`,
					});
				} catch (error) {
					Sentry.captureMessage("CAIU NO CATCH: sync_execucoes.php", "error");
					console.log(error);
				}
			}

			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const { exibir, ocultar } = yield call(apiCall);
		yield put(DashBoardActions.execucoes_request_sucess(exibir));
	} catch (e) {
		yield put(DashBoardActions.execucoes_request_loading(false));
	} finally {
		yield put(DashBoardActions.execucoes_request_loading(false));
	}
	const TimeFinal = new Date().getTime();

	getTimeExecFunction(
		TimeInitial,
		TimeFinal,
		"Function_getExecucoes",
		user_logado
	);
	getTimeout(
		TimeInitial,
		TimeFinal,
		"TIMEOUT_Function_getExecucoes",
		user_logado?.ID_USUARIO
	);
}

function* getEtiquetas() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_etiquetas.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_etiquetas.php", "error");
			throw e;
		}
	}

	try {
		const { etiquetas } = yield call(apiCall);
		yield put(DashBoardActions.etiquetas_request_success(etiquetas));
	} catch (e) {}
}

function* getNotificacoes() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const language: string = yield select((state: any) => state.configs.language);

	let date = "0000-00-00 00:00:00";
	const txSyncDataHora: string = yield call(
		loadStorage,
		"SyncDataHoraNotifications"
	);

	if (txSyncDataHora) {
		date = txSyncDataHora;
	}
	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
				SyncDataHora: date,
				txIdioma: language?.toLowerCase(),
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_notificacoes.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_notificacoes.php", "error");
			throw e;
		}
	}
	interface ApiCallProps {
		SyncDataHora: string;
		notificacoes: [];
	}

	try {
		const { SyncDataHora, notificacoes }: ApiCallProps = yield call(apiCall);

		const exist_notifications: [] = yield call(loadStorage, "notifications");

		if (exist_notifications) {
			const old_notifications = exist_notifications.map((element: any) => {
				return {
					...element,
					IS_NEW: false,
				};
			});
			const new_notifications = notificacoes.map((element: any) => {
				return {
					...element,
					IS_NEW: true,
				};
			});
			yield put(
				DashBoardActions.notificacoes_request_success([
					...new_notifications,
					...old_notifications,
				])
			);
			if (new_notifications.length > 0) {
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast:
							new_notifications.length > 0 &&
							new_notifications[0].NOTIFICACAO_TITULO,
					})
				);
			}
			yield call(persistStorage, {
				res: [...new_notifications],
				tag: "notifications",
			});
		} else {
			const new_notifications = notificacoes.map((element: any) => {
				return {
					...element,
					IS_NEW: true,
				};
			});
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast:
						new_notifications.length > 0 &&
						new_notifications[0].NOTIFICACAO_TITULO,
				})
			);
			yield put(
				DashBoardActions.notificacoes_request_success(new_notifications)
			);

			yield call(persistStorage, {
				res: new_notifications,
				tag: "notifications",
			});
		}
		if (SyncDataHora) {
			yield call(persistStorage, {
				res: SyncDataHora,
				tag: "SyncDataHoraNotifications",
			});
		}
	} catch (e) {
		yield put(DashBoardActions.notificacoes_request_success([]));
	}
}

function* getAssimetria() {
	yield put(DashBoardActions.assimetria_request_loading(true));

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const paciente: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
				ID_USUARIO: paciente.ID_USUARIO,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_assimetrias_unificado.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_assimetria.php", "error");
			throw e;
		}
	}

	try {
		const { assimetria, desequilibrios_musculares } = yield call(apiCall);
		yield put(DashBoardActions.assimetria_request_success(assimetria));
		yield put(DashBoardActions.desequilibrios_request_success(desequilibrios_musculares));
	} catch (e) {
		yield put(DashBoardActions.assimetria_request_loading(false));
	} finally {
		yield put(DashBoardActions.assimetria_request_loading(false));
	}
}

function* getAnamneses() {
	yield put(DashBoardActions.anamneses_request_loading(true));

	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const paciente: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

	interface ConfigState {
		configs: {
			language: string;
		};
	}

	const language: string = yield select(
		(state: ConfigState) => state.configs.language
	);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
				ID_USUARIO: paciente.ID_USUARIO,
				txIdioma: language,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_anamneses.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_anamneses.php", "error");
			throw e;
		}
	}

	try {
		const { anamneses } = yield call(apiCall);

		yield put(DashBoardActions.anamneses_request_success(anamneses));
	} catch (e) {
		yield put(DashBoardActions.anamneses_request_loading(false));
	} finally {
		yield put(DashBoardActions.anamneses_request_loading(false));
	}
}

function* getAnotacoes() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_anotacoes.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_anotacoes.php", "error");
			throw e;
		}
	}

	try {
		const { exibir } = yield call(apiCall);
		yield put(DashBoardActions.anotacoes_request_success(exibir));
	} catch (e) {}
}

function* getBlog() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}inicio/inicio_blog.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: inicio_blog.php", "error");
			throw e;
		}
	}

	try {
		const { posts } = yield call(apiCall);
		yield put(DashBoardActions.set_blog(posts));
	} catch (e) {}
}

function* getDinamos() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_dinamos.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_dinamos.php", "error");
			throw e;
		}
	}

	try {
		const { dinamos } = yield call(apiCall);
		yield put(DashBoardActions.dinamos_request_success(dinamos));
	} catch (e) {}
}

function* getPlanos() {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	async function apiCall() {
		try {
			const da = {
				TOKEN: user_logado.token,
			};
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(da),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}sync/sync_planos.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: sync_planos.php", "error");
			throw e;
		}
	}

	try {
		const { planos } = yield call(apiCall);
		yield put(DashBoardActions.planos_request_success(planos));
	} catch (e) {}
}

function* getCategoriesProtocols (){
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
		async function apiCall() {
			try {
				const useToken = {
					TOKEN: user_logado.token,
				};
				const requestOptions: any = {
					method: "POST",
					body: JSON.stringify(useToken),
				};
				const res = await fetch(
					`${process.env.REACT_APP_BASE_URL}sync/sync_categorias_protocolos.php`,
					requestOptions
				)
				
				const response: any = await res.json()
				return response
			} catch (e) {
				throw e;
			}
		}
	

		try{
			const data:any[] = yield call(apiCall);
			yield put(DashBoardActions.set_categorias_protocolos_in_modal_filter_protocol(data))
			
		}catch(error){
			console.log("🚀 ~ file: dashboard.ts:321 ~ function*getCategoriesProtocols ~ error:", error)
		}
};

function* AtribuirCategoriaProtocolos ({payload}:any){
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const data = {
		TOKEN: user_logado.token,
		txDataHora: payload?.txDataHora,
		ID_USUARIO: payload?.id_usuario,
		CATEGORIAS_PROTOCOLOS: payload?.id_categorias
	};
	async function apiCall() {
		try {

			
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/protocolos/protocolos_categorias_atribuir.php`,
				requestOptions
			)
			const response: any = await res.json()
			return response
		} catch (e) {
			throw e;
		}
	}

	try{
		const data: {
			code: number,
			message: string,
			status: string,
		} = yield call(apiCall);

		
		if(data?.status === TypesErrorRequest.SUCCESS){
			yield put(DashBoardActions.search_categorias_atribuidas(payload?.id_usuario))
		}
	}catch(error){
		console.log("🚀 ~ file: dashboard.ts:321 ~ function*getCategoriesProtocols ~ error:", error)
	}

};

function* SearchCategoriasAtribuidas ({payload}:any){
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const config_servicos:{SERVICO_NOVOS_PROTOCOLOS: boolean} = yield select(
		(state: any) => state.configs.config_servicos
	);

	async function apiCall() {
		try {
			const data = {
				TOKEN: user_logado.token,
				ID_USUARIO:payload
			}
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/sync/sync_categorias_atribuidas.php`,
				requestOptions
			)
			const response: any = await res.json()
			return response
			
		} catch (e) {
			throw e;
		}
	}

		try{
			const data:{categorias_atribuidas:number[]} = yield call(apiCall);
			if(data?.categorias_atribuidas){
				yield put(DashBoardActions.get_categorias_atribuidas(data?.categorias_atribuidas))
			}
			if(data?.categorias_atribuidas?.length == 0 && config_servicos?.SERVICO_NOVOS_PROTOCOLOS){
				yield put(ManageProtocolActions.set_modal_filter_protocol(true))
			}
		}catch(error){
			console.log("🚀 ~ file: dashboard.ts:384 ~ function*SearchCategoriasAtribuidas ~ error:", error)
		}

}

function* GetVerificarQuestionarioSatisfacao ({payload}: any){

	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const amount_executions: number = yield select(
		(state: any) => state.dashboard.set_quantidade_execucoes,
    );
	const questionarioSatisfacaoExameRespondidoExame1: boolean = yield select((state: any)=> state.dashboard.resposta_questionario_satisfacao?.EXAME_1);
	const questionarioSatisfacaoExameRespondidoExame2: boolean = yield select((state: any)=> state.dashboard.resposta_questionario_satisfacao?.EXAME_2);

	async function apiCall() {
		const data = {
			TOKEN: user_logado.token,
			txTipo: payload?.txTipo,
			RESPOSTA_ID_TIPO: payload?.resposta_id_tipo
		}
		const dataExameSatisfacao = {
			TOKEN: user_logado.token,
			txTipos: payload?.txTipos
		}
		const requestOptionsProtocolo: any = {
			method: "POST",
			body: JSON.stringify(data),
		};
		const requestOptionSatisfacaoExame: any = {
			method: "POST",
			body: JSON.stringify(dataExameSatisfacao),
		};
		try {
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/questionarios/questionario_satisfacao_verificar.php`,
				payload?.resposta_id_tipo ? requestOptionsProtocolo : requestOptionSatisfacaoExame
			)
			const response: any = await res.json()
			return response
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: questionario_satisfacao_verificar.php", "error");
			throw e;
		}
	};

	try{
		const data:{
			status: string, 
			respondido: {EXAME_1: boolean, EXAME_2: boolean}, 
			resposta: {
				EXAME_1: [{RESPOSTA_SATISFACAO_CRIADO: string}], 
				EXAME_2: [{RESPOSTA_SATISFACAO_CRIADO: string}],
			}
		} = yield call(apiCall)
		if(data?.status == TypesErrorRequest?.SUCCESS){
			
			if(payload?.txTipo  === 'RELATORIO_UNIFICADO'){
				if(!data?.respondido){
					yield put (DashBoardActions.set_modal_satisfaction_unified_report(true))
				}
			}else{
				yield put (DashBoardActions.set_info_questionario_satisfacao(data?.respondido, data?.resposta))
			}

			const Now = moment().format('YYYY-MM-DD HH:mm:ss') 
				const usuario_criado = moment(user_logado?.USUARIO_CRIADO)
				const currentMoment = moment(Now)
				const dif = currentMoment.diff(usuario_criado, 'day')

				if(data?.respondido?.EXAME_1 != null && !data?.respondido?.EXAME_2 != null){

					if(
						(dif < 35)|| (!!data?.respondido?.EXAME_1 && !data?.respondido?.EXAME_2  )
					){
						yield put(DashBoardActions.set_get_amount_executions())
					}

					const today = moment().format('YYYY-MM-DD HH:mm:ss');
					const todayMoment = moment(today);
					const data_resposta_satisfacao = moment(data?.resposta?.EXAME_1[0]?.RESPOSTA_SATISFACAO_CRIADO);
					const difDays = todayMoment?.diff( data_resposta_satisfacao, 'days')
			
					if(amount_executions >= 2 && questionarioSatisfacaoExameRespondidoExame1 == false){
						yield put(AppActions.set_modal_satisfacao_exame(true, 'EXAME_1')) 

					}else if(amount_executions >= 3 && difDays >= 3 && questionarioSatisfacaoExameRespondidoExame2 == false) {
						yield put(AppActions.set_modal_satisfacao_exame(true, 'EXAME_2')) 
					}
				}


		}

	}catch(error){
		Sentry.captureMessage("CAIU NO CATCH: questionario_satisfacao_verificar.php", "error");
	}

};

function* SalvarQuestionarioSatisfacao({payload}: any){
	const current_language: TextsProps = yield select(
		(state) => state.configs.current_language,
	);
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const data = {
		TOKEN: user_logado.token,
		txDataHora: payload?.txDataHora , 
		txTipo: payload?.txTipo,
		RESPOSTA_ID_TIPO: payload?.RESPOSTA_ID_TIPO,
		RESPOSTA_VALOR: payload?.RESPOSTA_VALOR,
		RESPOSTA_COMPLEMENTO: payload?.RESPOSTA_COMPLEMENTO,
	};

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/questionarios/questionario_satisfacao_salvar.php`,
				requestOptions
			)
			const response: any = await res.json()
			return response
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: questionario_satisfacao_salvar.php", "error");
			throw e;
		}
	};
	
	try{
		const data:{status:string, message: string} = yield call(apiCall)
		if(data?.status == TypesErrorRequest?.SUCCESS){
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.SUCCESS,
					message_toast:	current_language?.questionario_respondido_success
				})
			);
		}else{
			yield put(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast:	current_language?.questionario_respondido_failure
				})
			);
		}
	}catch(error){
		Sentry.captureMessage("CAIU NO CATCH: questionario_satisfacao_salvar.php", "error");
	}
}

function* GetAmountOfExecutions (payload: {payload:number, type: string}){
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	
	const data = {
		TOKEN: user_logado.token,
	}

	async function apiCall() {
		try {
			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(data),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}/sync/sync_quantidade_execucoes.php`,
				requestOptions
			)
			const response: any = await res.json()
			return response
		} catch (e) {
			Sentry.captureMessage("CAIU NO CATCH: quantidade_execucoes.php", "error");
			throw e;
		}
	};

	try{
		const data:{quantidade_avaliacoes:number} = yield call(apiCall)
		yield put (DashBoardActions.set_amount_executions(data?.quantidade_avaliacoes))	
	}catch(error){
		Sentry.captureMessage("CAIU NO CATCH: quantidade_execucoes.php", "error"), error
	}

}



function* getExerciciosAvaliados (){
	const user_logado: UserProps = yield select((state: any) => state.auth.user);

	const patient: PacientesProps = yield select(
		(state: any) => state.app.is_open_paciente
	);

		async function apiCall() {
			try {
				const useToken = {
					TOKEN: user_logado.token,
					ID_USUARIO: patient.ID_USUARIO
				};
				const requestOptions: any = {
					method: "POST",
					body: JSON.stringify(useToken),
				};
				const res = await fetch(
					`${process.env.REACT_APP_BASE_URL}sync/sync_exercicios_executados.php`,
					requestOptions
				)
				
				const response: any = await res.json()
				return response
			} catch (e) {
				throw e;
			}
		}
	

		try{
			const data:any[] = yield call(apiCall);
			yield put(ExerciciosActions.set_exercicios_avaliados(data))
			
		}catch(error){
			Sentry.captureMessage("CAIU NO CATCH: sync_exercicios_executados.php", "error"), error
		}
};


export function* DashBoardSaga(): any {
	return yield all([
		takeLatest(Types.PACIENTE_REQUEST, getPacientes),
		takeLatest(Types.PACIENTE_NOTIFICATION_REQUEST, getPacientesNotification),
		takeLatest(Types.AVALIADORES_REQUEST, getAvaliadores),
		takeLatest(Types.PROTOCOLOS_REQUEST, getProtocolos),
		takeLatest(Types.EXERCICES_REQUEST, getExercises),
		takeLatest(Types.EXECUCOES_REQUEST, getExecucoes), //
		takeLatest(Types.ETIQUETAS_REQUEST, getEtiquetas),
		takeLatest(Types.NOTIFICACOES_REQUEST, getNotificacoes),
		takeLatest(Types.ASSIMETRIA_REQUEST, getAssimetria), //
		takeLatest(Types.ANAMNESES_REQUEST, getAnamneses), //
		takeLatest(Types.ANOTACOES_REQUEST, getAnotacoes),
		takeLatest(Types.EXERCICIOS_AVALIADOS_REQUEST, getExerciciosAvaliados),
		takeLatest(Types.DINAMOS_REQUEST, getDinamos),
		takeLatest(Types.BLOG, getBlog),
		takeLatest(Types.PLANOS_REQUEST, getPlanos),
		takeLatest(Types.PACIENTES_MAIS_VISITADOS_REQUEST, getMaisVisitados),
		takeLatest(Types.CATEGORIAS_PROTOCOLOS, getCategoriesProtocols),
		takeLatest(Types.ATRIBUIR_CATEGORIA_PROTOCOLOS, AtribuirCategoriaProtocolos),
		takeLatest(Types.SEARCH_CATEGORIAS_ATRIBUIDAS, SearchCategoriasAtribuidas),
		takeLatest(Types.SET_QUESTIONARIO_SATISFACAO_VERIFICAR, GetVerificarQuestionarioSatisfacao),
		takeLatest(Types.SALVAR_QUESTIONARIO_SATISFACAO,SalvarQuestionarioSatisfacao ),
		takeLatest(Types.GET_QUANTIDADE_EXECUCOES, GetAmountOfExecutions)
	]);
}
