import React, { useEffect } from "react";
import styled from "styled-components";
import { ContainerDash, Icon } from "../../../styles/global";
import { useDispatch, useSelector } from "react-redux";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TextsProps } from "../../../database/txt.interface";
import InputPesquisaProtocolo from "../../DashProtocolos/InputPesquisaProtocolo/InputPesquisaProtocolo";
import ListagemProtocolosInPaciente from "./ListagemProtocolos";
import { FilterButton } from "../../../styles/styles_input_pesquisas";
import Filter from "../../../assets/icons/filter.svg";
import { P3Desk } from "../../../styles/styles_text";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { Colors } from "../../../styles/colors";

const ProtocolsInPatient: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	useEffect(() => {
		dispatch(
			DashBoardActions.search_categorias_atribuidas(paciente?.ID_USUARIO)
		);
	}, [paciente?.ID_USUARIO]);

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<ContainerOptions>
				<OptionSelected>
					{current_language?.menu_lateral_protocolos}
				</OptionSelected>
				<Option
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("exercises_in_patient"))
					}
				>
					{current_language?.exercicios}
				</Option>
			</ContainerOptions>
			<DivInputFilter>
				<InputPesquisaProtocolo />
				<FilterButton
					style={{ marginTop: "1.5rem" }}
					src={Filter}
					onClick={() =>
						dispatch(ManageProtocolActions.set_modal_filter_protocol(true))
					}
				/>
			</DivInputFilter>
			<ListagemProtocolosInPaciente />
		</ContainerDash>
	);
};

const ContainerOptions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 60%;
`;

const Option = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 38.4px;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	border-bottom: 3px solid #dedfe3;
	color: #000e4b;
`;

const OptionSelected = styled.text`
	font-family: "NotoSans-regular";
	font-size: 1.2rem;
	font-weight: 700;
	line-height: 38.4px;
	padding-right: 1.5rem;
	border-bottom: 3px solid #00d084;
	color: #000e4b;
`;

const DivInputFilter = styled.div`
	width: 60%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

export default ProtocolsInPatient;
