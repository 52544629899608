import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	display: flex;
	width: 1000%;
	height: 100%;
	overflow-y: scroll;
	align-items: center;
	flex-direction: column;
	
	.Empty{
		color: ${Colors.primary_blue_k_main};
		font-size: 22px;
		font-family: "NotoSans-Regular";
	}
	

	
	@media (max-width: 1366px) {
		padding: 2rem;
	}

	.itensExcluidos {
		background-color: transparent;
		color: #8d8d8d;
		font-size: medium;
	}

	.vermelho {
		color: ${Colors.tertirary_red_1_dark};
	}

	.semExcluido {
		width: 100%;
		height: 100%;

		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		color: #8d8d8d;
		font-size: 20px;
		font-weight: 500;
	}

	.conteudo {
		width: 100%;
	}

	.body {
		width: 100%;
		height: 80%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}

	.left {
		display: flex;
	}

	.mid {
		margin-left: 2.4%;
		width: 75%;
		cursor: pointer;
	}

	.usuario {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}
	.usuario2 {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}

	.usuarioAzul {
		display: flex;
		flex-direction: row;
		background-color: rgba(0, 196, 255, 0.05);
		width: 100%;
		padding: 1.1rem;
		border-radius: 1.1rem;
	}

	.avaliador {
		font-weight: 400;
	}

	.nome {
		font-size: 1.25rem;
		margin-bottom: 1.5%;
		cursor: pointer;
	}

	.tags {
		width: 100%;
		margin-top: 2%;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.right {
		display: flex;
		width: 25%;
		flex-direction: column;
	}

	.hr {
		margin: 0.1rem 0;
		border: none;
		height: 0.063rem;
		background-color: #e5e5e5;
	}

	.PacienteEdata {
		width: 90%;
		height: 2rem;
	}

	.Divpaciente {
		width: 100%;
		display: flex;
		flex-direction: row;
	}

	.paciente {
		font-size: 1rem;
		color: #5c5b5b;
		font-weight: 400;
	}

	.divOpcoes {
		width: 10%;
		height: 50%;
		display: flex;
		flex-direction: column;
		float: inline-end;
	}

	.botaoPontos {
		margin-top: 2rem;
		background-color: transparent;
		border: none;
	}

	.pontos {
		width: 1.25rem;
		height: 1.25rem;
		margin-top: 5%;
		float: right;
	}

	.user {
		width: 1rem;
		height: 1rem;
		margin-right: 0.5rem;
	}

	.dia {
		margin-top: 5%;
	}

	.btnAdicionar {
		margin-top: 4.8rem;
		display: flex;
		flex-direction: row;
		width: 18.5rem;
		height: 5.6rem;
		justify-content: center;
		align-items: center;
		border-radius: 2.8rem;
		background-color: white;
		border: none;
		box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);

		@media (max-height: 900px) {
			margin-top: 1.5rem;
		}
	}

	.textoBotao {
		margin-left: 1rem;
	}


	.dropdown:active .dropdown-content {
		display: block;
	}

	.img {
		width: 1.18rem;
		height: 1.18rem;
		margin-right: 1rem;
	}

	.op {
		font-size: 1.25rem;
		width: 100%;
		margin-bottom: 1.4rem;
		font-weight: 500;
		color: #5c5b5b;
		background-color: transparent;
		border: none;
	}

	.op2 {
		font-size: 1.25rem;
		width: 100%;
		color: ${Colors.tertirary_red_1_dark};
		font-weight: 500;
		background-color: transparent;
		border: none;
	}
	.imgForInput {
		width: 4.8rem;
		height: 4.8rem;
		border-radius: 50%;
		object-fit: cover;
	}

	.r {
		display: flex;
		justify-content: flex-end;
	}

	.divRecuperarConta {
		display: flex;
		justify-content: flex-end;
		align-items: flex-end;
		height: 50%;
	}

	.recuperar {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 19px;
		color: ${Colors.primary_blue_k_medium_1};
		cursor: pointer;
	}

	.divBotao {
		display: flex;
		width: 100%;
		justify-content: center;
	}

	.btnRecuperar {
		width: 26.87rem;
		height: 3.6rem;
		background: ${Colors.primary_blue_k_main};
		border-radius: 8px;
		border: none;
		color: white;
		font-family: Roboto;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 21px;
		margin-top: 2rem;

		@media (min-width: 1366px) {
			margin-top: 1rem;
		}
	}

	.divCabecalho {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	.divTitle {
		display: flex;
		flex-direction: row;
		height: 2rem;
		background-color: white;
	}

	.title {
		font-family: Roboto;
		font-style: normal;
		font-weight: 500;
		font-size: 24px;
		line-height: 28px;
		color: #5c5b5b;
	}

	.divNav {
		display: flex;
		flex-direction: row;
		width: 100%;
		height: 6rem;
		align-items: center;
		background-color: white;
	}

	.selecao {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #001574;
		font-weight: 500;
		background-color: transparent;
		border-bottom: 3px solid #001574;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.selecao2 {
		display: block;
		flex-direction: row;
		height: 65%;
		margin-right: 4.1rem;
		color: #4d4d4d;
		font-weight: 500;
		background-color: transparent;
		border-bottom: none;
		border-right: none;
		border-left: none;
		border-top: none;
		transition: all 0.1s linear;
	}

	.ficha {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		border-bottom: 1px solid #e0e0e0;
	}

	.fichaExercicio {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 5rem;
		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 1.3rem;
		margin-top: 1.3rem;
		padding-right: 1rem;
	}

	.c {
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 50%;
		height: 100%;
		border-bottom: 1px solid #e0e0e0;
		background-color: white;
	}

	.ca {
		display: flex;
		align-items: flex-end;
		flex-direction: row;
		width: 100%;
		height: 50%;
	}

	.cb {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		height: 50%;
		background-color: white;
	}

	.cc {
		display: flex;
		width: 100%;
		align-items: center;
		flex-direction: row;
	}

	.d {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
		border-bottom: 1px solid #e0e0e0;
		background-color: snow;
	}

	.condicao {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.etiquetas {
		display: flex;
		flex-direction: row;
		background-color: white;
		width: 100%;
		height: 6rem;
	}

	.icone {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 1.3rem;
	}

	.iconeSmall {
		width: 0.8rem;
		height: 0.8rem;
		margin-right: 1.3rem;
	}

	.a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 80%;
		height: 100%;
	}

	.b {
		display: flex;
		width: 50%;
		height: 100%;
		background-color: white;
		justify-content: flex-end;
		align-items: center;
	}

	.p {
		color: #5c5b5b;
		margin-bottom: 0.8rem;
	}

	.opo {
		display: flex;
		flex-direction: column;
		width: 20%;
	}

	.tag {
		background-color: #8c8e86;
		font-size: 0.75rem;
		font-weight: 500;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		border-radius: 2rem;
		color: white;
		margin-right: 2%;
	}

	.botao {
		color: ${Colors.primary_blue_k_main};
		background-color: transparent;
		border: none;
		font-weight: 500;
		margin-left: 2.1rem;
	}

	.divExercicio {
		display: flex;
		flex-direction: column;
		width: 100%;

		overflow-x: hidden;

		overflow-y: scroll;
		::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}
	}
`;
export const Overlay = styled.div<{isVisible: boolean}>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
`;
export const ContentTrash = styled.div`
	width: 50%;
	display: flex;
	flex-direction: column;
`;
export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;
export const TrashOptions = styled.div`
	display: flex;
	width: 623px;
	justify-content: space-between;
	border: 2px solid ${Colors.primary_blue_k_main};
	border-radius: 12px ;
	margin-top: 40px;
`;
export const TextUser = styled.p`
	font-family: "Kanit-Regular";
	font-size: 18px;
	font-weight: 600;
	color: ${Colors.neutral_grey_k_5};
	cursor: pointer;
`;
export const TextExercise = styled.p`
	font-family: "Kanit-Regular";
	font-size: 18px;
	font-weight: 600;
	color: ${Colors.neutral_grey_k_5};
	cursor: pointer;
`;
export const TextExecutions = styled.p`
	font-family: "Kanit-Regular";
	font-size: 18px;
	font-weight: 600;
	color: ${Colors.neutral_grey_k_5};
	cursor: pointer;
`;
export const UserOptions = styled.div<{selected?: string}>`
	display: flex;
	width: 33.3333333%;
	height: 100%;
	justify-content: center;
	border-right: 1.5px solid ${Colors?.primary_blue_k_main};
	align-items: center;
	border-top-left-radius:${({selected})=> selected != 'pacientes' ? '20px' : '7px' };
	border-bottom-left-radius:${({selected})=> selected != 'pacientes' ? '20px' : '7px' };
	background-color: ${({selected})=> selected == 'pacientes' 
		? Colors?.primary_blue_k_main
		: Colors?.white
	};
	cursor: pointer;

	${TextUser}{
		color: ${({selected})=> selected == 'pacientes' 
			? Colors?.white
			: Colors?.primary_blue_k_main
		}
	}
`;
export const ExerciseOptions = styled.div<{selected?: string}>`
	display: flex;
	width: 33.3333333%;
	height: 100%;
	justify-content: center;
	align-items: center;
	border-left: 1.5px solid ${Colors?.primary_blue_k_main};
	border-right: 1.5px solid ${Colors?.primary_blue_k_main};
	// border-top-left-radius:${({selected})=> selected != 'gerais' ? '20px' : '7px' };
	// border-bottom-left-radius:${({selected})=> selected != 'gerais' ? '20px' : '7px' };
	background-color: ${({selected})=> selected == 'exercicios' 
		? Colors?.primary_blue_k_main
		: Colors?.white
	};
	cursor: pointer;

	${TextExercise}{
		color: ${({selected})=> selected == 'exercicios' 
			? Colors?.white
			: Colors?.primary_blue_k_main
		}
	}
`;
export const ExecutionsOptions = styled.div<{selected?: string}>`
	display: flex;
	width: 33.3333333%;
	height: 100%;
	justify-content: center;
	align-items: center;
	border-left: 1.5px solid ${Colors?.primary_blue_k_main};
	border-top-right-radius:${({selected})=> selected != 'execucoes' ? '20px' : '7px' };
	border-bottom-right-radius:${({selected})=> selected != 'execucoes' ? '20px' : '7px' };
	background-color: ${({selected})=> selected == 'execucoes' 
		? Colors?.primary_blue_k_main
		: Colors?.white
	};
	cursor: pointer;

	${TextExecutions}{
		color: ${({selected})=> selected == 'execucoes' 
			? Colors?.white
			: Colors?.primary_blue_k_main
		}
	}
`;
export const ContainerTitle = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
`;

export const ContainerArrowIcon = styled.div`
	display: flex;
	width: 40px;
	height: 40px;
	border-radius: 40px;
	align-items: center;
	justify-content: center;
	background-color: transparent;
`;
export const ContainerDeleteUsers = styled.div`
	display: flex;
	width: 623px;
	height: 50%;
	flex-direction: column;
	position: relative;
	margin-top: 50px;
	position: relative;
	
`;
export const SelectAll = styled.p<{listaExcluidos: number}>`
	font-family: "NotoSans-SemiBold";
	font-size: 18px;
	font-weight:600 ;
	color: ${({listaExcluidos})=> listaExcluidos > 0 ?  Colors?.tertirary_red_1_dark : Colors?.secondary_green_k_medium_1 };
	position: absolute;
	right: 0px;
	cursor: pointer;
`;
export const CardUser = styled.div<{bgColor: boolean}>`
	display: flex;
	width: 100%;
	padding: 12px;
	margin-top: 35px;
	border-radius: 12px;
	background-color:${({bgColor})=> bgColor ? Colors?.neutral_grey_k_10 : 'transparent'};

`;
export const Content = styled.div`
	display: flex;
	width: 85%;
	flex-direction: column;
`;
export const ContentName = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
`;
export const ContentTag = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: row;
	margin-top: 5px;
`;
export const Tag = styled.div<{usuarioNivel?: number}>`
	display: flex;
	min-width: 85px;
	min-height: 32px;
	justify-content: center;
	align-items: center;
	border-radius: 30px;
	color: #323D6E;
	font-family: "NotoSans-Regular";
	font-size: 0.87rem;
	margin-right:10px;
	font-weight: 400;
	background-color: ${({usuarioNivel})=> usuarioNivel != 2 ? '#D0D9FF ': '#C1FEE8' };
`;
export const ContainerButtons = styled.div`
	display: flex;
	width: 15%;
	align-items: center;
	justify-content: space-between;

	
	.custom-checkbox input,
	.custom-radio input {
		display: none;
		cursor: pointer;
	}

	.custom-checkbox input + label:before {
		content: "";
		width: 1rem;
		height: 1rem;
		border-radius: 20%;
		background-color: white;
		border: 2px solid #8d8d8d;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
	}

	.custom-checkbox input:checked + label:before {
		background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 10 10'%3E%3Cg class='nc-icon-wrapper' stroke-width='1' fill='%23555555'%3E%3Cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-miterlimit='10' data-cap='butt' d='M2.83 4.72l1.58 1.58 2.83-2.83'/%3E%3C/g%3E%3C/svg%3E");
		background-color: ${Colors?.secondary_green_k_dark_2};
		background-position: center;
		border: none;
		padding: 2px;
		cursor: pointer;
	}
`;

export const RestoreAll = styled.div`
	display: flex;
	position: fixed;
	width: 22rem;
	height: 64px;
	font-family: "Kanit-Regular";
	font-size: 17px;
	font-weight: 600;
	border-radius:36px;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: ${Colors?.primary_blue_k_main};
	color: white;
	padding: 19px 32px;
	border: none;
	cursor: pointer;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
	z-index: 1000; 

	&:hover {
		background-color: ${Colors?.primary_blue_k_dark_2};
	}
	&:active{
		background-color: ${Colors?.secondary_main_darker};
	}

`;
