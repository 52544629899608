import React, { useEffect, useState } from "react";
import {
	ButtonSave,
	Checkbox,
	ContainerDash,
	Icon,
	ProfilePicture,
	RadioButton,
	Return,
} from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import CheckboxIcon from "../../../assets/icons/checkbox.svg";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import { useDispatch, useSelector } from "react-redux";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import styled from "styled-components";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { DateRangePicker, FocusedInputShape } from "react-dates";
import moment from "moment";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { TypesErrorModal, TypesPeriodo } from "../../../utils/types";
import { CircularProgress } from "@material-ui/core";
import ModalSatisfactionReport from "../../../components/Modais/ModalSatisfactionReport";

interface typeKeys {
	key: string;
	title: string;
	isValid: boolean;
}

interface IHandleDatesChange {
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
}

const SelecaoPeriodo: React.FC = () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const [startDate, setStartDate] = useState<moment.Moment | null>(
		moment((patient.USUARIO_CRIADO = patient?.USUARIO_CRIADO))
	);

	const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(
		null
	);

	const [endDate, setEndDate] = useState<moment.Moment | null>(
		current_language?.idioma === "Español"
			? moment().locale("es")
			: moment().locale(
					current_language?.idioma === "Português"
						? "pt-br"
						: current_language?.idioma === "English"
						? "en"
						: "es"
			  )
	);

	const AlterarPeriodo = (periodo: string) => {
		let per = "";

		if (periodo == "ultima_semana") {
			per = `${moment(new Date())
				.subtract(7, "d")
				.format("YYYY-MM-DD")}/${moment(new Date()).format("YYYY-MM-DD")}`;
		}
		if (periodo == "ultimo_mes") {
			per = `${moment(new Date())
				.subtract(30, "d")
				.format("YYYY-MM-DD")}/${moment(new Date()).format("YYYY-MM-DD")}`;
		}
		if (periodo == "todo_periodo") {
			per = `0000-00-00/${moment(new Date()).format("YYYY-MM-DD")}`;
		}

		if (periodo == "periodo_personalizado") {
			per = `${startDate?.format("YYYY-MM-DD")}/${endDate?.format(
				"YYYY-MM-DD"
			)}`;
		}

		dispatch(RelatorioActions.set_current_selected_period(per));
	};

	const handleDatesChange = ({ startDate, endDate }: IHandleDatesChange) => {
		setStartDate(startDate);
		setEndDate(endDate);
		AlterarPeriodo("periodo_personalizado");
	};

	const permiteDate = () => {
		const dateNow = moment().valueOf();
		const dataRef = endDate?.valueOf() != null ? endDate?.valueOf() : dateNow;
		const Permit = dataRef < dateNow;
		if (!!Permit) {
			setLoading(true);
			dispatch(RelatorioActions.get_emitir_relatorio());
		} else {
			dispatch(
				AppActions.set_modal_visible({
					type: TypesErrorModal.FAIL,
					message: current_language?.data_final_maior_que_atual,
				})
			);
		}
	};

	const periodos: Array<typeKeys> = [
		{
			key: "todo_periodo",
			title: "Todo o período",
			isValid: true,
		},
		{
			key: "ultimo_mes",
			title: "Último mês",
			isValid: true,
		},
		{
			key: "ultima_semana",
			title: "Última Semana",
			isValid: true,
		},
		{
			key: "periodo_personalizado",
			title: "Período personalizado",
			isValid: true,
		},
	];

	const [optionChecked, setOptionChecked] = useState("todo_periodo");

	useEffect(() => {
		AlterarPeriodo("todo_periodo");
	}, []);

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk>Informações do paciente</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
				}}
			>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</div>
			<div style={{ width: "60%" }}>
				<P2Desk style={{ marginBottom: "1rem" }}>
					Selecione o período que você quer avaliar:
				</P2Desk>
			</div>
			<WhiteContainer style={{ height: "100vh" }}>
				{periodos.map((item: typeKeys, index: number) => (
					<Option key={index}>
						<div>
							<P2Desk
								style={
									item.key === "periodo_personalizado"
										? { marginRight: "10px" }
										: {}
								}
							>
								{item.title}
							</P2Desk>

							{item.key === "periodo_personalizado" &&
								optionChecked == "periodo_personalizado" && (
									<DateRangePicker
										startDate={startDate}
										startDateId="tata-start-date"
										endDate={endDate}
										endDateId="tata-end-date"
										onDatesChange={handleDatesChange}
										focusedInput={focusedInput}
										onFocusChange={(focusedInput) =>
											setFocusedInput(focusedInput)
										} // <== ERROR OCCURS
										isOutsideRange={() => false}
										displayFormat={() =>
											current_language?.idioma === "Português"
												? "DD/MM/YYYY"
												: current_language?.idioma === "English"
												? "MM/DD/YYYY"
												: "DD-MM-YYYY"
										}
										hideKeyboardShortcutsPanel={true}
										weekDayFormat={
											current_language?.idioma === "Português"
												? "ddd"
												: current_language?.idioma === "English"
												? "ddd"
												: "ddd"
										}
									/>
								)}
						</div>

						<RadioButton
							type="radio"
							name={item.key}
							value={item.key}
							onChange={(e) => {
								setOptionChecked(item.key);
								AlterarPeriodo(item.key);
							}}
							checked={optionChecked === item.key}
						/>
					</Option>
				))}
			</WhiteContainer>
			<div style={{ width: "60%" }}>
				<ButtonSave onClick={() => permiteDate()} active={true}>
					{loading ? (
						<CircularProgress
							color="inherit"
							style={{ width: "1.3rem", height: "1.3rem" }}
						/>
					) : (
						"Emitir"
					)}
				</ButtonSave>
				<ButtonSave
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("selecao_exercicios"))
					}
					style={{
						marginTop: "1rem",
						marginBottom: "1rem",
						backgroundColor: "transparent",
						border: "2px solid #000e4b",
						color: "#000e4b",
					}}
					active={true}
				>
					Voltar
				</ButtonSave>
			</div>
			
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	width: 60%;
	margin-bottom: 3rem;
	display: flex;
	flex-direction: column;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const Option = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 1rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;
export default SelecaoPeriodo;

