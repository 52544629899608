import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TextsProps } from "../../../database/txt.interface";
import Limpar from "../../../assets/icons/limpar.svg";
import { useForm } from "react-hook-form";
import Lupa from "../../../assets/icons/lupa.svg";
import {
	ButtomSearch,
	ButtomSearchPulse,
	Container,
	DivInput,
	Icon,
	InputSearch,
	Reset,
} from "../../../styles/styles_input_pesquisas";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";

const InputPesquisaProtocolo: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const { reset } = useForm();

	const dispatch = useDispatch();

	const [isWriting, SetIsWriting] = useState(false);

	const Submit = (event: any) => {
		if (event.key == "Enter" || event.type == "click") {
			dispatch(DashBoardActions.set_button_search(true));
		}
	};

	const protocolos: ProtocolosProps[] = useSelector(
		(state: any) => state.manage_protocol.protocolos
	);

	const [inputValueProtocol, setInputValueProtocol] = useState("");

	const resetInput = () => {
		dispatch(DashBoardActions.set_search_protocol(""));
		setInputValueProtocol("");
		reset();
	};

	const handleInputChangeProtocol = (event: any) => {
		setInputValueProtocol(event.target.value);
		if (inputValueProtocol != "") {
			const matchedProtocolos = protocolos.filter(
				(protocolo: ProtocolosProps) => {
					return (
						protocolo.PROTOCOLO_TITULO_PT.toLowerCase().indexOf(
							event.target.value.toLowerCase()
						) !== -1
					);
				}
			);
			dispatch(DashBoardActions.set_search_protocol(matchedProtocolos));
		}
	};

	//UI
	return (
		<Container>
			<div style={{ display: "flex" }}>
				<DivInput>
					<ButtomSearch type="button" onClick={Submit}>
						<Icon src={Lupa} />
					</ButtomSearch>
					<InputSearch
						onKeyDown={Submit}
						value={inputValueProtocol}
						onChange={handleInputChangeProtocol}
						type="text"
						placeholder={current_language?.pesqui_protocol}
					/>
					{isWriting === true && (
						<Reset type="reset">
							<Icon src={Limpar} alt="" onClick={resetInput} />
						</Reset>
					)}
				</DivInput>
			</div>
		</Container>
	);
};

export default InputPesquisaProtocolo;
