import React, { useCallback, useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import "react-circular-progressbar/dist/styles.css";
import { TextsProps } from "../../../database/txt.interface";
import { ProtocolosProps } from "../../../interfaces/protocolo.interface";
import { AppActions } from "../../../store/ducks/app";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { beep_curto } from "../beep";
import { beep_long_contracao } from "../beep_long_contracao";
import Configuracoes from "../Configuracoes";
import Progress from "../progress";
import ResultadoExecucao from "../ResultadoExecucao";
import { Body, Checklist, Conteiner, Content } from "../styles";
import TimerRelogio from "../timer";
import RenderExerProto, { ExerProtoPros } from "./renderExerProps";
import moment from "moment";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { ManageProtocolActions } from "../../../store/ducks/manage_protocols";
import { AmplitudeLog } from "../../../utils/amplitude";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { getEvaluatedPatients } from "../../../utils/funcs";
import { ButtonSave, Icon } from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import TimeIcon from "../../../assets/icons/timer_icon.svg";
import TutorialDefault from "../../../assets/tutorial_default.png";
import ExpandIcon from "../../../assets/icons/expand.svg";
import {
	H2Desk,
	P1Desk,
	P2Desk,
	P3Desk,
	S1Desk,
} from "../../../styles/styles_text";
import Settings from "../../../assets/icons/settings.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import Info from "../../../assets/icons/info.svg";
import ToastAlert from "../../../components/toast";
import { ConexaoBluetooth } from "../ConexaoBluetooth";
import { tutorialImages } from "../../../utils/tutorials";

export const Preenchimento = styled.div`
	height: 100%;

	transition: all 0.1s ease-out;

	background-color: #000e4b;
`;

export const Barra = styled.div`
	height: 5rem;
	width: 55rem;
	background-color: #e5e5e5;

	position: relative;

	overflow: hidden;

	border-radius: 1rem;

	.lineMin {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid #000;
		transition: all 0.1s ease-out;
	}

	.lineMax {
		position: absolute;
		width: 0;
		height: 100%;
		border: 2px solid #000;
		transition: all 0.1s ease-out;
	}

	@media (max-width: 1300px) {
		height: 4rem;
		width: 50rem;
	}

	@media (max-width: 1130px) {
		height: 3.8rem;
		width: 45rem;
	}
`;

const Avaliacao: React.FC = () => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const language = useSelector((state: any) => state.configs.language);

	const result_bluetooth = useSelector(
		(state: any) => state.configs.result_bluetooth
	);
	const questionarioSatisfacaoRespondido = useSelector(
		(state: any) => state.dashboard.resposta_questionario_satisfacao
	);

	const modo_demo: any = useSelector((state: any) => state.configs.modo_demo);

	const [timeInitiTimeAval, setTimeInitAvaliation] = useState<any>();

	const resultado_execucao = useSelector(
		(state: any) => state.app.resultado_execucao
	);

	const is_open_configuracoes = useSelector(
		(state: any) => state.app.is_open_configuracoes_exercicios
	);

	const current_repeticoes = useSelector(
		(state: any) => state.avaliation.current_repeticoes
	);
	const force_option_min = useSelector(
		(state: any) => state.avaliation.force_option_min
	);
	const start = useSelector((state: any) => state.avaliation.start);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const force_option_max = useSelector(
		(state: any) => state.avaliation.force_option_max
	);
	const valor_ble = useSelector((state: any) => state.avaliation.valor_ble);
	const valor_obtido = useSelector((state: any) => state.avaliation.valor);

	const is_play = useSelector((state: any) => state.app.is_play_exercicios);
	const alerta_sonoro = useSelector(
		(state: any) => state.configs.alerta_sonoro
	);
	const protocolo_list: ProtocolosProps = useSelector(
		(state: any) => state.app.protocolo_list
	);
	const is_valid: any = useSelector((state: any) => state.avaliation.is_valid);

	const current_exercise: ExerProtoPros = useSelector(
		(state: any) => state.avaliation.current_exercise
	);

	const checklist_execucao = useSelector(
		(state: any) => state.app.checklist_execucao
	);

	const exercicio_atual: any = protocolo_list?.PROTOCOLO_EXERCICIO?.find(
		(e: any) => current_exercise?.exercice_id == e?.ID_EXERCICIO
	);

	const exercicio = useSelector((state: any) => state.app.exercicio);
	
	const connect_flag_hand_grip = useSelector(
		(state: any) => state.avaliation.connect_flag_hand_grip
	);
	const result_bluetooth_hand_grip = useSelector(
		(state: any) => state.configs.result_bluetooth_hand_grip
	);

	const connect_flag = useSelector(
		(state: any) => state.avaliation.connect_flag
	);

	const valor_ble_hand_grip = useSelector((state: any) => state.avaliation.valor_ble_hand_grip);


	const calc_percent_values = useCallback(
		(valKG: any, time: any) => {
			if (
				parseFloat(valKG) > parseFloat(force_option_min) &&
				is_valid === null
			) {
				dispatch(AvaliationActions.set_is_valid(true));
				dispatch(
					AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time)
				);
			}
			if (
				parseFloat(valKG) < parseFloat(force_option_min) &&
				is_valid === false
			) {
				dispatch(
					AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time)
				);
				dispatch(AvaliationActions.set_is_valid(null));
			} else {
				dispatch(
					AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time)
				);
			}
		},
		[force_option_min, is_valid, valor_ble.peso]
	);

	const calc_percent_values_hand_grip = useCallback((valKG: any, time: any, repetitions?: any) => {
		if (parseFloat(valKG) > parseFloat(force_option_min) && is_valid === null) {
			dispatch(AvaliationActions.set_is_valid(true));
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
		}
		if (
			parseFloat(valKG) < parseFloat(force_option_min) &&
			is_valid === false
		) {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
			dispatch(AvaliationActions.set_is_valid(null));
		} else {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time, repetitions));
		}
	},[force_option_min, is_valid, valor_ble_hand_grip.peso])

	function calc_percent_values_demo(valKG: any, time: any) {
		if (valKG > force_option_min) {
			dispatch(AvaliationActions.set_is_valid(true));
		}
		if (valKG < force_option_min) {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time));
			dispatch(AvaliationActions.set_is_valid(false));
		} else {
			dispatch(AvaliationActions.set_valor(parseFloat(valKG).toFixed(1), time));
		}
	}

	useEffect(() => {
		if (modo_demo) {
			if (is_valid && is_valid != null) {
				if (alerta_sonoro) {
					beep_curto();
				}
				dispatch(AvaliationActions.set_current_repe(current_repeticoes + 1));
			}
		} else {
			if (is_valid) {
				if (alerta_sonoro) {
					beep_curto();
				}
				dispatch(AvaliationActions.set_current_repe(current_repeticoes + 1));
				dispatch(AvaliationActions.set_is_valid(false));
			}
		}

		if (!is_valid && is_valid != null && start && start != null) {
			const fim = moment(new Date()).valueOf();
			const time_screen: any = (fim - timeInitiTimeAval) / 1000;
			if (!!time_screen) {
				dispatch(AvaliationActions.set_picos(time_screen.toFixed(1)));
			}
		}
	}, [is_valid, start]);

	useEffect(() => {
		if (start && start != null) {
			dispatch(AppActions.set_play_exercicios(true));
			setTimeInitAvaliation(moment(new Date()).valueOf());
		}
	}, [start]);

	useEffect(() => {
		let demoInterval: any = null;
		let demoCounter = 0;
		if (start && start != null) {
			let time = 0;
			demoInterval = setInterval(function () {
				if (modo_demo) {
					time += 0.1;
					calc_percent_values_demo(
						20 * Math.sin((5 * demoCounter * Math.PI) / 180) + 20,
						time.toFixed(1)
					);
					demoCounter++;
				}
			}, 100);

			if (!modo_demo) {
				const fim = moment(new Date()).valueOf();
				const time_screen: any = (fim - timeInitiTimeAval) / 1000;
				if (!!time_screen) {
					calc_percent_values(valor_ble.peso, time_screen.toFixed(1));
				}
			}
		}
		if (!start && start != null) {
			clearInterval(demoInterval);
			demoCounter = 0;
		}
		return () => {
			clearInterval(demoInterval);
		};
	}, [start, valor_ble.ping]);

	useEffect(() => {
		let demoInterval: any = null;
		let demoCounter = 0;
		if (start && start != null) {
			let time = 0;
			demoInterval = setInterval(function () {
				if (modo_demo) {
					time += 0.1;
					calc_percent_values_demo(
						20 * Math.sin((5 * demoCounter * Math.PI) / 180) + 20,
						time.toFixed(1)
					);
					demoCounter++;
				}
			}, 100);

			if (!modo_demo) {
				const fim = moment(new Date()).valueOf();
				const time_screen: any = (fim - timeInitiTimeAval) / 1000;
				if (!!time_screen) {
					calc_percent_values_hand_grip(valor_ble_hand_grip.peso, time_screen.toFixed(1));
				}
			}
		}
		if (!start && start != null) {
			clearInterval(demoInterval);
			demoCounter = 0;
		}
		return () => {
			clearInterval(demoInterval);
		};
	}, [start, valor_ble_hand_grip.ping]);

	const contagem_regressiva = useSelector(
		(state: any) => state.configs.contagem_regressiva
	);
	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);
	const handleIsNotPlay = () => {
		dispatch(AvaliationActions.set_start(false));
		dispatch(AppActions.set_play_exercicios(false));
		dispatch(AppActions.set_resultado_execucao(true));
	};

	const handleOpenContagemExercicio = () => {
		if ((!!exercicio && exercicio?.IDS_HARDWARES[0] === "2") || (!!exercicio_atual && exercicio_atual?.IDS_HARDWARES[0] === "2")) {
			if (!result_bluetooth_hand_grip && !modo_demo) {
				ConexaoBluetooth();
				dispatch(AppActions.set_modal_conecta_hand_grip(true));
			} else {
				contagem_regressiva
					? dispatch(AppActions.set_modal_contagem_exercicio(true))
					: dispatch(AvaliationActions.set_start(true));
	
				contagem_regressiva === false &&
					dispatch(AppActions.set_timer_reset(true));
			}
		} else {
			if (!result_bluetooth && !modo_demo) {
				ConexaoBluetooth();
				dispatch(AppActions.set_modal_conecta3(true));
			} else {
				contagem_regressiva
					? dispatch(AppActions.set_modal_contagem_exercicio(true))
					: dispatch(AvaliationActions.set_start(true));
	
				contagem_regressiva === false &&
					dispatch(AppActions.set_timer_reset(true));
			}
		}
	};

	const valorContador = tempo_contracao;
	const tempoMaximo = tempo_contracao;
	const [contador, setContador] = React.useState<any>(valorContador);
	const [beepContagem, setBeepContagem]: any = useState(tempoMaximo);

	const timer_reset = useSelector((state: any) => state.app.timer_reset);

	useEffect(() => {
		if (is_play) {
			if (timer_reset) {
				setTimeout(() => {
					contador !== 0 && setContador(contador - 1);
					setBeepContagem(contador);
					if (contador === 0) {
						setContador(0);
						beepContagem !== contador && beep_long_contracao();
					}
				}, 1000);
			}
		} else if (is_play === false) {
			setContador(valorContador);
			return;
		}
		if (timer_reset === false) {
			setContador(valorContador);
			return;
		}
	}, [is_play, contador, timer_reset]);

	const timer = contador;
	const valor = tempoMaximo - timer;

	const progress_exers_proto = useSelector(
		(state: any) => state.avaliation.progress_exers_proto
	);

	const pico_result = useSelector((state: any) => state.avaliation.pico_result);
	const id_execucao: number = useSelector(
		(state: any) => state.avaliation.id_execucao
	);

	const [ids_execucoes, set_ids_execucoes] = useState<number[]>([]);
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	useEffect(() => {
		if (id_execucao != null) {
			set_ids_execucoes((prevState) => [...prevState, id_execucao]);
		}
	}, [id_execucao]);

	const finalizar = () => {
		AmplitudeLog("finalizar_execucao_protocolo");
		dispatch(AvaliationActions.reset_progress_exers_proto());
		dispatch(AvaliationActions.set_in_execution_force_max_status(false));
		setContador(valorContador);
		dispatch(AvaliationActions.set_exer_protocolos_reset());
		dispatch(AvaliationActions.reset2());
		dispatch(AvaliationActions.reset());
		dispatch(ManageProtocolActions.set_button_goback(false));
		const obj = {
			name: paciente?.USUARIO_NOME,
			value: "paciente",
		};
		dispatch(AppActions.set_open_barraSuperior(obj));
		if (!questionarioSatisfacaoRespondido && protocolo_list?.ID_USUARIO == 0) {
			dispatch(AppActions.set_modal_satisfacao(true, "Protocolos"));
		}
		dispatch(
			AvaliationActions.set_logs_protocols_executed(
				moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				patient?.ID_USUARIO,
				protocolo_list?.ID_PROTOCOLO,
				0,
				ids_execucoes
			)
		);
		// dispatch(push("/paciente"));
		dispatch(DashBoardActions.set_current_page("protocols_in_patient"));
	};

	const [picoResult, setPicoResult] = React.useState<any>(0);

	let resultMediaPico = 0;
	pico_result.map((i: any) => {
		if (i.valor > picoResult) {
			setPicoResult(i.valor);
		}
		resultMediaPico += i.valor;
	});
	resultMediaPico = resultMediaPico / pico_result.length;
	const exer_protocolo = useSelector(
		(state: any) => state.avaliation.exer_protocolo
	);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const remove = useSelector((state: any) => state.manage_protocol.remove_play);

	useEffect(() => {
		dispatch(
			DashBoardActions.set_questionario_satisfacao_verificar(
				protocolo_list?.ID_PROTOCOLO,
				"PROTOCOLOS"
			)
		);
	}, [protocolo_list?.ID_PROTOCOLO]);

	useEffect(() => {
		if(connect_flag) {
			dispatch(AppActions.set_modal_conecta(false));
			dispatch(AppActions.set_modal_conecta3(false));
			dispatch(AppActions.set_modal_conecta2(false));
			dispatch(AppActions.set_modal_conecta4(false));
		}
	}, [connect_flag])	
	
	useEffect(() => {
		if(connect_flag_hand_grip) {
			dispatch(AppActions.set_modal_conecta_hand_grip(false));
		}
	}, [connect_flag_hand_grip])

	return (
		<Conteiner>
			{is_open_toast && <ToastAlert />}
			<Content>
				<Return
					onClick={() =>
						dispatch(DashBoardActions.set_current_page("perfil_paciente"))
					}
				>
					<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
					<P3Desk>{current_language?.menu_lateral_protocolos}</P3Desk>
				</Return>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: "1rem",
					}}
				>
					{checklist_execucao ? (
						<H2Desk>{protocolo_list?.PROTOCOLO_TITULO_PT}</H2Desk>
					) : (
						<H2Desk>
							{language === "PT"
								? exercicio_atual?.EXERCICIO_TITULO_PT
								: language === "EN"
								? exercicio_atual?.EXERCICIO_TITULO_EN
								: exercicio_atual?.EXERCICIO_TITULO_ES}{" "}
							{current_exercise?.name} ({current_exercise?.title})
						</H2Desk>
					)}

					<Icon
						src={Settings}
						style={{ marginLeft: "1rem" }}
						onClick={() => {
							dispatch(DashBoardActions.set_current_page("configuracoes"));
							AmplitudeLog("configuracoes_execucao");
						}}
					/>
				</div>

				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: "1rem",
					}}
				>
					<ProfilePicture
						src={paciente?.USUARIO_FOTO ? paciente?.USUARIO_FOTO : Perfil}
						style={{ objectFit: 'cover' }}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{paciente.USUARIO_NOME}
					</S1Desk>
				</div>

				<hr style={{ border: "1px solid #DEDFE3", marginTop: "1rem" }} />

				{checklist_execucao ? (
					<Checklist style={{ width: "100%" }}>
						{protocolo_list?.PROTOCOLO_TEMPO_ESTIMADO && (
							<TempoEstimado>
								<Icon
									src={TimeIcon}
									style={{ width: "1rem", height: "1rem" }}
								/>
								{current_language?.tempo_estimado}{" "}
								{protocolo_list.PROTOCOLO_TEMPO_ESTIMADO}
							</TempoEstimado>
						)}
						<div className="lista">
							<div className="exercicios">
								{protocolo_list.PROTOCOLO_EXERCICIO.map((exer, index) => {
									return (
										<>
											<ExerciseSection key={index}>
												<TitleAndDescription>
													<ExerciseName>
														{exer.EXERCICIO_TITULO_PT}
													</ExerciseName>
													<RenderExerProto item={exer} index={index} />
												</TitleAndDescription>
												<TutorialSection
													onClick={() =>
														dispatch(AppActions.set_modal_tutorial(true, exer))
													}>
													{tutorialImages[exer.EXERCICIO_TITULO_PT] && tutorialImages[exer.EXERCICIO_TITULO_PT][0] && (
													<>
														<Icon
															style={{ 
																width: "8rem",
																height: "100%",
																objectFit: "cover",
																borderTopRightRadius: "15px",
																borderBottomRightRadius: "15px",
																marginRight: "7px"
															}}
															src={tutorialImages[exer.EXERCICIO_TITULO_PT][0]}
														/>
														<Icon
															src={ExpandIcon}
															style={{
																width: "1.5rem",
																height: "1.5rem",
																marginLeft: "-2rem",
																marginTop: "0.5rem",
															}}
														/>
													</>
												)}
												</TutorialSection>
											</ExerciseSection>
										</>
									);
								})}
							</div>
							<div
								className={
									progress_exers_proto.length === exer_protocolo.length
										? `footer2`
										: `footer`
								}
							>
								{progress_exers_proto.length === exer_protocolo.length ? (
									<ButtonSave
										active={true}
										className="finalizar"
										onClick={finalizar}
									>
										{current_language?.finalizar}
									</ButtonSave>
								) : remove ? (
									<div></div>
								) : progress_exers_proto.length > 0 ? (
									<ButtonSave
										active={true}
										onClick={() => {
											finalizar(), getEvaluatedPatients(paciente);
										}}
									>
										{current_language?.finalizar_execucao}
									</ButtonSave>
								) : (
									<div></div>
								)}
							</div>
						</div>
					</Checklist>
				) : resultado_execucao ? (
					<ResultadoExecucao />
				) : (
					<>
						{is_open_configuracoes ? (
							<Configuracoes />
						) : (
							<>
								{/* <Header /> */}
								<Body>
									<TimersContainer>
										<Option>
											<P1Desk>
												{current_language?.config_aval_repeticoes}
											</P1Desk>
											<P1Desk>{current_repeticoes}</P1Desk>
										</Option>
										<Option>
											<P1Desk>{current_language?.cronometro}</P1Desk>
											<P1Desk>
												<TimerRelogio />
											</P1Desk>
										</Option>
										<Option>
											<P1Desk>{current_language?.contracao}</P1Desk>
											<CircularProgress>
												<CircularProgressbar
													value={valor}
													minValue={0}
													maxValue={tempoMaximo}
													strokeWidth={10}
													styles={buildStyles({
														strokeLinecap: "butt",
														pathColor: "#d6d6d6",
														textSize: "40px",
														textColor: "#7F7F7F",
														trailColor: "#4585FF",
													})}
												></CircularProgressbar>
												<P1Desk style={{ position: "absolute" }}>
													{timer}
												</P1Desk>
											</CircularProgress>
										</Option>
									</TimersContainer>
									<div className="marginTopoBarra">
										<Progress
											step={valor_obtido}
											max={force_option_max * 1.2}
										/>
									</div>
									<div
										style={{ width: "100%", marginBottom: "1rem" }}
										onClick={() =>
											dispatch(AppActions.set_open_modal_ouro(true))
										}
									>
										<Icon style={{ marginRight: "0.5rem" }} src={Info} />
										<P2Desk>{current_language?.padrao_ouro}</P2Desk>
									</div>
									{is_play ? (
										<>
											<ButtonSave
												active={true}
												onClick={() => {
													handleIsNotPlay();
													AmplitudeLog("parar_protocolo");
												}}
											>
												{current_language?.execucao_finalizar}
											</ButtonSave>
										</>
									) : (
										<>
											<ButtonSave
												active={true}
												onClick={() => {
													handleOpenContagemExercicio();
													AmplitudeLog("iniciar_protocolo");
												}}
											>
												{current_language?.iniciar}
											</ButtonSave>
											<ButtonSave
												style={{
													backgroundColor: "transparent",
													color: "#000E4B",
												}}
												active={true}
												onClick={() => {
													handleOpenContagemExercicio();
													AmplitudeLog("tutorial");
												}}
											>
												{current_language?.abrir_tutorial}
											</ButtonSave>
										</>
									)}
								</Body>
							</>
						)}
					</>
				)}
			</Content>
		</Conteiner>
	);
};

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

const TimersContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin-top: 2rem;
`;

const Option = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
`;

const CircularProgress = styled.div`
	background-color: #fff;
	width: 2.3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
`;

const TempoEstimado = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 28.8px;
	border: 1px solid #00a377;
	width: 100%;
	border-radius: 30px;
	color: #00a377;
	align-items: center;
	padding: 0.1rem;
	margin-bottom: 1.5rem;
	margin-top: 1rem;
`;

const ExerciseSection = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border: 2px solid #dedfe3;
	border-radius: 18px;
	margin-bottom: 1rem;
	margin-top: 1rem;
	height: 8rem;
`;

const TitleAndDescription = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 1rem;
`;

const ExerciseName = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1.1rem;
	font-weight: 600;
	color: #323d6e;
`;

const TutorialSection = styled.div`
	display: flex;
	max-height: 100%;
`;

export default Avaliacao;
