import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { TextsProps } from "../../../database/txt.interface";
import Limpar from "../../../assets/icons/limpar.svg";
import { useForm } from "react-hook-form";
import Lupa from "../../../assets/icons/lupa.svg";
import Filter from "../../../assets/icons/filter.svg";
import { AppActions } from "../../../store/ducks/app";
import DeleteIcon from "../../../assets/icons/delete.svg";
import AvaliadorIcon from "../../../assets/icons/avaliador_icon.svg";
import CondicaoFisicaIcon from "../../../assets/icons/cond_fisica_icon.svg";
import EtiquetaIcon from "../../../assets/icons/etiqueta_icon.svg";
import {
	ButtomSearch,
	ButtomSearchPulse,
	Container,
	DivInput,
	FilterButton,
	Icon,
	InputSearch,
	Reset,
} from "../../../styles/styles_input_pesquisas";
import MenuIcon from "../../../assets/icons/3points.svg";
import { DropDown, MenuOption } from "../../../styles/global";
import { AtribuirActions } from "../../../store/ducks/atribuir";

const InputPesquisaPaciente: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const { reset } = useForm();

	const dispatch = useDispatch();
	const [pulse, setPulse] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [isWriting, SetIsWriting] = useState(false);

	const Submit = (event: any) => {
		setPulse(false);
		if (event.key == "Enter" || event.type == "click") {
			setPulse(false);
			dispatch(DashBoardActions.set_button_search(true));
		}
	};

	const handleInputChange = (event: any) => {
		setInputValue(event.target.value);
	};

	const resetInput = () => {
		setInputValue("");
		dispatch(DashBoardActions.set_search(""));
		dispatch(DashBoardActions.set_button_search(true));
		SetIsWriting(false);
		setPulse(false);
		reset();
	};

	/*Manda o valor digitado no input para o redux em tempo real, e tbm mostra o
	botão X ao digitar algo */
	const handleOpenSearch = (e: any) => {
		dispatch(DashBoardActions.set_search(e.target.value));
		if (e.target.value !== "") {
			SetIsWriting(true);
			setPulse(true);
		} else {
			SetIsWriting(false);
			setPulse(false);
		}
	};

	const [controler_menu, set_controler_menu] = useState(false);

	//UI
	return (
		<Container>
			<Title>{current_language?.pag_inicial_pacientes}</Title>
			<div style={{ display: "flex" }}>
				<DivInput>
					{pulse ? (
						<ButtomSearchPulse type="button" onClick={Submit}>
							<Icon src={Lupa} />
						</ButtomSearchPulse>
					) : (
						<ButtomSearch type="button" onClick={Submit}>
							<Icon src={Lupa} />
						</ButtomSearch>
					)}
					<InputSearch
						onKeyUp={(e) => {
							if (e.key != "Enter") handleOpenSearch(e);
						}}
						onKeyDown={Submit}
						value={inputValue}
						onChange={handleInputChange}
						type="text"
						placeholder={current_language?.dash_pesquisar_paciente}
					/>
					{isWriting === true && (
						<Reset type="reset">
							<Icon src={Limpar} alt="" onClick={resetInput} />
						</Reset>
					)}
				</DivInput>

				<FilterButton
					onClick={() => dispatch(AppActions.set_modal_filtro_etiquetas(true))}
					src={Filter}
				/>
				<DropDown active={controler_menu} style={{ marginLeft: "1rem" }}>
					<div className="dropdown">
						<Icon
							src={MenuIcon}
							onClick={(event) => {
								event.stopPropagation(),
									set_controler_menu(!controler_menu ? true : false);
							}}
						/>
						<div className="dropdown-content">
							<MenuOption
								onClick={() => {
									dispatch(AtribuirActions.set_menu_option_selected("excluir"));
									set_controler_menu(false);
								}}
							>
								<Icon src={DeleteIcon} /> {current_language?.excluir}
							</MenuOption>
							<MenuOption
								onClick={(event) => {
									dispatch(
										AtribuirActions.set_menu_option_selected("avaliador")
									);
									set_controler_menu(false);
								}}
							>
								<Icon src={AvaliadorIcon} />{" "}
								{current_language?.pag_inicial_avaliador}
							</MenuOption>
							<MenuOption
								onClick={(event) => {
									dispatch(
										AtribuirActions.set_menu_option_selected("condicao_fisica")
									);
									set_controler_menu(false);
								}}
							>
								<Icon src={CondicaoFisicaIcon} />{" "}
								{current_language?.condicao_fisica}
							</MenuOption>
							<MenuOption
								onClick={() => {
									dispatch(
										AtribuirActions.set_menu_option_selected("etiqueta")
									);
									set_controler_menu(false);
								}}
							>
								<Icon src={EtiquetaIcon} /> {current_language?.tag}
							</MenuOption>
						</div>
					</div>
				</DropDown>
			</div>
		</Container>
	);
};

const Title = styled.text`
	font-family: "NotoSans-Medium";
	font-size: 1.62rem;
	font-weight: 500;
	line-height: 48px;
	color: #000e4b;
	margin-bottom: 10rem;
`;

export default InputPesquisaPaciente;
