import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ButtonSave, ContainerDash, Icon } from "../../../styles/global";
import { H2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import { TextsProps } from "../../../database/txt.interface";
import { useForm } from "react-hook-form";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AmplitudeLog } from "../../../utils/amplitude";
import { AnotationActions } from "../../../store/ducks/anotacoes";

const CriarAnotacao: React.FC = () => {
	const dispatch = useDispatch();
	const windowWidth = window.innerWidth;

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);
	const avaliadores = useSelector((state: any) => state.dashboard.avaliadores);
	const avaliador = avaliadores.filter(
		(item: PacientesProps) => item?.ID_USUARIO === pacientes[0]?.USUARIO_AVALIADOR
	)[0];

	const [isSearch, setIsSearch] = useState<boolean>(false);

	const [value, setvalue]: any = useState<any>(0);

	const isWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalue(e.target.value);
	};

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const ModalCadastroClose = () => {
		reset();
		setvalue(0);
	};

	const onSubmit = async (data: any) => {
		const NovaEtiqueta = {
			ID_USUARIO: paciente.ID_USUARIO,
			ANOTACAO_TXT: data.text,
		};
		AmplitudeLog("anotacao_criada", { body: NovaEtiqueta });
		dispatch(AnotationActions.add_anotation_request(NovaEtiqueta));
		ModalCadastroClose();
		setvalue("");
		reset();
		dispatch(DashBoardActions.set_current_page("listagem_anotacoes"));
	};

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("listagem_anotacoes"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk>{current_language?.anote_evolucao_paciente}</P3Desk>
			</Return>

			<Header>
				<H2Desk>{current_language?.criar_anotacao}</H2Desk>
				<hr
					style={{
						border: "1px solid #DEDFE3",
						marginTop: "1rem",
						marginBottom: "1rem",
					}}
				/>
				<H2Desk>{current_language?.pag_inicial_avaliador}:</H2Desk>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: "1rem",
					}}
				>
					<ProfilePicture src={Perfil} />
					<S1Desk style={{ marginLeft: "1rem" }}>{`${
						!!avaliador?.USUARIO_NOME ? avaliador?.USUARIO_NOME : "-"
					}`}</S1Desk>
				</div>
			</Header>
			<ContainerOptions onSubmit={handleSubmit(onSubmit)}>
				<H2Desk style={{ marginBottom: "1rem", marginTop: "1rem" }}>
					{current_language?.anote_evolucao_paciente}
				</H2Desk>
				<Textarea
					className="textArea"
					id="text"
					placeholder={current_language?.digite_aqui}
					cols={30}
					rows={windowWidth < 1440 ? 5 : 8}
					onKeyUp={(e) => isWriting(e)}
					{...register("text", {
						required: `${current_language?.login_input_senha}`,
					})}
				></Textarea>

				<P3Desk style={{ textAlign: "end" }} className="limite">
					{value.length === undefined ? "0" : value.length}/1000
				</P3Desk>

				<ButtonSave
					active={true}
					type="submit"
					id="enviar"
					value="ENVIAR"
					className="salve"
				>
					{current_language?.pagamento_salvar}
				</ButtonSave>
			</ContainerOptions>
		</ContainerDash>
	);
};

export default CriarAnotacao;

const ContainerOptions = styled.form`
	display: flex;
	width: 60%;
	flex-direction: column;
`;

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: "flex-start";
	width: 60%;
	margin-top: 1rem;
`;

const Textarea = styled.textarea`
	border-radius: 0.75rem;
	border: 1px solid var(--Neutras-Grey-K-20, #c4c5cc);
	padding: 1rem;
	background-color: #fafafa;
	font-family: "NotoSans-Regular";
`;
