import { ExerCucaoProp } from "../../interfaces/assimetria.interface";
import {
	EXECUCAO_TIPO,
	TypeAgrupamentoLado,
	TypesRelatorio,
	TypesRelatorioCompartilhar,
} from "../../utils/types";

export const Types = {
	SET_CURRENT_TYPE_RELATORIO: "SET_CURRENT_TYPE_RELATORIO",
	SET_CURRENT_TYPE_RELATORIO_COMPARTILHAR:
		"SET_CURRENT_TYPE_RELATORIO_COMPARTILHAR",
	SET_SELECTED_EXERCISE: "SET_SELECTED_EXERCISE",
	SET_OPEN_MODAL_EX: "SET_OPEN_MODAL_EX",
	SET_PERIODO: "SET_PERIODO",

	SET_DATA_FINAL: "SET_DATA_FINAL",
	SET_DATA_INICIO: "SET_DATA_INICIO",

	SET_CURRENT_ASSIMETRIA: "SET_CURRENT_ASSIMETRIA",

	SET_EXERS_FORCA_TEMPO: "SET_EXERS_FORCA_TEMPO",
	SET_OP_AVAL_FUNCI: "SET_OP_AVAL_FUNCI",

	SET_DATA_EVOLUTION: "SET_DATA_EVOLUTION",
	SET_DATA_EVOLUTION_MED: "SET_DATA_EVOLUTION_MED",

	SET_STATUS_MODAL_D_EXECUTION: "SET_STATUS_MODAL_D_EXECUTION",

	SET_SELECTED_EXECUTION: "SET_SELECTED_EXECUTION",

	REQUEST_DELETE_EXECUTION: "REQUEST_DELETE_EXECUTION",
	REQUEST_DELETE_EXECUTION_FINALLY: "REQUEST_DELETE_EXECUTION_FINALLY",
	REQUEST_DELETE_EXECUTION_SUCCESS: "REQUEST_DELETE_EXECUTION_SUCCESS",

	RESET_DELETIONS: "RESET_DELETIONS",

	SET_INFO_HISTORY_EXECUTIONS: "SET_INFO_HISTORY_EXECUTIONS",

	REQUEST_PERSONALIZAR_RELATORIO: "REQUEST_PERSONALIZAR_RELATORIO",
	REQUEST_PERSONALIZAR_RELATORIO_CANCEL:
		"REQUEST_PERSONALIZAR_RELATORIO_CANCEL",

	IMAGEM_RELATORIO: "IMAGEM_RELATORIO",

	EXPORTAR: "EXPORTAR",

	EXERCICIOS_HISTORICOS: "EXERCICIOS_HISTORICOS",

	COMPARTILHAR_LISTA: "COMPARTILHAR_LISTA",

	COMPARTILHAR_DATA: "COMPARTILHAR_DATA",

	SET_COMPARTILHAR: "SET_COMPARTILHAR",
	SET_COMPARTILHAR_CANCEL: "SET_COMPARTILHAR_CANCEL",

	SET_MESSAGE_COMPARTILHAR: "SET_MESSAGE_COMPARTILHAR",

	SET_MARCAR_TODAS: "SET_MARCAR_TODAS",

	SET_AGE: "SET_AGE",

	SET_RELATORIO_EVOLUTION: "SET_RELATORIO_EVOLUTION",
	SET_RELATORIO_ASSIMETRIA: "SET_RELATORIO_ASSIMETRIA",
	SET_RELATORIO_FORCA_TEMPO: "SET_RELATORIO_FORCA_TEMPO",
	SET_RELATORIO_MULTIFATORIAL: "SET_RELATORIO_MULTIFATORIAL",
	SET_RELATORIO_FISIOTERAPEUTICO: "SET_RELATORIO_FISIOTERAPEUTICO",

	SET_RELATORIO_QV_TYPE: "SET_RELATORIO_QV_TYPE",
	SET_RELATORIO_AV_TYPE: "SET_RELATORIO_AV_TYPE",
	SET_RELATORIO_MULT_TYPE: "SET_RELATORIO_MULT_TYPE",
	SET_RELATORIO_FISIO_TYPE: "SET_RELATORIO_FISIO_TYPE",
	SET_CURRENT_EXERCISE_HISTORY: "SET_CURRENT_EXERCISE_HISTORY",

	SET_CURRENT_PAGE_FROM_HISTORY_ASYMMETRY:
		"SET_CURRENT_PAGE_FROM_HISTORY_ASYMMETRY",

	SET_CURRENT_SELECTED_REPORTS: "SET_CURRENT_SELECTED_REPORTS",
	SET_CURRENT_SELECTED_EXERCISES: "SET_CURRENT_SELECTED_EXERCISES",
	SET_CURRENT_SELECTED_PERIOD: "SET_CURRENT_SELECTED_PERIOD",

	GET_EMITIR_RELATORIO: "GET_EMITIR_RELATORIO",
	SET_OPTIONS_FOR_DELETED_OR_MIGRATE: "SET_OPTIONS_FOR_DELETED_OR_MIGRATE",
	SET_ITEMS_SELECTED_FOR_DELETED_OR_MIGRATE: "SET_ITEMS_SELECTED_FOR_DELETED_OR_MIGRATE",
	SYN_LAST_EMISSION: 'SYN_LAST_EMISSION',
	SET_DATA_SYNC_LAST_REPORT_EMISSION: 'SET_DATA_SYNC_LAST_REPORT_EMISSION',
};

const INITIAL_STATE = {
	type_relatorio: TypesRelatorio.EVOLUTION,
	type_relatorio_compartilhar: TypesRelatorioCompartilhar.EVOLUTION,
	is_relatorio_evolucao: true,
	is_relatorio_assimetria: false,
	is_relatorio_forca_tempo: false,
	is_relatorio_multifatorial: false,
	is_relatorio_fisioterapeutico: false,
	selected_type_mult: "",
	selected_type_av: "",
	selected_type_qv: "",
	selected_type_fisio: "",
	selected_exercise: null,
	is_open_modal_exer: false,
	periodo: null,

	selectDataFinal: "",
	selectDataInicio: "",

	type_current_assimetria: TypeAgrupamentoLado.SUPERIOR_FRONTAL,

	exers_forca_tempo: [],
	opAvalFunci: EXECUCAO_TIPO.AVALIACAO,

	data_evo: [],
	data_evo_med: [],

	visible_delete_execution: false,
	selected_executions: [],
	loading_delete_execution: false,
	temp_delete_execution_success: false,

	dataD: null,
	dataE: null,
	history_execucoes: [],

	personalizar_relatorio: [],
	loading: false,

	image_relatorio: "",

	exercicios_historicos: [],

	exercicios_compartilhar: [],

	data_compartilhar: "",

	loading_compartilhar: false,

	message_compartilhar: [],

	marcar_todas: false,

	age: 3,

	current_exercise_history: [],
	current_page_from_history_asymmetry: 0,
	current_selected_reports: [],
	current_selected_exercises: [],
	current_selected_period: [],
	data_for_delete_or_migrate:[],
	data_selected_for_delete_or_migrate:[],
	last_issued_report: {},
};

export default function relatorios(state = INITIAL_STATE, action: any) {
	switch (action.type) {
		case Types.SET_CURRENT_TYPE_RELATORIO:
			return {
				...state,
				type_relatorio: action.payload,
			};
		case Types.SET_MARCAR_TODAS:
			return {
				...state,
				marcar_todas: action.payload,
			};
		case Types.SET_CURRENT_TYPE_RELATORIO_COMPARTILHAR:
			return {
				...state,
				type_relatorio_compartilhar: action.payload,
			};
		case Types.SET_SELECTED_EXERCISE:
			return {
				...state,
				selected_exercise: action.payload,
			};
		case Types.SET_OPEN_MODAL_EX:
			return {
				...state,
				is_open_modal_exer: action.payload,
			};
		case Types.SET_PERIODO:
			return {
				...state,
				periodo: action.payload,
			};
		case Types.SET_DATA_INICIO:
			return {
				...state,
				selectDataInicio: action.payload,
			};
		case Types.SET_DATA_FINAL:
			return {
				...state,
				selectDataFinal: action.payload,
			};
		case Types.SET_CURRENT_ASSIMETRIA:
			return {
				...state,
				type_current_assimetria: action.payload,
			};
		case Types.SET_EXERS_FORCA_TEMPO:
			return {
				...state,
				exers_forca_tempo: action.payload,
			};
		case Types.SET_OP_AVAL_FUNCI:
			return {
				...state,
				opAvalFunci: action.payload,
			};
		case Types.SET_DATA_EVOLUTION:
			return {
				...state,
				data_evo: action.payload,
			};
		case Types.SET_DATA_EVOLUTION_MED:
			return {
				...state,
				data_evo_med: action.payload,
			};
		case Types.SET_STATUS_MODAL_D_EXECUTION:
			return {
				...state,
				visible_delete_execution: action.payload,
			};
		case Types.REQUEST_DELETE_EXECUTION:
			return {
				...state,
				loading_delete_execution: true,
			};
		case Types.REQUEST_DELETE_EXECUTION_FINALLY:
			return {
				...state,
				loading_delete_execution: false,
			};
		case Types.REQUEST_DELETE_EXECUTION_SUCCESS:
			return {
				...state,
				temp_delete_execution_success: action.payload,
			};
		case Types.RESET_DELETIONS:
			return {
				...state,
				selected_executions: [],
			};
		case Types.SET_INFO_HISTORY_EXECUTIONS:
			return {
				...state,
				dataD: action.payload.dataD,
				dataE: action.payload.dataE,
				history_execucoes: action.payload.his,
			};

		case Types.SET_SELECTED_EXECUTION:
			let newLis: any = [];
			const exist: any = state.selected_executions.find(
				(exe: any) => exe.id == action.payload.id
			);
			if (!!exist) {
				newLis = state.selected_executions.filter(
					(exe: any) => exe.id != action.payload.id
				);
			} else {
				newLis = [...state.selected_executions, action.payload];
			}
			return {
				...state,
				selected_executions: [...newLis],
			};
		case Types.REQUEST_PERSONALIZAR_RELATORIO:
			return {
				...state,
				personalizar_relatorio: [],
			};
		case Types.IMAGEM_RELATORIO:
			return {
				...state,
				image_relatorio: action.payload,
			};
		case Types.REQUEST_PERSONALIZAR_RELATORIO_CANCEL:
			return {
				...state,
			};
		case Types.EXPORTAR:
			return {
				...state,
			};
		case Types.EXERCICIOS_HISTORICOS:
			return {
				...state,
				exercicios_historicos: action.payload,
			};
		case Types.COMPARTILHAR_LISTA:
			return {
				...state,
				exercicios_compartilhar: action.payload,
			};
		case Types.COMPARTILHAR_DATA:
			return {
				...state,
				data_compartilhar: action.payload,
			};
		case Types.SET_COMPARTILHAR:
			return {
				...state,
				loading_compartilhar: true,
			};

		case Types.SET_COMPARTILHAR_CANCEL:
			return {
				...state,
				loading_compartilhar: false,
			};

		case Types.SET_MESSAGE_COMPARTILHAR:
			return {
				...state,
				message_compartilhar: action.payload,
			};

		case Types.SET_AGE:
			return {
				...state,
				age: action.payload,
			};
		case Types.SET_RELATORIO_EVOLUTION:
			return {
				...state,
				is_relatorio_evolucao: action.payload,
			};
		case Types.SET_RELATORIO_ASSIMETRIA:
			return {
				...state,
				is_relatorio_assimetria: action.payload,
			};
		case Types.SET_RELATORIO_FORCA_TEMPO:
			return {
				...state,
				is_relatorio_forca_tempo: action.payload,
			};
		case Types.SET_RELATORIO_MULTIFATORIAL:
			return {
				...state,
				is_relatorio_multifatorial: action.payload,
			};
		case Types.SET_RELATORIO_FISIOTERAPEUTICO:
			return {
				...state,
				is_relatorio_fisioterapeutico: action.payload,
			};
		case Types.SET_RELATORIO_MULT_TYPE:
			return {
				...state,
				selected_type_mult: action.payload,
			};
		case Types.SET_RELATORIO_FISIO_TYPE:
			return {
				...state,
				selected_type_fisio: action.payload,
			};
		case Types.SET_RELATORIO_AV_TYPE:
			return {
				...state,
				selected_type_av: action.payload,
			};
		case Types.SET_RELATORIO_QV_TYPE:
			return {
				...state,
				selected_type_qv: action.payload,
			};
		case Types.SET_CURRENT_EXERCISE_HISTORY:
			return {
				...state,
				current_exercise_history: action.payload,
			};
		case Types.SET_CURRENT_PAGE_FROM_HISTORY_ASYMMETRY:
			return {
				...state,
				current_page_from_history_asymmetry: action.payload,
			};
		case Types.SET_CURRENT_SELECTED_REPORTS:
			return {
				...state,
				current_selected_reports: action.payload,
			};
		case Types.SET_CURRENT_SELECTED_EXERCISES:
			return {
				...state,
				current_selected_exercises: action.payload,
			};
		case Types.SET_CURRENT_SELECTED_PERIOD:
			return {
				...state,
				current_selected_period: action.payload,
			};
		case Types.SET_OPTIONS_FOR_DELETED_OR_MIGRATE:
			return {
				...state,
				data_for_delete_or_migrate: action.payload,
			};
		case Types.SET_ITEMS_SELECTED_FOR_DELETED_OR_MIGRATE:
			return {
				...state,
				data_selected_for_delete_or_migrate: action.payload,
			};
		case Types.SET_DATA_SYNC_LAST_REPORT_EMISSION:
			return {
				...state,
				last_issued_report: action.payload
			}
		case Types.GET_EMITIR_RELATORIO:
			return {
				...state,
			};
		default:
			return state;
	}
}

export const RelatorioActions = {
	set_type_relatorio: (type: any) => ({
		type: Types.SET_CURRENT_TYPE_RELATORIO,
		payload: type,
	}),
	set_marcar_todas: (status: any) => ({
		type: Types.SET_MARCAR_TODAS,
		payload: status,
	}),
	set_type_relatorio_compartilhar: (type: any) => ({
		type: Types.SET_CURRENT_TYPE_RELATORIO_COMPARTILHAR,
		payload: type,
	}),
	set_selected_exercise: (exercise: any) => ({
		type: Types.SET_SELECTED_EXERCISE,
		payload: exercise,
	}),
	set_open_modal_exer: (status: any) => ({
		type: Types.SET_OPEN_MODAL_EX,
		payload: status,
	}),
	set_periodo: (p: any) => ({
		type: Types.SET_PERIODO,
		payload: p,
	}),
	set_data_inicio: (data: any) => ({
		type: Types.SET_DATA_INICIO,
		payload: data,
	}),
	set_data_final: (data: any) => ({
		type: Types.SET_DATA_FINAL,
		payload: data,
	}),
	set_current_assimetria: (type: any) => ({
		type: Types.SET_CURRENT_ASSIMETRIA,
		payload: type,
	}),
	set_exers_forca_tempo: (exers: any) => ({
		type: Types.SET_EXERS_FORCA_TEMPO,
		payload: exers,
	}),
	set_op_aval_funci: (op: any) => ({
		type: Types.SET_OP_AVAL_FUNCI,
		payload: op,
	}),
	set_data_evolution: (data: any) => ({
		type: Types.SET_DATA_EVOLUTION,
		payload: data,
	}),
	set_data_evolution_med: (data: any) => ({
		type: Types.SET_DATA_EVOLUTION_MED,
		payload: data,
	}),
	set_status_modal_d_execution: (status: any) => ({
		type: Types.SET_STATUS_MODAL_D_EXECUTION,
		payload: status,
	}),
	set_select_execution: (exe: any) => ({
		type: Types.SET_SELECTED_EXECUTION,
		payload: exe,
	}),
	set_relatorio_evolucao: (exe: any) => ({
		type: Types.SET_RELATORIO_EVOLUTION,
		payload: exe,
	}),
	set_relatorio_assimetria: (exe: any) => ({
		type: Types.SET_RELATORIO_ASSIMETRIA,
		payload: exe,
	}),
	set_relatorio_forca_tempo: (exe: any) => ({
		type: Types.SET_RELATORIO_FORCA_TEMPO,
		payload: exe,
	}),
	set_relatorio_multifatorial: (exe: any) => ({
		type: Types.SET_RELATORIO_MULTIFATORIAL,
		payload: exe,
	}),
	set_relatorio_fisioterapeutico: (exe: any) => ({
		type: Types.SET_RELATORIO_FISIOTERAPEUTICO,
		payload: exe,
	}),
	set_relatorio_fisio_type: (exe: any) => ({
		type: Types.SET_RELATORIO_FISIO_TYPE,
		payload: exe,
	}),
	set_relatorio_mult_type: (exe: any) => ({
		type: Types.SET_RELATORIO_MULT_TYPE,
		payload: exe,
	}),
	set_relatorio_avaliacao_type: (exe: any) => ({
		type: Types.SET_RELATORIO_AV_TYPE,
		payload: exe,
	}),
	set_relatorio_qv_type: (exe: any) => ({
		type: Types.SET_RELATORIO_QV_TYPE,
		payload: exe,
	}),
	request_delete_execution: () => ({
		type: Types.REQUEST_DELETE_EXECUTION,
	}),
	request_delete_execution_finally: () => ({
		type: Types.REQUEST_DELETE_EXECUTION_FINALLY,
	}),
	request_delete_execution_success: (status: any) => ({
		type: Types.REQUEST_DELETE_EXECUTION_SUCCESS,
		payload: status,
	}),
	reset_deletions: () => ({
		type: Types.RESET_DELETIONS,
	}),
	set_infos_history_executions: (dataD: any, dataE: any, his: any) => ({
		type: Types.SET_INFO_HISTORY_EXECUTIONS,
		payload: { dataD, dataE, his },
	}),
	personalizar_relatorio_request: (data: any) => ({
		type: Types.REQUEST_PERSONALIZAR_RELATORIO,
		payload: data,
	}),
	personalizar_relatorio_request_cancel: () => ({
		type: Types.REQUEST_PERSONALIZAR_RELATORIO_CANCEL,
	}),
	exportar: () => ({
		type: Types.EXPORTAR,
	}),
	set_imagem_relatorio: (data: any) => ({
		type: Types.IMAGEM_RELATORIO,
		payload: data,
	}),
	set_exercicios_historicos: (data: any) => ({
		type: Types.EXERCICIOS_HISTORICOS,
		payload: data,
	}),
	set_exercicios_compartilhar: (data: any) => ({
		type: Types.COMPARTILHAR_LISTA,
		payload: data,
	}),
	set_data_compartilhar: (data: any) => ({
		type: Types.COMPARTILHAR_DATA,
		payload: data,
	}),
	set_atribuir_tags: (data: any) => ({
		type: Types.SET_COMPARTILHAR,
		payload: data,
	}),
	set_atribuir_tags_cancel: () => ({
		type: Types.SET_COMPARTILHAR_CANCEL,
	}),
	set_message_compartilhar: (data: any) => ({
		type: Types.SET_MESSAGE_COMPARTILHAR,
		payload: data,
	}),
	set_age: (age: any) => ({
		type: Types.SET_AGE,
		payload: age,
	}),
	set_current_exercise_history: (exer: Array<ExerCucaoProp>) => ({
		type: Types.SET_CURRENT_EXERCISE_HISTORY,
		payload: exer,
	}),
	set_current_page_from_history_asymmetry: (id_exercise: any) => ({
		type: Types.SET_CURRENT_PAGE_FROM_HISTORY_ASYMMETRY,
		payload: id_exercise,
	}),
	set_current_selected_reports: (payload: any) => ({
		type: Types.SET_CURRENT_SELECTED_REPORTS,
		payload: payload,
	}),
	current_selected_exercises: (payload: any) => ({
		type: Types.SET_CURRENT_SELECTED_EXERCISES,
		payload: payload,
	}),
	set_current_selected_period: (payload: any) => ({
		type: Types.SET_CURRENT_SELECTED_PERIOD,
		payload: payload,
	}),
	get_emitir_relatorio: () => ({
		type: Types.GET_EMITIR_RELATORIO,
	}),
	set_options_for_deleted_or_migrate: (data:any) => ({
		type: Types.SET_OPTIONS_FOR_DELETED_OR_MIGRATE,
		payload:data
	}),
	set_items_selected_for_deleted_or_migrate: (data:any) => ({
		type: Types.SET_ITEMS_SELECTED_FOR_DELETED_OR_MIGRATE,
		payload:data
	}),
	sync_last_report_emission: ()=> ({
		type:Types.SYN_LAST_EMISSION,
	}),
	set_data_last_report_issued: (data:any)=> ({
		type:Types.SET_DATA_SYNC_LAST_REPORT_EMISSION,
		payload: data
	}),
};
