import styled from "styled-components";

export const S1Desk = styled.text`
	//styleName: S1-Desk;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
`;

export const S2Desk = styled.text`
	//styleName: S2-Desk;
	font-family: "NotoSans-Regular";
	font-size: 1.1rem;
	font-weight: 500;
	line-height: 36px;
	color: #323d6e;
`;

export const S3Desk = styled.text`
	//styleName: S3-Desk;
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 36px;
	color: #323d6e;
`;

export const P1Desk = styled.text`
	/* styleName: P1-Desk; */
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 400;
	line-height: 36px;
	color: #2a3048;
`;

export const P2Desk = styled.text`
	/* styleName: P1-Desk; */
	font-family: "NotoSans-Regular";
	font-size: 0.9rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 1.875rem */
`;

export const P3Desk = styled.text`
	/* styleName: P3-Desk; */
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 27px;
	color: #797d8d;
`;

export const H2Desk = styled.text`
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
`;

export const H3Desk = styled.text`
	/* H3-Desk */
	font-family: "NotoSans-Regular";
	font-size: 1.5rem;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
`;

export const DescriptionDesk = styled.text`
	//styleName: Description-Desk;
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	color: #000e4b;
`;

