import {
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import { TextsProps } from "../../../database/txt.interface";
import { Container, Close,  Footer, Button, TextButton, Content, LoadComponent } from "./styles";
import moment from "moment";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import{ReactComponent as WorningRed} from "../../../assets/kinologyIcons/svg/WorningRed.svg";
import{ReactComponent as WorningYellow} from "../../../assets/kinologyIcons/svg/warningForgot.svg";
import { Colors } from "../../../styles/colors";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import Text from "../../Texts/Text";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { DashBoardActions } from "../../../store/ducks/dashboard";

interface ModalProps {
	isVisible: boolean
	setIsVisible: (status:boolean)=>void
	typeModal: 'deleted' | 'migrate' | 'redirectToEvolution'
	itemList?: any,
	userId?: number
	user?: any
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
	})
);


const ModalConfirmMigrateOrDelete: React.FC<ModalProps> = ({
	isVisible = false,
	setIsVisible,
	typeModal,
	itemList,
	userId,
	user
}) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const load_deleted_execucao: TextsProps = useSelector(
		(state: any) => state.avaliation.execucao_loading
	);
	const classes = useStyles();
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const language = useSelector((state: any) => state.configs.language);

	const ids = itemList?.map((item:any)=> item?.i?.ID_EXECUCAO)

	const loading_execucao = useSelector((state:any)=> state.exercicios.loading_execucao)

	const dat_request = {
		txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
		ID_EXECUCAO: ids,
	};
	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);

	useEffect(()=>{
		if(!load_deleted_execucao )[
			setIsVisible(false)
		]
	},[load_deleted_execucao, execucoes])


	const data_request = {
		txDataHora: moment(new Date()).format('YYYY-MM-DD HH:mm:ss').toString(),
		ID_EXECUCAO: ids,
		ID_USUARIO: userId,
	};

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				BackdropProps={{ style: { backgroundColor: 'transparent' }}}
				open={isVisible}
			>
				<Fade in={isVisible}>
					<Container style={{ height: typeModal === 'migrate' && '710px' || '508px' }}>
						<Close
							className="close"
							src={buttonClose}
							onClick={() => setIsVisible(false)}
							alt="Close"
							style={{width: '1.2rem', height: '1.2rem'}}
						/>
						<Content >
							{
								typeModal === 'deleted'
								? <WorningRed  style={{alignSelf: 'center'}}/>
								: <WorningYellow  style={{alignSelf: 'center'}}/>
							}
							{typeModal === 'deleted' && (
								<>
									<Text
										title={ current_language?.deseja_excluir_execucao}
										fontSize={1.35}
										textColor={Colors?.primary_blue_k_main}
										fontWeight={600}
										marginTop={32}
										fontFamily="NotoSans-Regular"
									/>
									<Text
										title={ current_language?.confirmar_excluir}
										fontSize={1.15}
										textColor={Colors?.primary_blue_k_main}
										marginTop={32}
										fontFamily="NotoSans-Regular"
									/>
									<div>
										{itemList?.map((item: any) => (
											
											<Text
												title={`\u2022 ${item.x} ${item.i.EXECUCAO_EXECUTADA.split(" ")[1]} Max. ${item.y}`}
												fontSize={1.15}
												textColor={Colors?.primary_blue_k_main}
												marginTop={12}
												fontWeight={600}
												fontFamily="NotoSans-Regular"
											/>
										))}
									</div>
								</>
							) || typeModal === 'migrate' && (
								<>
									<Text
										title={ current_language?.deseja_migrar_execucao}
										fontSize={1.35}
										fontWeight={600}
										textColor={Colors?.primary_blue_k_main}
										marginTop={32}
										fontFamily="NotoSans-Regular"
									/>
									<Text
										title={ current_language?.confirme_dados}
										fontSize={1.35}
										textColor={Colors?.primary_blue_k_main}
										marginTop={32}
										fontFamily="NotoSans-Regular"
									/>
									<div>
										{itemList?.map((item: any) => (
											
											<Text
												title={`\u2022 ${item.x} ${item.i.EXECUCAO_EXECUTADA.split(" ")[1]} Max. ${item.y}`}
												fontSize={1.15}
												textColor={Colors?.primary_blue_k_main}
												marginTop={12}
												fontWeight={600}
												fontFamily="NotoSans-Regular"
											/>
										))}
									</div>
									<Text
										title={ current_language?.para_paciente}
										fontSize={1.15}
										textColor={Colors?.primary_blue_k_main}
										marginTop={32}
										fontFamily="NotoSans-Regular"
									/>
									<Text
										title={user?.USUARIO_NOME}
										fontSize={1.15}
										textColor={Colors?.primary_blue_k_main}
										fontWeight={600}
										marginTop={12}
										fontFamily="NotoSans-Regular"
									/>
								</>
							) || (
								<>
									<Text
										title={current_language?.deseja_migrar_excluir}
										fontSize={1.35}
										fontWeight={600}
										textColor={Colors?.primary_blue_k_main}
										marginTop={32}
										fontFamily="NotoSans-Regular"
									/>
									<Text
										title={current_language?.sera_redirecionado}
										fontSize={1.15}
										textColor={Colors?.primary_blue_k_main}
										fontWeight={600}
										marginTop={12}
										fontFamily="NotoSans-Regular"
									/>
									<Text
										title={current_language?.historico_forca}
										fontSize={1.15}
										textColor={Colors?.primary_blue_k_main}
										fontWeight={600}
										marginTop={12}
										fontFamily="NotoSans-Regular"
									/>
									<Text
										title={current_language?.sera_redirecionado2}
										fontSize={1.15}
										textColor={Colors?.primary_blue_k_main}
										fontWeight={600}
										marginTop={12}
										fontFamily="NotoSans-Regular"
									/>
								</>
							)}
						</Content>
						<Footer>
							<Button 
								onClick={() => setIsVisible(false)}
								bgColor={Colors.white}
								borderColor={Colors.primary_blue_k_main}
							>
								<TextButton color={Colors?.primary_blue_k_main} >{current_language?.botao_cancelar}</TextButton>
							</Button>
							<Button
									onClick={()=>{
										typeModal === 'deleted' && dispatch(AvaliationActions.excluir_execucao(dat_request))
										|| typeModal === 'migrate' && dispatch(ExerciciosActions.editar_execucao_request(data_request))
										|| dispatch(DashBoardActions.set_current_page("relatorio_evolucao"))
									}}
									bgColor={ typeModal === 'deleted'
										? Colors.tertirary_red_1_dark
										: Colors?.primary_blue_k_main
									}
									borderColor={typeModal === 'deleted'
										? Colors.tertirary_red_1_dark
										: Colors?.primary_blue_k_main
									}
							>
										{
											load_deleted_execucao || loading_execucao
											? <LoadComponent />
											:
												<TextButton color={Colors?.white} >
													{typeModal === 'deleted' &&
														current_language?.modal_excluir ||
														typeModal === 'migrate' &&
														current_language?.migrar ||
														current_language?.modal_confirmar
													}
												</TextButton>
										}
							</Button>
						</Footer>
					</Container>
				</Fade>
			</Modal>
		</div>
	);
};

export default ModalConfirmMigrateOrDelete;