import {
	createStyles,
	makeStyles,
	MenuItem,
	Select,
	Theme,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import {
	ExecucaoDados,
	ExecucaoProps,
} from "../../../interfaces/execucao.interface";
import { Exercicios2Props } from "../../../interfaces/exercicio.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { getGroupingSide, getPermission } from "../../../utils/funcs";
import { TypesLanguage, TypesPermission } from "../../../utils/types";
import { ExerAfterListProps } from "../Evolucao";
import NenhumRelatorio from "../NenhumRelatorio";
import RenderGrafic from "./_renderGrafic";
import { Content, Exercice } from "./styles";
import { ConfigsActions } from "../../../store/ducks/configs";
import {
	ContainerDash,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import styled from "styled-components";
import ArrowRight from "../../../assets/kinologyIcons/svg/arrow_forward_ios.svg";
import ArrowLeft from "../../../assets/kinologyIcons/svg/arrow_forward_ios_left.svg";
import PdfIcon from "../../../assets/kinologyIcons/svg/pdf.svg";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import DropDownButton from "../../../components/DropDownButton";
import MigrarIcon from "../../../assets/kinologyIcons/svg/migrar.svg";
import ModalConfirmMigrateOrDelete from "../../../components/Modais/ModalConfirMigrateOrDelete";
import { Colors } from "../../../styles/colors";

const ForcaTempo: React.FC = () => {
	const useStyles = makeStyles((theme: Theme) =>
		createStyles({
			button: {
				display: "block",
				marginTop: theme.spacing(2),
				border: "none",
				fontSize: 25,
			},
			formControl: {
				margin: theme.spacing(1),
				border: "none",
				fontSize: 25,
			},
			select: {
				border: 0,
				fontSize: 20,
				fontWeight: "bold",
				fontStyle: "normal",
				color: "#5C5B5B",
			},
		})
	);

	const dispatch = useDispatch();
	const [age, setAge] = React.useState<string>();
	const [executionsFilter, setExecutions] = React.useState<any>([]);

	const [array_for_grafics, set_array_for_grafics] = React.useState<any>([]);

	const [open, setOpen] = React.useState(false);

	const language = useSelector((state: any) => state.configs.language);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const [finalExers, setFinalExers] = useState<Exercicios2Props[]>([]);
	const [finalExersRelatorios, setFinalExersRelatorios] = useState<
		ExerAfterListProps[]
	>([]);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);

	const opAvalFunci = useSelector((state: any) => state.relatorios.opAvalFunci);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const [total_position_direito, set_total_position_direito] =
		React.useState(0);
	const [current_position_direito, set_current_position_direito] =
		React.useState(0);

	const [total_position_esquerdo, set_total_position_esquerdo] =
		React.useState(0);
	const [current_position_esquerdo, set_current_position_esquerdo] =
		React.useState(0);

	const [total_position_bilateral, set_total_position_bilateral] =
		React.useState(0);
	const [current_position_bilateral, set_current_position_bilateral] =
		React.useState(0);

	const [total_position_sem_divisao, set_total_position_sem_divisao] =
		React.useState(0);
	const [current_position_sem_divisao, set_current_position_sem_divisao] =
		React.useState(0);
	const [modalConfirmVisible, setModalConfirmVisible] =  useState(false);

	useEffect(() => {
		const filter: any = finalExersRelatorios.find(
			(e) => e.ID_EXERCICIO.toString() == age
		);

		const execfiltered = execucoes.filter((exe) => {
			return (
				exe.ID_USUARIO == paciente?.ID_USUARIO &&
				exe.ID_EXERCICIO == filter?.ID_EXERCICIO &&
				exe.EXECUCAO_EXCLUIDA == false
			);
		});

		const executions_filtred_direito: any = [];
		const executions_filtred_esquerdo: any = [];
		const executions_filtred_bilateral: any = [];
		const executions_filtred_sem_divisao: any = [];

		execfiltered.forEach((item: any) => {
			if (item.EXECUCAO_MODO == "D") executions_filtred_direito.push(item);
			if (item.EXECUCAO_MODO == "E") executions_filtred_esquerdo.push(item);
			if (item.EXECUCAO_MODO == "B") executions_filtred_bilateral.push(item);
			if (item.EXECUCAO_MODO == "S") executions_filtred_sem_divisao.push(item);
		});

		set_array_for_grafics([
			executions_filtred_direito,
			executions_filtred_esquerdo,
			executions_filtred_bilateral,
			executions_filtred_sem_divisao,
		]);

		set_total_position_direito(executions_filtred_direito.length - 1);
		set_current_position_direito(set_current_position_direito.length - 1);

		set_total_position_esquerdo(executions_filtred_esquerdo.length - 1);
		set_current_position_esquerdo(set_current_position_esquerdo.length - 1);

		set_total_position_bilateral(executions_filtred_bilateral.length - 1);
		set_current_position_bilateral(set_current_position_bilateral.length - 1);

		set_total_position_sem_divisao(executions_filtred_sem_divisao.length - 1);
		set_current_position_sem_divisao(
			set_current_position_sem_divisao.length - 1
		);

		setExecutions(execfiltered);
	}, [age, opAvalFunci, paciente, is_open_toast]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		setAge(event.target.value as string);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		!getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_EVOLUCAO,
			dispatch
		)
			? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
			: setOpen(true);
	};

	const classes = useStyles();

	const exercicios: Exercicios2Props[] = useSelector(
		(state: any) => state.exercicios.exercicios
	);

	dispatch(AppActions.set_number_grafics(executionsFilter.length));
	useEffect(() => {
		const ids: any = [];
		const modosForExer: any = {};
		execucoes.forEach((e) => {
			if (e.ID_USUARIO == paciente.ID_USUARIO) {
				ids.push(e.ID_EXERCICIO);
				modosForExer[`${e.ID_EXERCICIO}-${e.EXECUCAO_MODO}`] = e.EXECUCAO_MODO;
			}
		});

		const filter = exercicios.filter((e) => {
			return ids.includes(e.ID_EXERCICIO);
		});

		let exers: any = [];
		filter.forEach((exer) => {
			let pro: any;
			for (pro in modosForExer) {
				if (exer.ID_EXERCICIO == pro.split("-")[0]) {
					const filt: any = exers.find(
						(ex: any) => ex.ID_EXERCICIO == exer.ID_EXERCICIO
					);
					if (filt) {
						const add = {
							...exer,
							modo: [...filt.modo, modosForExer[pro]],
						};
						exers = exers.filter(
							(ex: any) => ex.ID_EXERCICIO != exer.ID_EXERCICIO
						);
						exers.push(add);
					} else {
						exers.push({
							...exer,
							modo: [modosForExer[pro]],
						});
					}
				}
			}
		});
		setFinalExers(exers);
	}, []);

	useEffect(() => {
		const finalExers2: any = [];

		function ChangeType() {
			if (opAvalFunci == "AVALIACAO") {
				return "a";
			} else {
				return "f";
			}
		}

		finalExers.forEach((exer: any, index) => {
			if (exer.EXERCICIO_EXCLUIDO == false) {
				if (
					execucoes.find(
						(item) =>
							item.ID_EXERCICIO == exer.ID_EXERCICIO &&
							item.EXECUCAO_EXCLUIDA == false 
					)
				) {
					exer.modo.forEach((e: string) => {
						finalExers2.push({
							EXERCICIO_NOME:
								language == TypesLanguage.PT
									? finalExers[index]?.EXERCICIO_TITULO_PT
									: language == TypesLanguage.EN
									? finalExers[index]?.EXERCICIO_TITULO_EN
									: finalExers[index]?.EXERCICIO_TITULO_ES,
							ID_EXERCICIO: exer.ID_EXERCICIO,
							MODO: e[0],
						});
					});
				}
			}
		});

		const result: any = Object.values(
			finalExers2.reduce((acc: any, item: any) => {
				if (!acc[item.ID_EXERCICIO]) {
					acc[item.ID_EXERCICIO] = { ...item };
				} else {
					acc[item.ID_EXERCICIO].MODO += item.MODO;
				}
				return acc;
			}, {})
		);
		setAge(result[0]?.ID_EXERCICIO);
		setFinalExersRelatorios(result);
	}, [finalExers, is_open_toast, opAvalFunci]);

	const [permission, setPermission] = useState(true);

	useEffect(() => {
		const permissionTimeForce: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_FORCA_TEMPO
		);
		if (!permissionTimeForce) {
			setPermission(false);
			dispatch(ConfigsActions.set_title_modal_upgrade(true));
		} else {
			dispatch(ConfigsActions.set_title_modal_upgrade(false));
		}
	}, []);

	const _renderGrafics = (item: any) => {
		function changePosition(position: number) {
			if (item[0].EXECUCAO_MODO == "D") {
				set_current_position_direito(position);
			}
			if (item[0].EXECUCAO_MODO == "E") {
				set_current_position_esquerdo(position);
			}
			if (item[0].EXECUCAO_MODO == "B") {
				set_current_position_bilateral(position);
			}
			if (item[0].EXECUCAO_MODO == "S") {
				set_current_position_sem_divisao(position);
			}
		}

		const currentPosition = () => {
			const position: any = {
				D: current_position_direito,
				E: current_position_esquerdo,
				B: current_position_bilateral,
				S: current_position_sem_divisao,
			};
			return position[item[0].EXECUCAO_MODO];
		};

		const currentTotalPosition = () => {
			const position: any = {
				D: total_position_direito,
				E: total_position_esquerdo,
				B: total_position_bilateral,
				S: total_position_sem_divisao,
			};
			return position[item[0].EXECUCAO_MODO];
		};

		return (
			<>
				<ModalConfirmMigrateOrDelete
					isVisible={modalConfirmVisible}
					setIsVisible={ (visible:boolean) => setModalConfirmVisible(visible)}
					typeModal="redirectToEvolution"
				/>
				{item.length > 0 && (
					<>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								zIndex: 999
							}}>
								<P2Desk style={{ marginTop: "2rem", marginBottom: "2rem" }}>
									<strong>
										{item[currentPosition()]?.EXERCICIO_TITULO_PT}{" "}
										{getGroupingSide(
											item[currentPosition()]?.EXECUCAO_MODO,
											current_language
										)?.toLowerCase()}
									</strong>
								</P2Desk>
								<div style={{ width: "2rem", height: "2rem", marginTop: '1rem', zIndex: 999}}>
									<DropDownButton 
										text={current_language?.migrar_ou_excluir}
										icon={MigrarIcon}
										setIsVisible={ (visible:boolean) => setModalConfirmVisible(visible)}
									/>
								</div>	
							</div>
						</div>
						<div
							style={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
								flexDirection: "column",
								alignItems: "center",
							}}
						>
							<P3Desk>
								<strong>Data da Avaliação</strong>
							</P3Desk>
							<div style={{ display: "flex", alignItems: "center" }}>
								<Icon
									onClick={() => {
										changePosition(
											currentPosition() - 1 >= 0
												? currentPosition() - 1
												: currentPosition()
										);
									}}
									src={ArrowLeft}
									style={
										currentPosition() - 1 >= 0
											? { opacity: 1 }
											: { opacity: 0.2 }
									}
								/>
								<P2Desk
									style={{
										marginLeft: "1rem",
										marginRight: "1rem",
									}}
								>
									{item[currentPosition()]?.EXECUCAO_EXECUTADA}
								</P2Desk>
								<Icon
									onClick={() => {
										changePosition(
											currentPosition() + 1 <= currentTotalPosition()
												? currentPosition() + 1
												: currentPosition()
										);
									}}
									src={ArrowRight}
									style={
										currentPosition() + 1 <= currentTotalPosition()
											? { opacity: 1 }
											: { opacity: 0.2 }
									}
								/>
							</div>
						</div>
						<div className="row">
							{item.length == 0 && <NenhumRelatorio />}
							<RenderGrafic
								key={item[currentPosition()]?.exe?.ID_EXECUCAO}
								execucao={item[currentPosition()]}
							/>
						</div>
					</>
				)}
			</>
		);
	};

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<ProfilePicture
						src={paciente?.USUARIO_FOTO ? paciente?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{paciente.USUARIO_NOME}
					</S1Desk>
				</div>
				<Icon
					style={{ width: "2rem", height: "2rem" }}
					onClick={() => {
						dispatch(DashBoardActions.set_current_page("emissao_relatorios"))
						dispatch(
							RelatorioActions.set_current_selected_reports(["CURVAS_FORCA"])
						);
					}
					}
					src={PdfIcon}
				/>
			</div>

			<WhiteContainer>
				<P2Desk style={{ marginBottom: "2rem" }}>Curvas de Força</P2Desk>
				<Content>
					<Exercice>
						{permission ? (
							<>
								<Escolha style={{ marginBottom: "1rem" }}>
									Escolha para visualizar
								</Escolha>
								<div className="Divtitle">
									<FormControl>
										{finalExersRelatorios.length > 0 ? (
											<Select
												className={"selectTitle"}
												labelId="demo-controlled-open-select-label"
												id="demo-controlled-open-select"
												open={open}
												value={age ? age : ""}
												onClose={handleClose}
												onOpen={handleOpen}
												onChange={handleChange}
											>
												{finalExersRelatorios.map((item) => (
													<MenuItem
														key={item.ID_EXERCICIO}
														value={item.ID_EXERCICIO}
													>
														{item.EXERCICIO_NOME}
													</MenuItem>
												))}
											</Select>
										) : (
											<p></p>
										)}
									</FormControl>
								</div>
								{array_for_grafics.map((item: any) => _renderGrafics(item))}
							</>
						) : (
							<NenhumRelatorio />
						)}
					</Exercice>
				</Content>
			</WhiteContainer>
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const Escolha = styled.div`
	color: var(--Color, #000e4b);
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 600;
	line-height: 120%;
`;

export default ForcaTempo;
