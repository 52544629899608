import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import {
	ExecucaoPicos,
	ExecucaoProps,
} from "../../../interfaces/execucao.interface";
import { AvaliationActions } from "../../../store/ducks/avaliation";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import exercicios from "../../../store/ducks/exer";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import { Charts, Grafic } from "./styles";
import Exclude from "../../../assets/icons/exclude.svg";
import Migration from "../../../assets/icons/migration.svg";
import { AppActions } from "../../../store/ducks/app";
import { Colors } from "../../../styles/colors";
import styled from "styled-components";
import { P2Desk, P3Desk } from "../../../styles/styles_text";
import Arrow from "../../../assets/kinologyIcons/svg/arrow_drop.svg";
import { Icon } from "../../../styles/global";

interface RenderGraficProps {
	execucao: ExecucaoProps;
}

const RenderGrafic: React.FC<RenderGraficProps> = ({ execucao }: any) => {
	const dispatch = useDispatch();
	const [arr1, setArr1] = useState<any>([]);
	const [arr2, setArr2] = useState<any>([]);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	useEffect(() => {
		const aux_execucoes: any = execucao;
		const aux = aux_execucoes?.EXECUCAO_DADOS;
		const arrayMenor: any[] = [];
		aux.map((item: any) => {
			if (item.VALOR < execucao?.EXECUCAO_PESOMIN) {
				const ultimo = arrayMenor.slice(-1)[0];
				if (ultimo !== undefined) {
					arrayMenor.push({ TEMPO: ultimo.TEMPO, VALOR: 0 });
				}
				arrayMenor.push({ TEMPO: item.TEMPO, VALOR: 0 });
			} else {
				arrayMenor.push(item);
			}
		});

		setArr1(arrayMenor);
		setArr2(aux);
	}, []);

	let min = null;
	let lastTime = null;
	const obj: any = [];
	let color = null;
	obj.push([
		"tempo",
		"peso",
		{ role: "style" },
		{ role: "annotation" },
		"min",
		{ role: "style" },
		{ role: "annotation" },
	]);

	for (let i = 0; i < execucao?.EXECUCAO_DADOS.length; i++) {
		const data = execucao?.EXECUCAO_DADOS[i];
		//
		if (data.VALOR < execucao?.EXECUCAO_PESOMIN) {
			color = Colors.blue_k_light_3;
		} else {
			color = "#0026cc";
		}

		min = Math.round(execucao?.EXECUCAO_PESOMIN);

		let pico = null;

		execucao?.EXECUCAO_PICOS.map((item: any) => {
			if (item.VALOR == data.VALOR && item.TEMPO == data.TEMPO) {
				pico = item.VALOR;
			}
		});

		obj.push([
			data.TEMPO,
			data.VALOR,
			color,
			pico,
			min,
			Colors.primary_blue_k_main,
			null,
		]);
		lastTime = [
			data.TEMPO,
			data.VALOR,
			color,
			pico,
			min,
			Colors.primary_blue_k_main,
			null,
		];
	}

	lastTime?.splice(6, 1, "Min. " + execucao?.EXECUCAO_PESOMIN);
	obj.push(lastTime);

	const [aprovado, setAprovado] = useState(false);

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	useEffect(() => {
		if (is_open_toast == true) {
			setAprovado(false);
		}
	}, [is_open_toast]);

	const excluir = (data: any) => {
		setAprovado(true);
		const dat_request = {
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
			ID_EXECUCAO: [data],
		};
		dispatch(AvaliationActions.excluir_execucao(dat_request));
		dispatch(DashBoardActions.execucoes_request(false));
	};

	const MigrationRequest = (execucao: ExecucaoProps) => {
		dispatch(AppActions.set_title_export_data(false));
		dispatch(AppActions.set_modal_migracao(true, execucao));
	};

	const _renderTables = () => {
		function arredondarPersonalizado(valor: number) {
			const parteInteira = Math.floor(valor);
			const parteDecimal = valor - parteInteira;
			if (parteDecimal <= 0.5) {
				return Math.floor(valor);
			} else {
				return Math.ceil(valor);
			}
		}

		type TypeObjetivos = "explosao" | "hipertrofia" | "resistencia";

		const percentes_rm = {
			explosao: { min: 85, max: 100 },
			hipertrofia: { min: 70, max: 85 },
			resistencia: { min: 20, max: 60 },
		};

		function CalcRmPercent(objetivo: TypeObjetivos) {
			let min = (execucao.RMi * percentes_rm[objetivo].min) / 100;
			let max = (execucao.RMi * percentes_rm[objetivo].max) / 100;
			min = arredondarPersonalizado(min);
			max = arredondarPersonalizado(max);

			return `${min} a ${max} kg`;
		}
		return (
			<>
				{/* cabecalho */}
				<Table style={{ marginTop: "3rem" }}>
					<thead>
						<tr>
							<Th>MÁXIMO (kg)</Th>
							<Th>TEMPO CONTRAÇÃO (s)</Th>
							<Th>TDF</Th>
						</tr>
					</thead>
					<tr>
						<Td>{execucao?.EXECUCAO_EXECMAX}</Td>
						<Td>
							{execucao?.EXECUCAO_TEMPO_CONTRACAO != ""
								? execucao?.EXECUCAO_TEMPO_CONTRACAO
								: execucao?.EXECUCAO_TEMPO}
							(s)
						</Td>
						<Td>
							{execucao?.TDF} {execucao.MEDIDA_TDF}
						</Td>
					</tr>
					<thead>
						<tr>
							<Th>MÉDIA (kg)</Th>
							<Th>TEMPO TOTAL (s)</Th>
							<Th>REPETIÇÕES</Th>
						</tr>
					</thead>
					<tr>
						<Td>{execucao?.EXECUCAO_EXECMED}</Td>
						<Td>{execucao?.EXECUCAO_TEMPO}</Td>
						<Td>{execucao?.EXECUCAO_REPS}</Td>
					</tr>
				</Table>
				<ContainerInfo>
					<Infos>
						*Os resultados do teste de 1RM isométrico podem não corresponder
						diretamente aos resultados do teste de 1RM dinâmico, pois envolvem
						diferentes tipos de contração muscular.
					</Infos>
				</ContainerInfo>

				{/* Taxa de Desenvolvimento de Força */}
				<ContainerInfo>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "1rem",
						}}
					>
						<P2Desk style={{ marginRight: "1rem" }}>
							Taxa de Desenvolvimento de Força
						</P2Desk>{" "}
						<Icon src={Arrow} />
					</div>

					<Infos>
						*Os resultados do teste de 1RM isométrico podem não corresponder
						diretamente aos resultados do teste de 1RM dinâmico, pois envolvem
						diferentes tipos de contração muscular.
					</Infos>

					<div
						style={{
							display: "flex",
							flexDirection: "row",
							width: "100%",
							marginTop: "1rem",
						}}
					>
						<div
							style={{
								backgroundColor: "#DEDFE3",
								paddingLeft: "1.5rem",
								paddingRight: "1.5rem",
							}}
						>
							<P3Desk style={{ color: "#414D4A" }}>TDF</P3Desk>
						</div>
						<div
							style={{
								backgroundColor: "#FFFFFF",
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<P3Desk style={{ color: "#414D4A" }}>
								{execucao?.TDF} {execucao.MEDIDA_TDF}
							</P3Desk>
						</div>
					</div>
				</ContainerInfo>

				{/* Repetições Máximas */}
				<ContainerInfo>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginBottom: "1rem",
						}}
					>
						<P2Desk style={{ marginRight: "1rem" }}>Repetições Máximas</P2Desk>{" "}
						<Icon src={Arrow} />
					</div>

					<div
						style={{
							backgroundColor: "#FFFFFF",
							width: "15%",
							borderRadius: "1rem",
							display: "flex",
							justifyContent: "center",
							marginBottom: "1rem",
						}}
					>
						<P3Desk style={{ color: "#414D4A" }}>
							1 RMi = {execucao.RMi} kg
						</P3Desk>
					</div>

					<Infos>
						*Os resultados do teste de 1RM isométrico podem não corresponder
						diretamente aos resultados do teste de 1RM dinâmico, pois envolvem
						diferentes tipos de contração muscular.
					</Infos>
				</ContainerInfo>
				<ContainerInfo
					style={{
						borderRadius: "0rem 0rem 0.625rem 0.625rem",
						border: "none",
					}}
				>
					<ContainerObjetivo
						style={{ borderRadius: "0.625rem 0.625rem 0rem 0rem" }}
					>
						Objetivo: Explosão
						<Table
							style={{
								backgroundColor: "#DEDFE3",
							}}
						>
							<thead>
								<tr>
									<Th style={{ textAlign: "center" }}>%RM</Th>
									<Th style={{ textAlign: "center" }}>Carga</Th>
									<Th style={{ textAlign: "center" }}>Protocolo</Th>
								</tr>
							</thead>
							<tr style={{ backgroundColor: "#FFF" }}>
								<Td style={{ textAlign: "center" }}>85% a 100%</Td>
								<Td style={{ textAlign: "center" }}>
									{CalcRmPercent("explosao")}
								</Td>
								<Td
									style={{
										textAlign: "center",
										color: "#414D4A",
										fontWeight: "200",
									}}
								>
									3 a 5 séries com 1 a 6 repetições - executado explosivo com
									recuperação de 180 a 300 segundos
								</Td>
							</tr>
						</Table>
					</ContainerObjetivo>

					<ContainerObjetivo>
						Objetivo: Hipertrofia
						<Table
							style={{
								backgroundColor: "#DEDFE3",
							}}
						>
							<thead>
								<tr>
									<Th style={{ textAlign: "center" }}>%RM</Th>
									<Th style={{ textAlign: "center" }}>Carga</Th>
									<Th style={{ textAlign: "center" }}>Protocolo</Th>
								</tr>
							</thead>
							<tr style={{ backgroundColor: "#FFF" }}>
								<Td style={{ textAlign: "center" }}>70% a 85%</Td>
								<Td style={{ textAlign: "center" }}>
									{CalcRmPercent("hipertrofia")}
								</Td>
								<Td
									style={{
										textAlign: "center",
										color: "#414D4A",
										fontWeight: "200",
									}}
								>
									3 a 5 séries com 7 a 12 repetições - executado lento com
									recuperação de 90 a 180 segundos
								</Td>
							</tr>
						</Table>
					</ContainerObjetivo>

					<ContainerObjetivo>
						Objetivo: Resistência
						<Table
							style={{
								backgroundColor: "#DEDFE3",
							}}
						>
							<thead>
								<tr>
									<Th style={{ textAlign: "center" }}>%RM</Th>
									<Th style={{ textAlign: "center" }}>Carga</Th>
									<Th style={{ textAlign: "center" }}>Protocolo</Th>
								</tr>
							</thead>
							<tr style={{ backgroundColor: "#FFF" }}>
								<Td style={{ textAlign: "center" }}>20% a 60%</Td>
								<Td style={{ textAlign: "center" }}>
									{CalcRmPercent("resistencia")}
								</Td>
								<Td
									style={{
										textAlign: "center",
										color: "#414D4A",
										fontWeight: "200",
									}}
								>
									3 a 6 séries com no mínimo 13 repetições - executado rápido
									com recuperação de 30 a 90 segundos
								</Td>
							</tr>
						</Table>
					</ContainerObjetivo>
				</ContainerInfo>
			</>
		);
	};

	return (
		<Grafic>
			<Chart
				width={"100%"}
				height={"100%"}
				chartType="ComboChart"
				loader={<div>Loading Chart</div>}
				data={obj}
				options={{
					seriesType: "area",
					series: { 1: { type: "line" } },
					areaOpacity: 1,
					legend: "none",
					hAxis: {
						title: `${current_language?.tempo_s}`,
						titleTextStyle: { color: "#333" },
						TextPosition: "left",
						gridlines: { color: "none" },
					},
					vAxis: {
						minValue: 0,
						title: current_language?.forca_kg,
						gridlines: { color: "none" },
					},
					chartArea: {
						width: "70%",
						height: "60%",
					},
					annotations: {
						boxStyle: {
							strokeWidth: 1,
							backgroundColor: "white",
						},
						textStyle: {
							fontSize: 6,
							auraColor: "white",
							opacity: 1,
						},
					},
				}}
			/>

			{_renderTables()}
			{/* <div className="divTable">
				<table className="table">
					<thead>
						<tr className="tr">
							<th className="prop">{current_language?.data}</th>
							<th className="prop">{current_language?.maximo}</th>
							<th className="prop">{current_language?.media}</th>
							<th className="prop">{current_language?.time_1}</th>
							<th className="prop">{current_language?.contracao}</th>
							<th className="prop">
								{current_language?.config_aval_repeticoes}
							</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th className="value">
								{moment(execucao?.EXECUCAO_EXECUTADA).format("DD/MM/YY")}
							</th>
							<th className="value">{execucao?.EXECUCAO_EXECMAX}(Kg)</th>
							<th className="value">
								{execucao?.EXECUCAO_EXECMED} (Kg)
								{getPermission(
									config_servicos,
									TypesPermission.SERVICO_VALOR_REFERENCIA,
									dispatch
								) && (
									<svg
										width="13"
										height="12"
										viewBox="0 0 13 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M6.57559 2.89109L6.69902 1.70666C6.74785 1.23068 7.22491 0.9077 7.7068 1.02369C8.43178 1.19868 8.94104 1.82615 8.94104 2.54561V4.06302C8.94104 4.40051 8.94104 4.5695 9.01938 4.69299C9.06392 4.76348 9.1251 4.82298 9.19808 4.86548C9.32687 4.94097 9.50234 4.94097 9.85276 4.94097H10.0653C10.9791 4.94097 11.4358 4.94098 11.717 5.13596C11.9279 5.28246 12.0765 5.49694 12.1356 5.73993C12.2139 6.06491 12.0384 6.47139 11.6869 7.28334L11.512 7.68832C11.4105 7.92287 11.369 8.1762 11.3907 8.42828C11.5152 9.8652 10.2923 11.0756 8.79722 10.9961L3.20344 10.6967C2.59222 10.6642 2.28688 10.6477 2.01106 10.4202C1.7347 10.1927 1.68264 9.95819 1.57908 9.48972C1.35865 8.49308 1.36871 7.46412 1.60859 6.47139C1.76046 5.84742 2.40011 5.51144 3.0607 5.59144C4.81224 5.80143 6.40012 4.582 6.57559 2.89159V2.89109Z"
											stroke="#00A377"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								)}
							</th>
							<th className="value">{execucao?.EXECUCAO_TEMPO}(s)</th>
							<th className="value">
								{execucao?.EXECUCAO_TEMPO_CONTRACAO != ""
									? execucao?.EXECUCAO_TEMPO_CONTRACAO
									: execucao?.EXECUCAO_TEMPO}
								(s)
							</th>
							<th className="value">{execucao?.EXECUCAO_REPS}</th>
						</tr>
					</tbody>
				</table>
			</div> */}
		</Grafic>
	);
};

const Table = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1.37px solid var(--Neutras-Grey-K-5, #f1f2f3);
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	text-align: left;
	padding: 0.7rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 600;
	line-height: 1;
`;

const Td = styled.th`
	text-align: left;
	padding: 0.7rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const ContainerInfo = styled.div`
	display: flex;
	flex-direction: column;
	padding: 1.25rem 0.68225rem;
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	border-bottom: 1px solid #dedfe3;
`;

const Infos = styled.text`
	color: var(--Neutras-Grey-K-50, #797d8d);
	/* Table-Regular-Desk */
	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const ContainerObjetivo = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #dedfe3;
	color: var(--Neutras-Grey-K-70, #3d4259);
	text-align: center;

	font-family: "NotoSans-Regular";
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 600;
	line-height: 2.5;
`;

export default RenderGrafic;
