import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Conteiner = styled.div`
	width: 100%;
	height: 100%;
	padding-top: 2rem;
	padding-bottom: 5vh;
	padding-left: 2.5rem;
	padding-right: 2.5rem;
	background-color: white;
	border-radius: 1.25rem;
	align-items: center;
	display: flex;
	flex-direction: column;

	overflow-x: hidden;

	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
		height: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}

	a {
		text-decoration: none;
	}
`;

export const Header = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: flex-start;
`;
export const Content = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
`;
export const Content2 = styled.div`
	display: flex;
	width: 50%;
	flex-direction: column;
`;
export const Faturas = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
	margin-top: 60px;
	flex-direction: column;
`;
export const FaturasPendentes = styled.div`
	display: flex;
	width: 100%;
	cursor: pointer;

`;
export const ContainerTextFaturas = styled.div`
	display: flex;
	width: 80%;
	align-items: center;
	margin-top: 15px;
	flex-direction: row;
`;
export const ArrowFaturas = styled.div`
	display: flex;
	width: 42px;
	height: 42px;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	&:active {
		background-color: ${Colors?.primary_blue_k_main};

		.ArrowInvoices{
			color: ${Colors.white}
		}
	}
`;
export const ContainerInfoCard = styled.div`
	display: flex;
	width: 100%;
	margin-top: 30px;
	justify-content: space-between;
`;
export const CardNumbers = styled.div`
	display: flex;
	width: 80%;
	align-items: center;
`;
export const ContainerIconEdit = styled.div`
	display: flex;
	border-radius: 100%;
	justify-content: center;
	align-items: center;
	width: 42px;
	height: 42px;

	&:hover {
		background-color: ${Colors?.primary_blue_k_medium_1};

		.EditCard  {
			color: ${Colors?.white}
		}
	}
	&:active {
		background-color: ${Colors?.primary_blue_k_main};

		.EditCard  {
			color: ${Colors?.white}
		}
	}

`;
export const ContainerGoBack = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 20px;
`;

export const Footer = styled.div`
	border-top: 1px solid #e0e0e0;

	.item {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 1.5rem;
		margin-bottom: 1rem;
		p {
			cursor: pointer;
			margin-left: 1rem;
			margin-top: 0.2rem;

			font-size: 1rem;
			font-weight: 500;

			color: #5c5b5b;
		}

		svg {
			cursor: pointer;
			margin-left: 1rem;
		}
	}

	.left {
		display: flex;
		align-items: center;
	}

	.faturasPendentes {
		font-weight: 500;
		font-size: 0.875rem;

		margin-left: 1rem;

		color: ${Colors.tertirary_red_1_dark};
	}

	.faturasNaoPendentes {
		font-weight: 500;
		font-size: 0.875rem;

		margin-left: 1rem;

		color: ${Colors.secondary_green_k_medium_1};
	}
`;
