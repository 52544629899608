import React, { ReactElement, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ContainerDash, Icon } from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import { TextsProps } from "../../../database/txt.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
// import ArrowForward from "../../../assets/icons/arrow_forward_ios.svg";
import ArrowForwardClosed from "../../../assets/icons/arrow_forward_ios_closed.svg";
import Paragrafo from "../../../assets/kinologyIcons/svg/paragrafo.svg";
// import Saude from "../../../assets/kinologyIcons/svg/saude.svg";
// import Reabilitacao from "../../../assets/kinologyIcons/svg/reabilitacao.svg";
// import Esportiva from "../../../assets/kinologyIcons/svg/esportiva.svg";
// import Estetica from "../../../assets/kinologyIcons/svg/estetico.svg";
// import { TypeCategoriasAnamnese } from "../../../utils/types";
import { AnamneseActions } from "../../../store/ducks/anamnese";
import { Colors } from "../../../styles/colors";

const Anamnese: React.FC = () => {
	const dispatch = useDispatch();

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	// const [open_outras_opcoes, set_open_outras_opcoes] = useState(false);

	// interface OutrasOpcoes {
	// 	name: string;
	// 	icon: ReactElement;
	// 	key: TypeCategoriasAnamnese;
	// }

	// const outras_opcoes: Array<OutrasOpcoes> = [
	// 	{
	// 		name: "Saúde",
	// 		icon: <Icon src={Saude} />,
	// 		key: "SAUDE",
	// 	},
	// 	{
	// 		name: "Reabilitação",
	// 		icon: <Icon src={Reabilitacao} />,
	// 		key: "REABILITACAO",
	// 	},
	// 	{
	// 		name: "Esportiva",
	// 		icon: <Icon src={Esportiva} />,
	// 		key: "ESPORTIVO",
	// 	},
	// 	{
	// 		name: "Estética",
	// 		icon: <Icon src={Estetica} />,
	// 		key: "ESTETICO",
	// 	},
	// ];

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "2.5rem",
					marginBottom: "3rem",
					width: "60%",
				}}
			>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</div>

			<AreaResultadosButton
				onClick={() => {
					dispatch(DashBoardActions.set_current_page("tipos_anamnese"));
					dispatch(AnamneseActions.set_anamnese_category("ANAMNESE_V7"));
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<Inciar>
							<Icon src={Paragrafo} style={{ marginRight: "0.5rem" }} />
							{current_language?.botao_iniciar_protocolo}
						</Inciar>
						<AnamneseTitle style={{ marginLeft: "1.5rem" }}>
							Anamnese <strong style={{ color: "#00A377" }}>{`(${current_language?.perfil_paciente_novo.toLocaleUpperCase()})`}</strong>
						</AnamneseTitle>
						<Description style={{ marginTop: "0.5rem", marginLeft: "1.5rem" }}>
							{current_language?.flexivel_e_rapida}
						</Description>
					</div>
				</div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Icon src={ArrowForwardClosed} />
				</div>
			</AreaResultadosButton>

			{/* <AreaResultadosButton
				onClick={() =>
					set_open_outras_opcoes(open_outras_opcoes ? false : true)
				}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<Inciar>Escolher</Inciar>
						<P2Desk>Outras opções</P2Desk>
					</div>
				</div>
				<div style={{ display: "flex", alignItems: "center" }}>
					<Icon src={open_outras_opcoes ? ArrowForward : ArrowForwardClosed} />
				</div>
			</AreaResultadosButton>

			{outras_opcoes.map((outras_opcoes: OutrasOpcoes, index) => (
				<AreaResultadosButton
					style={{ display: open_outras_opcoes ? "flex" : "none" }}
					key={index}
					onClick={() => {
						dispatch(DashBoardActions.set_current_page("tipos_anamnese"));
						dispatch(AnamneseActions.set_anamnese_category(outras_opcoes.key));
					}}
				>
					<div style={{ display: "flex", alignItems: "center" }}>
						<div
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							{outras_opcoes.icon}
							<P2Desk style={{ marginLeft: "0.5rem" }}>
								{outras_opcoes.name}
							</P2Desk>
						</div>
					</div>
					<div style={{ display: "flex", alignItems: "center" }}>
						<Icon src={ArrowForwardClosed} />
					</div>
				</AreaResultadosButton>
			))} */}
		</ContainerDash>
	);
};

export default Anamnese;

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

const AreaResultadosButton = styled.div`
	width: 60%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;

const Inciar = styled.div`
	color: var(--Neutras-Grey-K-60, #63677a);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const AnamneseTitle = styled.div`
	color: var(--Primrias-Blue-K-Principal, var(--Color, #000e4b));
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
`;

const Description = styled.div`
	color: var(--Neutras-Grey-K-60, #63677a);
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;
