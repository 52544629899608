import styled from "styled-components";
import { Colors } from "../../styles/colors";

export const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	
	align-items: flex-start;
	justify-content: center;
	margin-top: 60px;

	.labelForInput {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		width: 100%;
		height: 100%;
	}

	.imgForInput {
		width: 100px;
		object-fit: cover;
	}

	.divFoto {
		padding: 1rem;

		position: relative;

		border: 1px dashed #8c8e86;
		box-sizing: border-box;

		display: flex;
		justify-content: center;
		align-items: center;
	}

	.xisFoto {
		position: absolute;

		top: 0.5rem;
		right: 0.5rem;

		width: 1.8rem;
		height: 1.8rem;

		display: flex;
		justify-content: center;
		align-items: center;

		border: 1px solid #8c8e86;
		border-radius: 50%;

		padding: 0.5rem;

		background: rgba(255, 255, 255, 0.85);

		cursor: pointer;
	}

	.Adicionar {
		cursor: pointer;

		margin-top: 1rem;

		display: flex;
		align-items: center;
		justify-content: center;

		p {
			color: ${Colors.primary_blue_k_main};

			font-weight: 500;
			font-size: 1.125rem;

			svg {
				margin: 0 0.5rem 0 1rem;
			}
		}

		.p {
			display: flex;
			justify-content: center;
			align-items: center;

			color: ${Colors.primary_blue_k_medium_1};

			cursor: pointer;

			font-weight: 500;
			font-size: 1.125rem;

			svg {
				margin: 0 0.5rem 0 0.5rem;
			}
		}

		.addLogo {
			color: #5c5b5b;

			font-weight: 400;
			font-size: 1.125rem;
		}
	}
`;


export const Label = styled.label``;

export const ContainerAddSignature = styled.div<{imageName?: string, img?: string}>`
    display: flex;
	width: 60%;
    height: 10rem;
    border-radius: 20px;
    align-items: center;
	padding: 15px;
    justify-content: center;
    border: 2px solid ${
        ({imageName, img})=> imageName == 'HoverPhoto'
        ? Colors.primary_blue_k_medium_1
        : imageName == 'PressSetPhoto'
            ? Colors?.primary_blue_k_main
            :  imageName ==  'SucessSetPhoto' || img
                ? Colors?.secondary_green_k_medium_1
                : Colors?.neutral_grey_k_10
    } ;
    background-color: #DEDFE320 ;

    .labelForInput {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;

export const ContainerSignature = styled.div<{imageName?: string, img?: string}>`
    display: flex;
    width: 50%;
    height: 10rem;
    border-radius: 20px;
    align-items: center;
	padding: 15px;
    justify-content: center;
	border: 1px dashed #000000;
	box-sizing: border-box;
    background-color: white ;
	position: relative;

    .labelForInput {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
`;
export const RemoveSignature = styled.div`
	display: flex;
	width: 42px;
	height: 42px;
	border-radius: 100%;
	border: 1px solid ${Colors?.primary_blue_k_main};
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 10px;
	top:10px;

	&:hover {
		border: 1px solid ${Colors?.tertirary_red_1_dark};
		background-color: ${Colors?.tertirary_red_1_dark} ;
		.TrashSignature {
			color:${Colors?.white} ;
		}
	}
	&:active {
		border: 1px solid ${Colors?.tertirary_red_2_dark};
		background-color: ${Colors?.tertirary_red_2_dark} ;

		.TrashSignature {
			color:${Colors?.white} ;
		}
	}
`;
export const ContainerIconEditSignature = styled.div`
	display: flex;
	width: 42px;
	height: 42px;
	border-radius: 100%;
	border:none;
	justify-content: center;
	align-items: center;
	
	&:hover {
		background-color: ${Colors?.primary_blue_k_medium_1} ;
		.EditIcon {
			color:${Colors?.white} ;
		}
	}
	&:active {
		background-color: ${Colors?.primary_blue_k_main} ;

		.EditIcon {
			color:${Colors?.white} ;
		}
	}
`;

export const EditSignatureContainer = styled.div`
	display: flex;
	width: 45%;
	align-items: center;
	margin-top: 14px;
	cursor: pointer;

	&:hover ${ContainerIconEditSignature} {
		background-color: ${Colors?.primary_blue_k_medium_1} ;
		.EditIcon {
			color:${Colors?.white} ;
		}
	}
	&:active ${ContainerIconEditSignature} {
		background-color: ${Colors?.primary_blue_k_main} ;

		.EditIcon {
			color:${Colors?.white} ;
		}
	}
`;