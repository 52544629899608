import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	flex-direction: column;
	height: 508px;
	width: 565px;
	border-radius: 30px;
	display: flex;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 1.738rem;
		position: absolute;
		right: 2rem;
		top: 2rem;
		cursor: pointer;
	}

`;

export const Close = styled.img`
	width: 1rem;
	position: absolute;
	right: 2.5rem;
	top: 2.5rem;
	cursor: pointer;
`;

export const ButtomSearch = styled.button`
	background-color: transparent;
	border: none;
	width: 20px;
	height: 20px;
`;

export const ButtomSearchPulse = styled.button`
	width: 20px;
	height: 20px;
	display: block;
	border-radius: 50%;
	background: transparent;
	cursor: pointer;
	box-shadow: 0 0 0 #000e4b;
	animation: pulse 2s infinite;
	border: none;

	@-webkit-keyframes pulse {
		0% {
			-webkit-box-shadow: 0 0 0 0 #000e4b;
		}
		70% {
			-webkit-box-shadow: 0 0 0 10px #000e4b;
		}
		100% {
			-webkit-box-shadow: 0 0 0 0 #000e4b;
		}
	}
	@keyframes pulse {
		0% {
			-moz-box-shadow: 0 0 0 0 #000e4b;
			box-shadow: 0 0 0 0 #000e4b;
		}
		70% {
			-moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 15px rgba(204, 169, 44, 0);
		}
		100% {
			-moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
			box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
		}
	}
`;

export const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top:3rem;
	height: 5rem;
`
export const SearchBar = styled.div`
	display: flex;
	width: 80%;
	border-bottom:1px solid ${Colors.primary_blue_k_main+20};
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;

	.input {
		width: 100%;
		border: none;
		padding-left: 10px;
		::placeholder{
			color: ${Colors.neutral_grey_k_20};
		}
	}
`;



export const Content = styled.div`
	display: flex;
	width: 100%;
	height: 70%;
	flex-direction: column;
	align-items: center;
	position: relative;
`;
export const ContentPatients = styled.div`
	width: 80%;
	height: 80%;
	display: flex;
	flex-direction: column;
	overflow-y: scroll;

	::-webkit-scrollbar {
			width: 10px;
			height: 10px;
		}
		::-webkit-scrollbar-thumb {
			background: #e0e0e0;
			border-radius: 10px;
		}
		::-webkit-scrollbar-track {
			background: #ffffff;
			border-radius: 10px;
		}

`;

export const PatientCard = styled.div`
	display: flex;
	width: 100%;
	height: 50px;
	flex-direction: row;
	align-items: center;
	margin-bottom: 1rem;
	padding-left: 0.5rem;
	padding-right: 1rem;
	padding-bottom: 0.5rem;
	justify-content: space-between;
	cursor: pointer;
	border-bottom: 1px solid ${Colors.neutral_grey_k_20};
`;
export const ProfilePicture = styled.img`
	object-fit: cover;
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
`;
export const NameAndTagContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-left: 10px;
`;

export const PaginationAndAddButton = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding-bottom: 1.5rem;
	padding-top: 0.5rem;
`;


