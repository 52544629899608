/* eslint-disable eqeqeq */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DinoTriste from "../../../assets/icons/dinoTriste.svg";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import AssimetriaSupIcon from "../../../assets/icons/assimetria_icon.svg";
import AssimetriaInfIcon from "../../../assets/icons/assimetria_inferior.svg";
import EvolucaoIcon from "../../../assets/icons/evolucao_icon.svg";
import ArrowClosed from "../../../assets/icons/arrow_forward.svg";
import ArrowOpen from "../../../assets/icons/arrow_forward_ios_open.svg";
import PdfIcon from "../../../assets/kinologyIcons/svg/pdf.svg";

import SuperiorFrontalMasculino from "../../../assets/assimetria/anatomia_frontal_superior_masculino.svg";
import InferiorFrontalMasculino from "../../../assets/assimetria/anatomia_frontal_inferior_masculino.svg";
import InferiorDorsalMasculino from "../../../assets/assimetria/anatomia_dorsal_inferior_masculino.svg";
import SuperiorDorsalMasculino from "../../../assets/assimetria/anatomia_dorsal_superior_masculino.svg";

import SuperiorFrontalFeminino from "../../../assets/assimetria/anatomia_frontal_superior_feminino.svg";
import InferiorFrontalFeminino from "../../../assets/assimetria/anatomia_frontal_inferior_feminino.svg";
import InferiorDorsalFeminino from "../../../assets/assimetria/anatomia_dorsal_inferior_feminino.svg";
import SuperiorDorsalFeminino from "../../../assets/assimetria/anatomia_dorsal_superior_feminino.svg";

import { TextsProps } from "../../../database/txt.interface";
import {
	AssimetriaPros,
	ExerCucaoProp,
} from "../../../interfaces/assimetria.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AppActions } from "../../../store/ducks/app";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import { getPermission } from "../../../utils/funcs";
import {
	TypeAgrupamentoLado,
	TypesCores,
	TypesErrorRequest,
	TypesLanguage,
	TypesPermission,
} from "../../../utils/types";
import GetMuscleIcon from "./GetMuscleIcon";
import { Content } from "./styles";
import { ConfigsActions } from "../../../store/ducks/configs";
import history from "../../../routes/history";
import { Icon, ProfilePicture, Return } from "../../../styles/global";
import {
	DescriptionDesk,
	P2Desk,
	P3Desk,
	S1Desk,
} from "../../../styles/styles_text";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import GetDotsNumber from "./GetToogleNumberIcon";
import EvolucaoAssimetria from "./EvolucaoAssimetria/EvolucaoAssimetria";
import DropDownButton from "../../../components/DropDownButton";
import MigrarIcon from "../../../assets/kinologyIcons/svg/migrar.svg";
import ModalConfirmMigrateOrDelete from "../../../components/Modais/ModalConfirMigrateOrDelete";
import { Colors } from "../../../styles/colors";

const Assimetria: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const dispatch = useDispatch();
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const assimetrias: AssimetriaPros[] = useSelector(
		(state: any) => state.dashboard.assimetria
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const language = useSelector((state: any) => state.configs.language);

	const assimetria: any = assimetrias.filter(
		(ass) => ass.ID_USUARIO == patient.ID_USUARIO
	);

	const [legendas, setLegendas] = useState<any>();
	const [permission, setPermission] = useState(true);

	const arr_options_superiores = [
		{
			id: 1,
			value: TypeAgrupamentoLado.SUPERIOR_FRONTAL,
			Agrupamento: current_language?.grupameto_superior,
		},
		{
			id: 2,
			value: TypeAgrupamentoLado.SUPERIOR_DORSAL,
			Agrupamento: current_language?.grupameto_superior,
		},
	];

	const arr_options_inferiores = [
		{
			id: 3,
			value: TypeAgrupamentoLado.INFERIOR_FRONTAL,
			Agrupamento: current_language?.grupameto_inferior,
		},
		{
			id: 4,
			value: TypeAgrupamentoLado.INFERIOR_DORSAL,
			Agrupamento: current_language?.grupameto_inferior,
		},
	];

	useEffect(() => {
		const filter_paci: AssimetriaPros[] = assimetrias.filter(
			(ass) => ass.ID_USUARIO === patient.ID_USUARIO
		);
		if (filter_paci) {
			setLegendas(filter_paci[0]?.LEGENDAS);
		}
	}, []);

	type ModoPermitido = "SUPERIOR_FRONTAL" | "INFERIOR_FRONTAL";
	function encontrarMaiorLabel(
		modo: ModoPermitido,
		current_label: number | undefined
	) {
		if (modo == "SUPERIOR_FRONTAL") {
			let maior_label_superiores: any =
				assimetrias[0]?.LISTAS.SUPERIOR_FRONTAL[0].LABEL_EXERCICIO;
			assimetrias[0].LISTAS.SUPERIOR_FRONTAL.forEach((item: ExerCucaoProp) => {
				if (item.LABEL_EXERCICIO > maior_label_superiores) {
					maior_label_superiores = item.LABEL_EXERCICIO;
				}
			});
			return maior_label_superiores + (current_label ? current_label : 0);
		}

		if (modo == "INFERIOR_FRONTAL") {
			let maior_label_inferiores: any =
				assimetrias[0]?.LISTAS.INFERIOR_FRONTAL[0]?.LABEL_EXERCICIO;
			assimetrias[0].LISTAS.INFERIOR_FRONTAL.forEach((item: ExerCucaoProp) => {
				if (item.LABEL_EXERCICIO > maior_label_inferiores) {
					maior_label_inferiores = item.LABEL_EXERCICIO;
				}
			});
			return maior_label_inferiores + (current_label ? current_label : 0);
		}
	}

	type Legenda = {
		LEGENDA_D: string;
		LEGENDA_E: string;
		LABEL_MUSCULO: number;
	};

	type Item = {
		[key: string]: Legenda;
	};

	function ConverteObjetoLegendas(item: Item) {
		if (item != null) {
			const arrayDeObjetos = Object.entries(item).map(([key, value]) => {
				const posicao_agrupamento_musculo = key.split("_");
				return {
					posicao: posicao_agrupamento_musculo[0],
					agrupamento: posicao_agrupamento_musculo[1],
					musculo: posicao_agrupamento_musculo[2] + (posicao_agrupamento_musculo[3] != undefined ?  "_" + posicao_agrupamento_musculo[3] : ""),
					...value,
				};
			});
			return arrayDeObjetos;
		}
	}

	function _renderSupeFrontal() {
		return (
			<div className="contentBody">
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? SuperiorFrontalMasculino
							: SuperiorFrontalFeminino
					}
					alt=""
					className="imgBody"
				/>
				{ConverteObjetoLegendas(legendas?.SUPERIOR_FRONTAL)?.map(
					(item: any, index) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe="musculo"
							/>
							<GetDotsNumber
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								classe={"dots_sup"}
								label_musculo={item.LABEL_MUSCULO}
							/>
						</>
					)
				)}
			</div>
		);
	}

	function _renderSuperiorDorsal() {
		return (
			<div className="contentBody">
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? SuperiorDorsalMasculino
							: SuperiorDorsalFeminino
					}
					alt=""
					className="imgBody"
				/>
				{ConverteObjetoLegendas(legendas?.SUPERIOR_DORSAL)?.map(
					(item: any, index) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe={"musculo"}
							/>
							<GetDotsNumber
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								classe={"dots_sup"}
								label_musculo={encontrarMaiorLabel(
									"SUPERIOR_FRONTAL",
									item.LABEL_MUSCULO
								)}
							/>
						</>
					)
				)}
			</div>
		);
	}

	function _renderInferiorFrontal() {
		return (
			<div className="contentBody" style={{ paddingTop: 20 }}>
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? InferiorFrontalMasculino
							: InferiorFrontalFeminino
					}
					alt=""
					className="imgBodyInf"
				/>

				{ConverteObjetoLegendas(legendas?.INFERIOR_FRONTAL)?.map(
					(item: any, index) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe={"musculoInf"}
							/>
							<GetDotsNumber
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								classe={"dots_inf"}
								label_musculo={item.LABEL_MUSCULO}
							/>
						</>
					)
				)}
			</div>
		);
	}

	function _renderInferiorDorsal() {
		return (
			<div className="contentBody">
				<img
					src={
						patient.USUARIO_SEXO == "M"
							? InferiorDorsalMasculino
							: InferiorDorsalFeminino
					}
					alt=""
					className="imgBodyInf"
				/>

				{ConverteObjetoLegendas(legendas?.INFERIOR_DORSAL)?.map(
					(item: any, index) => (
						<>
							<GetMuscleIcon
								key={index}
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								cor_direito={item.LEGENDA_D}
								cor_esquerdo={item.LEGENDA_E}
								classe={"musculoInf"}
							/>
							<GetDotsNumber
								agrupamento={item.agrupamento}
								musculo={item.musculo}
								posicao={item.posicao}
								sexo={patient.USUARIO_SEXO == "M" ? "masculino" : "feminino"}
								classe={"dots_inf"}
								label_musculo={encontrarMaiorLabel(
									"INFERIOR_FRONTAL",
									item.LABEL_MUSCULO
								)}
							/>
						</>
					)
				)}
			</div>
		);
	}

	const handleInformationAgrupamento = (
		assimetria: any,
		typeAgrupamento: string,
		index?: number
	) => {
		dispatch(AppActions.set_number_grafics(false));

		const filter_agrupamento = assimetria[0]?.LISTAS[typeAgrupamento]
			? assimetria[0]?.LISTAS[typeAgrupamento]
			: [];

		const array_history: any = [];

		if (filter_agrupamento.length > 0) {
			filter_agrupamento.forEach((el: any, index: any) => {
				const arr_d: any = [];
				const arr_e: any = [];
				el.EXECUCOES.slice(0, 3)?.forEach((e: any) => {
					const datD = {
						x: moment(e.DATA).format("DD/MM/YY"),
						y: e.MAXEXEC_D,
						position: "D",
						color: e.LEGENDA_ASSIMETRIA,
						maior: e.MAXEXEC_E > e.MAXEXEC_D ? e.MAXEXEC_E : e.MAXEXEC_D,
						valor_e: e.MAXEXEC_E,
					};
					const datE = {
						x: moment(e.DATA).format("DD/MM/YY"),
						y: e.MAXEXEC_E,
						position: "E",
						color: e.LEGENDA_ASSIMETRIA,
						maior: e.MAXEXEC_E > e.MAXEXEC_D ? e.MAXEXEC_E : e.MAXEXEC_D,
						valor_d: e.MAXEXEC_D,
					};
					arr_d.push(datD);
					arr_e.push(datE);
				});
				array_history.push({
					name_exer:
						language == TypesLanguage.PT
							? el?.EXERCICIO_TITULO_PT
							: language == TypesLanguage.EN
							? el?.EXERCICIO_TITULO_EN
							: el?.EXERCICIO_TITULO_ES,
					arr_d: arr_d,
					arr_e: arr_e,
					filter_agrupamento: filter_agrupamento[index]?.EXECUCOES,
				});
			});
			if (index) {
				!getPermission(
					config_servicos_array,
					TypesPermission.SERVICO_REL_ASSIMETRIA,
					dispatch
				)
					? dispatch(AppActions.set_modal_upgrade_funcionalidade(true))
					: dispatch(RelatorioActions.set_age(4));
			}
		} else if (index && filter_agrupamento.length == 0) {
			dispatch(
				AppActions.set_toast_visible({
					status_toast: true,
					type_toast: TypesErrorRequest.FAIL,
					message_toast: current_language?.sem_historico_assimetria_grupamento,
				})
			);
		}

		return filter_agrupamento;
	};

	const navigationUpgrade = () => {
		history.push("/upgrade");
	};

	useEffect(() => {
		const permissionAsymmetry: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_ASSIMETRIA
		);
		if (!permissionAsymmetry) {
			setPermission(false);
			dispatch(ConfigsActions.set_title_modal_upgrade(true));
		} else {
			dispatch(ConfigsActions.set_title_modal_upgrade(false));
		}
	}, []);

	const config_servicos_array: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	type EstadoPermitido = "superiores" | "inferiores" | "evolucao";
	const [resultado_selecionado, set_resultado_selecionado] =
		useState<EstadoPermitido>("superiores");

	const [open_legends, set_open_legends] = useState(false);
	const [modalConfirmVisible, setModalConfirmVisible] =  useState(false);

	return (
		<Content>
			<ModalConfirmMigrateOrDelete
				isVisible={modalConfirmVisible}
				setIsVisible={ (visible:boolean) => setModalConfirmVisible(visible)}
				typeModal="redirectToEvolution"
			/>
			{permission ? (
				<>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<ProfilePicture
						src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{patient.USUARIO_NOME}
					</S1Desk>
				</div>
				<Icon
					style={{ width: "2rem", height: "2rem" }}
					onClick={() => {
						dispatch(DashBoardActions.set_current_page("emissao_relatorios"))
						dispatch(
							RelatorioActions.set_current_selected_reports(["ASSIMETRIA"])
						);
					}}
					src={PdfIcon}
				/>
			</div>
					<ContainerOptions>
						<Option
							onClick={() => set_resultado_selecionado("superiores")}
							style={
								resultado_selecionado == "superiores"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<Icon style={{ marginRight: "1rem" }} src={AssimetriaSupIcon} />
							<div style={{ display: "flex", flexDirection: "column" }}>
								<text>Agrupamentos</text>
								<strong>Superiores</strong>
							</div>
						</Option>
						<Option
							onClick={() => set_resultado_selecionado("inferiores")}
							style={
								resultado_selecionado == "inferiores"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<Icon style={{ marginRight: "1rem" }} src={AssimetriaInfIcon} />
							<div style={{ display: "flex", flexDirection: "column" }}>
								<text>Agrupamentos</text>
								<strong>Inferiores</strong>
							</div>
						</Option>
						<Option
							onClick={() => set_resultado_selecionado("evolucao")}
							style={
								resultado_selecionado == "evolucao"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<Icon style={{ marginRight: "1rem" }} src={EvolucaoIcon} />
							<strong>Evolução</strong>
						</Option>
					</ContainerOptions>
					{(resultado_selecionado == "superiores" ||
						resultado_selecionado == "inferiores") && (
						<WhiteContainer>
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								zIndex: 999
							}}>
								{resultado_selecionado == "inferiores" ? (
									<P2Desk>{current_language?.assimetria_inferiores}</P2Desk>
								) : (
									<P2Desk>{current_language?.assimetria_superiores}</P2Desk>
								)}
								<div style={{ width: "2rem", height: "2rem", marginTop: '1rem'}}>
									<DropDownButton 
										text={current_language?.migrar_ou_excluir}
										icon={MigrarIcon}
										setIsVisible={ (visible:boolean) => setModalConfirmVisible(visible)}
									/>
								</div>	
							</div>

							<BodyContainer>
								{(resultado_selecionado == "inferiores"
									? arr_options_inferiores
									: arr_options_superiores
								)?.map((item, index) => (
									<>
										<div key={index} className="bodyAndTable">
											{item?.value === TypeAgrupamentoLado.SUPERIOR_DORSAL ? (
												<P3Desk className="title">Vista Dorsal</P3Desk>
											) : item?.value ===
											  TypeAgrupamentoLado.SUPERIOR_FRONTAL ? (
												<P3Desk className="title">Vista Frontal</P3Desk>
											) : item?.value ===
											  TypeAgrupamentoLado.INFERIOR_DORSAL ? (
												<P3Desk className="title">Vista Dorsal</P3Desk>
											) : (
												<P3Desk className="title">Vista Frontal</P3Desk>
											)}
											<div className="body">
												{item?.value === TypeAgrupamentoLado.SUPERIOR_DORSAL ? (
													_renderSuperiorDorsal()
												) : item?.value ===
												  TypeAgrupamentoLado.SUPERIOR_FRONTAL ? (
													_renderSupeFrontal()
												) : item?.value ===
												  TypeAgrupamentoLado.INFERIOR_DORSAL ? (
													_renderInferiorDorsal()
												) : item?.value ===
												  TypeAgrupamentoLado.INFERIOR_FRONTAL ? (
													_renderInferiorFrontal()
												) : (
													<></>
												)}
											</div>
										</div>
									</>
								))}
							</BodyContainer>

							<TableExercises>
								<thead>
									<tr>
										<Th>Exercícios</Th>
										<Th>Direito (D)</Th>
										<Th>Esquerdo (E)</Th>
										<Th>Assimetria atual</Th>
									</tr>
								</thead>
								{(resultado_selecionado == "inferiores"
									? arr_options_inferiores
									: arr_options_superiores
								)?.map((items, index) => (
									<tbody key={index}>
										{handleInformationAgrupamento(
											assimetria,
											items?.value,
											index
										)?.map((item: any, index: number) => (
											<tr className="tr" key={index}>
												<Td
													style={{
														display: "flex",
														alignItems: "center",
														flexDirection: "row",
														padding: "1.1rem",
													}}
												>
													<NumberLabel style={{ marginRight: "1rem" }}>
														{items?.value ===
														TypeAgrupamentoLado.INFERIOR_DORSAL
															? encontrarMaiorLabel(
																	"INFERIOR_FRONTAL",
																	item?.LABEL_EXERCICIO
															  )
															: items?.value ===
															  TypeAgrupamentoLado.SUPERIOR_DORSAL
															? encontrarMaiorLabel(
																	"SUPERIOR_FRONTAL",
																	item?.LABEL_EXERCICIO
															  )
															: item?.LABEL_EXERCICIO}

														{}
													</NumberLabel>
													<text>
														{language == TypesLanguage.PT
															? item?.EXERCICIO_TITULO_PT
															: language == TypesLanguage.EN
															? item?.EXERCICIO_TITULO_EN
															: item?.EXERCICIO_TITULO_ES}
													</text>
												</Td>
												<Td>{item.EXECUCOES[0]?.MAXEXEC_D} kg</Td>
												<Td>{item.EXECUCOES[0]?.MAXEXEC_E} kg</Td>
												<Td className="percentage">
													<div
														style={{
															padding: "0.3rem",
															backgroundColor:
																item?.EXECUCOES[0]?.LEGENDA_ASSIMETRIA ==
																TypesCores.POSITIVO
																	? "#80f279"
																	: item?.EXECUCOES[0]?.LEGENDA_ASSIMETRIA ==
																	  TypesCores.INTERMEDIARIO
																	? "#ffd57f"
																	: "#f2c3b8",
															width: item.EXECUCOES[0].ASSIMETRIA_VALOR,
														}}
													>
														{item.EXECUCOES[0].ASSIMETRIA_VALOR}
													</div>
												</Td>
											</tr>
										))}
									</tbody>
								))}
							</TableExercises>
							<ContainerLegenda>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
									}}
									onClick={() => set_open_legends(open_legends ? false : true)}
								>
									<Th>Legendas</Th>
									<Icon src={open_legends ? ArrowOpen : ArrowClosed} />
								</div>

								<div
									style={
										!open_legends ? { display: "none", marginTop: "1rem" } : {}
									}
								>
									<Th>Assimetria:</Th>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
											marginBottom: "1rem",
										}}
									>
										<Retangle style={{ backgroundColor: "#f7db92" }}></Retangle>
										<DescriptionDesk>Entre 10% e 20%</DescriptionDesk>
										<Retangle style={{ backgroundColor: "#edbdb3" }}></Retangle>
										<DescriptionDesk>Acima de 20%</DescriptionDesk>
										<Retangle style={{ backgroundColor: "#7fe07a" }}></Retangle>
										<DescriptionDesk>Abaixo de 10%</DescriptionDesk>
										<Retangle style={{ backgroundColor: "#758d87" }}></Retangle>
										<DescriptionDesk>Abaixo de 10%</DescriptionDesk>
									</div>
									<DescriptionDesk
										style={{
											color: "#797D8D",
											paddingLeft: "0.5rem",
										}}
									>
										A assimetria é aferida em relação ao grupamento muscular
										mais forte medido. Resultados contabilizados a partir da
										últimas execuções dos lados direito e esquerdo realizadas no
										mesmo dia. Para confiabilidade dos dados, execuções feitas
										em dias diferentes não serão contabilizadas. No caso de mais
										de uma execução no mesmo dia, será contabilizada a última.
										<br />
										<br />
									</DescriptionDesk>
								</div>
							</ContainerLegenda>
						</WhiteContainer>
					)}

					{resultado_selecionado == "evolucao" && (
						<WhiteContainer>
							<div style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center'
							}}>
								<P2Desk>{current_language?.evolucao_assimetria}</P2Desk>
								<div style={{ width: "2rem", height: "2rem", marginTop: '1rem'}}>
									<DropDownButton 
										text={current_language?.migrar_ou_excluir}
										icon={MigrarIcon}
										setIsVisible={ (visible:boolean) => setModalConfirmVisible(visible)}
									/>
								</div>	
							</div>
							<EvolucaoAssimetria />
						</WhiteContainer>
					)}
				</>
			) : (
				<div className="upgrade">
					<img src={DinoTriste} alt="" />
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel}</p>
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel2}</p>
					<button className="buttonUpgrade" onClick={navigationUpgrade}>
						{current_language?.menu_lateral_upgrade}
					</button>
				</div>
			)}
		</Content>
	);
};

const ContainerOptions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 60%;
`;

const Option = styled.text`
	color: var(--Azul-Azul-kinology, var(--Color, #000e4b));
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	height: 4rem;
	border-bottom: 4px solid var(--Neutras-Grey-K-10, #dedfe3);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	width: 100%;
`;

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const BodyContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	margin-top: 2rem;
`;

const TableExercises = styled.table`
	width: 100%;
	border-collapse: collapse;
`;

const Th = styled.th`
	border: 1.37px solid var(--Neutras-Grey-K-5, #f1f2f3);
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	text-align: left;
	padding: 0.7rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const Td = styled.td`
	border-bottom: 1.37px solid var(--Neutras-Grey-K-5, #f1f2f3);
	background: #fff;
	padding: 0.5rem;
	padding-left: 0.7rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const NumberLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #4d4d4d;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	font-size: 9px;
	color: #ffffff;
`;

const ContainerLegenda = styled.div`
	background: var(--Neutras-Grey-K-5, #f1f2f3);
	width: 100%;
	padding: 0.5rem;
	color: var(--Neutras-Grey-K-70, #3d4259);
	font-family: "Noto Sans";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
	border-radius: 0rem 0rem 0.875rem 0.875rem;
`;

const Retangle = styled.div`
	border-radius: 3px;
	width: 1rem;
	height: 1rem;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
`;

export default Assimetria;
