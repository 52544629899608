/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DinoTriste from "../../../assets/icons/dinoTriste.svg";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";

import { ConfigsActions } from "../../../store/ducks/configs";
import history from "../../../routes/history";
import {
	ContainerDash,
	HeaderProfile,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import { P2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import ExameFisicoAnamnese from "./ExameFisicoAnamnese";
import AvaliacaoAnamnese from "./AvaliacaoAnamnese";
import InformacoesPaciente from "../../Paciente/InformacoesPaciente/InformacoesPaciente";
import { AnamneseActions } from "../../../store/ducks/anamnese";
import moment from "moment";

const HistoricoAnamnese: React.FC = () => {
	interface TypeTopico {
		TITULO: string;
		VALOR: string;
	}

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const dispatch = useDispatch();
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	type typeHistorico = "avaliacao" | "exame_fisico";
	const type_historico: typeHistorico = useSelector(
		(state: any) => state.anamnese.type_historico
	);

	const relatorio_anamneses = useSelector(
		(state: any) => state.anamnese.relatorio_anamnese
	);

	const relatorio_exame_fisico = useSelector(
		(state: any) => state.anamnese.relatorio_exame_fisico_clinico
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const [permission, setPermission] = useState(true);

	const navigationUpgrade = () => {
		history.push("/upgrade");
	};

	useEffect(() => {
		const permissionAsymmetry: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_ASSIMETRIA
		);
		if (!permissionAsymmetry) {
			setPermission(false);
			dispatch(ConfigsActions.set_title_modal_upgrade(true));
		} else {
			dispatch(ConfigsActions.set_title_modal_upgrade(false));
		}
	}, []);

	const handleDate = () => {
		if(type_historico == "avaliacao") {
			return moment(relatorio_anamneses.relatorio_anamnese.RELATORIO_ATUALIZADO).format("DD/MM/YYYY").toString() 
		} else {
			return moment(relatorio_anamneses.relatorio_exame_fisico_clinico.RELATORIO_ATUALIZADO).format("DD/MM/YYYY").toString() 
		}
	}

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("relatorio_anamnese"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk>Anamnese Completa</P3Desk>
			</Return>
			<HeaderProfile style={{ marginBottom: "1rem" }}>
				<ProfilePicture
					src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
				/>
				<S1Desk style={{ marginLeft: "1rem" }}>{patient.USUARIO_NOME}</S1Desk>
			</HeaderProfile>

			<div style={{ width: "60%", marginBottom: "1rem" }}>
				<UltimaAlteracao>{`Última alteração: ${handleDate()}` }</UltimaAlteracao>
			</div>

			<WhiteContainer>
				<Title>
					{type_historico == "avaliacao" ? "Avaliação" : "Exame Físico/Clínico"}
				</Title>
				{(type_historico == "avaliacao"
					? relatorio_anamneses.relatorio_anamnese.CONTEUDO
					: relatorio_anamneses.relatorio_exame_fisico_clinico.CONTEUDO
				).map((item: TypeTopico, index: number) => (
					<div key={index} style={{ display: "flex", flexDirection: "column" }}>
						<Title>{item.TITULO}</Title>
						<Description>{item.VALOR}</Description>
					</div>
				))}
			</WhiteContainer>
		</ContainerDash>
	);
};

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const UltimaAlteracao = styled.text`
	color: var(--Neutras-Grey-K-50, #797d8d);
	text-align: center;
	/* Description-Desk */
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;
`;

const Title = styled.text`
	color: var(--Primrias-Blue-K-Principal, var(--Color, #000e4b));
	/* P2-App */
	font-family: "NotoSans-Regular";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 500;
	line-height: 150%; /* 1.6875rem */
	margin-top: 1rem;
`;

const Description = styled.text`
	color: var(--Neutras-Grey-K-80, #2a3048);
	font-family: "NotoSans-Regular";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.3125rem */
	margin-top: 1rem;
`;

export default HistoricoAnamnese;

