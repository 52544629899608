import React, { 
    ReactElement, 
    forwardRef, 
    useEffect, 
    useRef, 
    useState 
} from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { 
    useForm,
    useFormContext 
} from "react-hook-form";
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import styled from '@emotion/styled';

import { ContainerIcon, ContainerSelectInputForm, ContentSelectInput, HandleIcon } from './style';
import { InputSelectFormProps } from './Models';
import {formatDominant, setMaskCPF, setMaskPhone } from '../../utils/funcs';

import { TextsProps } from '../../database/txt.interface';

import Text from '../../components/Texts/Text';


import { Colors } from '../../styles/colors';

import { AuthActions } from '../../store/ducks/auth';

import EmailIcon from '../../assets/kinologyIcons/svg/Email_icon.svg';
import KeyPassword from '../../assets/kinologyIcons/svg/key_password.svg';
import CloseEye from '../../assets/kinologyIcons/svg/security_eye_icon.svg';
import DigitalIcon from '../../assets/kinologyIcons/svg/Digital_icon.svg';
import PositionIcon from '../../assets/kinologyIcons/svg/Position_icon.svg';
import Phone from '../../assets/kinologyIcons/svg/Phone.svg';
import ArrowSelect from '../../assets/kinologyIcons/svg/ArrowSelect.svg';
import { countries } from './Countries';
import { PacientesProps, UserProps } from '../../interfaces/pacientes.interface';

enum MaskProps {
    Phone = 'cel-phone',
    CreditCard = 'credit-card',
    CPF  = 'cpf',
    CNPJ = 'cnpj',
    Money =  'money',
    E_mail = 'e-mail'
};

interface InputRef {
	focus(): void;
};
interface InputValueReference {
	value: string;
}


interface inputProps extends Partial<HTMLInputElement>{
 leftIcon?: 'EmailIcon'
 | 'KeyPassword' 
 | 'DigitalIcon'
 | 'CloseEye'
 | 'PositionIcon'
 | 'Phone'
 | 'ArrowSelect';
 rightIcon?: 'EmailIcon'
 | 'KeyPassword' 
 | 'DigitalIcon'
 | 'CloseEye'
 | 'PositionIcon'
 | 'ArrowSelect'
};

enum InputNameType {
    LOGIN_PASSWORD = 'login_password',
    PASSWORD = 'password',
    NEW_PASSWORD = 'new_password',
    CONFIRM_PASSWORD = 'confirm_password',
    USUARIO_SENHA = 'USUARIO_SENHA',
    USUARIO_SENHA_CONFIRM = 'USUARIO_SENHA_CONFIRM'

};

type InputRules = {
    [key: string]: {
        required?: string;
        pattern?: {
            value: RegExp;
            message: string;
        };
        minLength?: {
            value: number;
            message: string;
        };
        validate?: (value: any) => boolean | string | undefined;
        onChange?: (nativeEvent: { target: { value: string } }) => void;

    };
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 18;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
      maxWidht: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
      minWidht: ITEM_HEIGHT * 2.5 + ITEM_PADDING_TOP,
    },
  },
};

const CustomFormControl = styled(FormControl)`
  width: 100%;
  border: none;
  .MuiOutlinedInput-root {
    border: none; 
  }
  .MuiSelect-select {
    height: 100px;
    display: flex;
    align-items: center;
  }
`;

const InputSelectForm: React.ForwardRefRenderFunction<InputRef, inputProps & InputSelectFormProps  > = ({
    type,
    typeInputName,
    testId,
    title,
    width,
    height,
    marginTop,
    margintBottom,
    marginLeft,
    marginRight,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    bgColor,
    borderRadius,
    borderWidth,
    borderBottomWidth,
    borderBottomColor,
    borderColor,
    justifyContent,
    alignItems,
    flexDirection,
    textColor = Colors?.primary_blue_k_main,
    fontSize,
    textAlign = 'left',
    textDecoration,
    fontFamily , /*= fonts?.NotoSans_Medium,*/
    fontWeight,
    leftIcon,
    rightIcon,
    placeholder,
    mask,
    arrayOptions,
    initialValue,
    disabled = false,
    dataChildToParent,
    security = !!(type == 'password') ? true : false,
    ...props


},ref,)=> {
   
    const dispatch = useDispatch();
    const Context = useFormContext();

    const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
    const current_language: TextsProps = useSelector((state: any) => state.configs.current_language);
  
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string>('');

    const iconType = {
        EmailIcon: EmailIcon ,
        KeyPassword: KeyPassword,
        CloseEye: CloseEye,
        DigitalIcon: DigitalIcon,
        PositionIcon: PositionIcon,
        Phone: Phone,
        ArrowSelect: ArrowSelect,
    };
  
    const commonRules = {
        onChange: (nativeEvent:{target:{value:string}}) => setValue(
            nativeEvent?.target?.value
        )
    };
    const rules: InputRules = {
        nacionalidade: {
            required: `${current_language?.login_input_senha}`,
            ...commonRules,
        },
        EditConditioning: {
            ...commonRules,
        },
        EditSexo: {
            ...commonRules,
        }, 
        EditLadoDominante: {
            ...commonRules,
        },
    };

    const handleIconAction = ()=> {
        switch(rightIcon){
            case 'ArrowSelect':
                setOpen(open ? false : true)
                break
            default: break;
        }
    };

    useEffect(()=>{
        if(typeInputName === 'EditConditioning'){
            setValue(arrayOptions[paciente?.USUARIO_CONDICIONAMENTO])
        }
        if( typeInputName === 'EditSexo'){
            const genderMap: { [key: string]: string } = {
                'M': arrayOptions[0],
                'F': arrayOptions[1]
            };
            setValue(genderMap[paciente?.USUARIO_SEXO])
        } 
        if( typeInputName === 'EditLadoDominante') {
            const sideMap: { [key: string]: string } = {
                'E': arrayOptions[0],
                'D': arrayOptions[1]
            };
            setValue(sideMap[paciente?.USUARIO_LADO_DOMINANTE])
        }
    },[typeInputName])
    
    useEffect(()=>{
        dataChildToParent?.(value)
    },[value])

    const handleOptions = ()=> {
        return (
            arrayOptions?.map((item: string, index: number)=> 
                <MenuItem 
                    key={index} 
                    value={item} 
                    style={{
                        height: 20,
                        borderRadius: 20,
                        marginTop: 8
                    }}
                >
                    {item}
                </MenuItem>
            )
        )
    }
      
    return (
        <ContainerSelectInputForm
            typeInputName={typeInputName}
            width={width}
            height={height}
            bgColor={bgColor}
            marginTop={marginTop}
            marginLeft={marginLeft}
            marginRight={marginRight}
            paddingTop={paddingTop}
            paddingBottom={paddingBottom}
            paddingLeft={paddingLeft}
            paddingRight={paddingRight}
            borderRadius={borderRadius}
            borderWidth={borderWidth}
            borderBottomWidth={borderBottomWidth}
            borderBottomColor={borderBottomColor}
            borderColor={borderColor}
            justifyContent={justifyContent}
            alignItems={alignItems}
            flexDirection={flexDirection}
            textColor={textColor}
            fontSize={fontSize}
            textAlign={textAlign}
            textDecoration={textDecoration}
        >
            <Text 
                title={title}
                textColor={Context?.formState?.errors?.[typeInputName]  
                    ? Colors?.tertirary_red_1_dark 
                    : textColor  
                }
                textAlign={textAlign}
                fontSize={fontSize}
                fontFamily={fontFamily}
                fontWeight={fontWeight}
            />
            <ContentSelectInput style={{ cursor: 'pointer' }} onClick={()=>handleIconAction()} borderBottomColor={ disabled? 'transparent' : Context?.formState?.errors?.[typeInputName] && Colors?.tertirary_red_1_dark }>
                { leftIcon &&
                    <ContainerIcon onClick={()=>{}} >
                        <HandleIcon src={iconType[leftIcon!]}  />
                    </ContainerIcon>
                }
                    { !open && Context.watch('nacionalidade') == ''
                        ? 
                        <Text 
                            title={placeholder}
                            textColor={Colors.primary_blue_k_main+50}
                            fontSize={1}
                            width='100%'
                            textAlign='left'
                            marginLeft={15}
                            fontFamily='NotoSans-Regular'
                        /> 
                        : 
                        <CustomFormControl variant="outlined" defaultValue={'Brasil'} onClick={()=>handleIconAction()}>
                            <Select
                                MenuProps={MenuProps}
                                open={open}
                                placeholder='Selecione'
                                value={value}
                                autoCapitalize={props?.autocapitalize}
                                autoComplete='off'
                                IconComponent={()=> <></> }
                                onClose={()=> handleIconAction()}
                                sx={{
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none',
                                        width: '100%',
                                    },
                                    '.MuiSelect-select': {
                                        cursor: disabled ? 'default' : 'pointer', 
                                    },
                                }}
                                style={{
                                    display: 'flex',
                                    color: textColor,
                                    paddingLeft: leftIcon ? 5 : 0,
                                    fontSize: '1rem',
                                    borderWidth:0,
                                    borderColor: 'transparent',
                                    width:'97%',
                                    cursor: 'pointer'
                                }}
                                {...Context?.register(typeInputName, rules[typeInputName])}
                             >
                                { handleOptions() }
                            </Select>
                         </CustomFormControl>

                    }
                    
                { rightIcon && !disabled &&
                    <ContainerIcon onClick={()=>handleIconAction()} >
                       <HandleIcon 
                            src={iconType[rightIcon!] } 
                            style={{cursor: 'pointer'}}  
                            onClick={()=>handleIconAction()}
                        /> 
                    </ContainerIcon>
                }
            </ContentSelectInput>
            {Context?.formState?.errors?.[typeInputName] &&
                <Text
                    title={Context?.formState?.errors[typeInputName]?.message}
                    textColor={ Colors?.tertirary_red_1_dark}
                    textAlign={'left'}
                    fontSize={0.87}
                    fontFamily={fontFamily} 
                    marginTop={8}
                />
            }
        </ContainerSelectInputForm>
    )
};

export default forwardRef(InputSelectForm);