import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { UserProps } from "../../interfaces/pacientes.interface";
import { TypesErrorRequest } from "../../utils/types";
import { AppActions } from "../ducks/app";
import { DashBoardActions } from "../ducks/dashboard";
import { ExerciciosActions, Types } from "../ducks/exer";
import * as Sentry from "@sentry/react";
import { RelatorioActions } from "../ducks/relatorios";

function* recuperarExercicio({ payload }: any): any {
	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	async function apiCall() {
		try {
			const dat: any = {
				TOKEN: user_logado.token,
				...payload,
			};

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}exercicios/exercicios_recuperar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.SUCCESS) {
			let message = "";
			if (data.code == 0) {
				message = current_language?.recuperacao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
			}
			yield put(DashBoardActions.exercices_request())
		} else if (data.status == TypesErrorRequest.FAIL) {
			let message = "";
			if (data.code == -1) {
				message = current_language?.recuperacao_falhou;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		yield put(ExerciciosActions.recuperar_exercicio_request_cancel());
		Sentry.captureMessage("CAIU NO CATCH: exercicios_recuperar.php", "error");
	} finally {
		yield put(ExerciciosActions.recuperar_exercicio_request_cancel());
	}
}

function* editarExercicio({ payload }: any): any {

	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	async function apiCall() {
		try {
			const dat: any = {
				TOKEN: user_logado.token,
				...payload,
			};

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}exercicios/exercicios_editar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.SUCCESS) {
			let message = "";
			if (data.code == 0) {
				message = current_language?.edicao_completa;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(DashBoardActions.exercices_request())
			}
		} else if (data.status == TypesErrorRequest.FAIL) {
			let message = "";
			if (data.code == -1) {
				message = current_language?.edicao_falhou;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
			if (data.code == 1) {
				message = current_language?.titulo_existe;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		yield put(ExerciciosActions.editar_exercicio_request_cancel());
		Sentry.captureMessage("CAIU NO CATCH: exercicios_editar.php", "error");
	} finally {
		yield put(ExerciciosActions.editar_exercicio_request_cancel());
	}
}

function* recuperarExecucao({ payload }: any): any {

	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);
	async function apiCall() {
		try {
			const dat: any = {
				TOKEN: user_logado.token,
				...payload,
			};

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}execucoes/execucoes_recuperar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.SUCCESS) {
			let message = "";
			if (data.code == 0) {
				message = current_language?.recuperacao_concluida;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(DashBoardActions.execucoes_request(true));
			}
		} else if (data.status == TypesErrorRequest.FAIL) {
			let message = "";
			if (data.code == -1) {
				message = current_language?.recuperacao_falhou;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		yield put(ExerciciosActions.recuperar_execucao_request_cancel());
	} finally {
		yield put(ExerciciosActions.recuperar_execucao_request_cancel());
	}
}


function* editarExecucao({ payload }: any): any {

	const user_logado: UserProps = yield select((state: any) => state.auth.user);
	const current_language: any = yield select(
		(state) => state.configs.current_language
	);

	const data_selected_delete_or_migrate = yield select((state:any)=> state.relatorios.data_selected_for_delete_or_migrate);
	const list_options_delete_or_migrate = yield select((state:any)=> state.relatorios.data_for_delete_or_migrate)


	const filteredExercises = list_options_delete_or_migrate.filter((exercise:any )=>
		!data_selected_delete_or_migrate.some((selected: any) => selected?.i?.ID_EXECUCAO === exercise?.i?.ID_EXECUCAO)
	);

	async function apiCall() {
		try {
			const dat: any = {
				TOKEN: user_logado.token,
				...payload,
			};

			const requestOptions: any = {
				method: "POST",
				body: JSON.stringify(dat),
			};
			const res = await fetch(
				`${process.env.REACT_APP_BASE_URL}execucoes/execucoes_editar.php`,
				requestOptions
			);
			const response: any = await res.json();
			return response;
		} catch (e) {
			throw e;
		}
	}

	try {
		const data = yield call(apiCall);
		if (data.status == TypesErrorRequest.SUCCESS) {
			let message = "";
			if (data.code == 0) {
				message = current_language?.migracao_com_sucesso;
				yield put(RelatorioActions.set_options_for_deleted_or_migrate(filteredExercises))
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.SUCCESS,
						message_toast: message,
					})
				);
				yield put(DashBoardActions.execucoes_request(false));
			}
		} else if (data.status == TypesErrorRequest.FAIL) {
			let message = "";
			if (data.code == -1) {
				message = current_language?.migracao_com_falha;
				yield put(
					AppActions.set_toast_visible({
						status_toast: true,
						type_toast: TypesErrorRequest.FAIL,
						message_toast: message,
					})
				);
			}
		}
	} catch (e) {
		yield put(ExerciciosActions.editar_execucao_request_cancel());
		Sentry.captureMessage("CAIU NO CATCH: execucoes_editar.php", "error");
	} finally {
		yield put(ExerciciosActions.editar_execucao_request_cancel());
	}
}




export function* ExercicioSaga(): any {
	return yield all([
		takeLatest(Types.RECUPERAR_EXERCICIO_REQUEST, recuperarExercicio),
		takeLatest(Types.EDITAR_EXERCICES, editarExercicio),
		takeLatest(Types.RECUPERAR_EXECUCAO_REQUEST, recuperarExecucao),
		takeLatest(Types.EDITAR_EXECUCAO_REQUEST, editarExecucao),
	]);
}
