import React, { useEffect } from "react";
import styled from "styled-components";
import Perfil from "../../../assets/icons/perfil.svg";
import CheckboxIcon from "../../../assets/icons/checkbox.svg";
import { getPermission } from "../../../utils/funcs";
import { useDispatch, useSelector } from "react-redux";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { getEtiquetas } from "../../../utils/etiquetas";
import { PacAvalActions } from "../../../store/ducks/manage_pac_aval";
import { AppActions } from "../../../store/ducks/app";
import moment from "moment";
import { Checkbox } from "../../../styles/global";
import history from "../../../routes/history";
import { AddPacAvalActions } from "../../../store/ducks/add_pac_aval";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ServicosProps } from "../../../interfaces/config.interface";
import { TypesPermission } from "../../../utils/types";
import { ConfigsActions } from "../../../store/ducks/configs";
import { TextsProps } from "../../../database/txt.interface";
import { AtribuirActions } from "../../../store/ducks/atribuir";
import { AnamneseActions } from "../../../store/ducks/anamnese";
import { P2Desk } from "../../../styles/styles_text";
import Loading from "../Loading";

const ListagemPacientes: React.FC = () => {
	const dispatch = useDispatch();
	const search = useSelector((state: any) => state.dashboard.search);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const etis = useSelector((state: any) => state.dashboard.etiquetas);
	const lista_filtro = useSelector((state: any) => state.app.lista_filtro);

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const button_search = useSelector(
		(state: any) => state.dashboard.button_search
	);

	const option = useSelector(
		(state: any) => state.atribuir.menu_option_selected
	);

	const config_servicos = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const pacientes_selecionados = useSelector(
		(state: any) => state.app.lista_pacientes
	);

	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	const HandleClassUsuario = (val: PacientesProps, event: any) => {
		event.stopPropagation();
		let aux_lista_pacientes = pacientes_selecionados;

		const exists = pacientes_selecionados.includes(val.ID_USUARIO);

		if (exists) {
			aux_lista_pacientes = aux_lista_pacientes.filter(
				(id: number) => id != val.ID_USUARIO
			);
		} else {
			aux_lista_pacientes = [...aux_lista_pacientes, val.ID_USUARIO];
		}

		dispatch(AppActions.set_lista_pacientes(aux_lista_pacientes));
	};

	const selecionarTodos = () => {
		const aux_pacientes_selecionados: any = [];
		pacientes?.map((val: PacientesProps) => {
			aux_pacientes_selecionados.push(val.ID_USUARIO);
		});
		dispatch(AppActions.set_lista_pacientes(aux_pacientes_selecionados));
	};

	const PesquisarPaciente = () => {
		const filtros_paginacao = {
			inicio: 0,
			fim: 6,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
			],
		};

		if (search != "") {
			filtros_paginacao.filtros.push({ tipo: "USUARIO_NOME", valor: search });
		}

		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));
		dispatch(DashBoardActions.paciente_request());
	};

	const handlePaciente = (paciente: any) => {
		const obj = {
			name: paciente?.USUARIO_NOME,
			value: "paciente",
		};
		const payload = {
			ID_USUARIO: paciente?.ID_USUARIO,
			txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
		};
		dispatch(AppActions.set_open_paciente(paciente));
		dispatch(PacAvalActions.set_perfil_anamnese_patient(paciente?.ID_USUARIO));
		dispatch(AnamneseActions.set_relatorio_qualidade_vida_history(null));
		dispatch(AppActions.set_open_barraSuperior(obj));
		dispatch(AppActions.set_confirm_close_modal_edit(true));
		dispatch(AddPacAvalActions.set_recorrente(payload));
		AmplitudeLog("paci_mais_visitados", {
			body: {
				paciente: payload,
			},
		});
		const permissionAssessmentPhysical: any = getPermission(
			config_servicos as ServicosProps[],
			TypesPermission.SERVICO_ANAMNESE_FISICA
		);
		if (!permissionAssessmentPhysical) {
			dispatch(ConfigsActions.set_close_modal_upgrade_and_goback(true));
			dispatch(
				ConfigsActions.set_physical_anamnesis_verification_by_the_most_visited_patients(
					permissionAssessmentPhysical
				)
			);
		}
	};

	const OpenPaci = (paciente: PacientesProps) => {
		handlePaciente(paciente);
		dispatch(AtribuirActions.set_menu_option_selected(""));
		dispatch(AppActions.set_lista_pacientes([]));
		dispatch(DashBoardActions.set_current_page("perfil_paciente"));
	};

	useEffect(() => {
		if (button_search) {
			PesquisarPaciente();
			// dispatch(DashBoardActions.set_button_search(false));
		}
	}, [button_search]);

	useEffect(() => {
		PesquisarPaciente()
	}, [])

	return (
		<Container>
			{option != "" && (
				<DivSelectAll>
					{pacientes_selecionados.length > 0 ? (
						<SelectAllButton
							onClick={() => dispatch(AppActions.set_lista_pacientes([]))}
						>
							{current_language?.cancelar_selecao}
						</SelectAllButton>
					) : (
						<SelectAllButton onClick={selecionarTodos}>
							{current_language?.selecionar_todos} ({pacientes.length}){" "}
						</SelectAllButton>
					)}
				</DivSelectAll>
			)}
			<PatientListContainer>
				{button_search || pacientes_loading ? (
					<Loading />
				) : (
					pacientes.map((paciente: PacientesProps, index: number) => (
						<PatientSection
							onClick={() => OpenPaci(paciente)}
							color={
								pacientes_selecionados.some(
									(id: number) => id === paciente.ID_USUARIO
								)
									? "#DEDFE3"
									: "transparent"
							}
							key={index}
						>
							<ProfilePicture
								src={paciente?.USUARIO_FOTO ? paciente?.USUARIO_FOTO : Perfil}
							/>
							<NameAndTagContainer>
								<Name>{paciente.USUARIO_NOME}</Name>
								<DivTags>
									{getEtiquetas(paciente, etis, lista_filtro).map((e: any) => (
										<Tag key={e.ID_ETIQUETA}>{e.ETIQUETA_NOME}</Tag>
									))}
								</DivTags>
							</NameAndTagContainer>

							{option != "" && (
								<Checkbox
									className="custom-checkbox"
									id={paciente.ID_USUARIO.toString()}
									type="checkbox"
									src={CheckboxIcon}
									onClick={(event) => HandleClassUsuario(paciente, event)}
									checked={pacientes_selecionados.some(
										(id: number) => id === paciente.ID_USUARIO
									)}
								/>
							)}
						</PatientSection>
					))
				)}
			</PatientListContainer>
		</Container>
	);
};

const Container = styled.div`
	width: 60%;
	margin-top: 2vh;
	height: 100%;
`;

const DivSelectAll = styled.div`
	width: 100%;
	height: 7%;
	display: flex;
	justify-content: flex-end;
`;

const SelectAllButton = styled.button`
	font-family: "NotoSans-regular";
	font-size: 75%;
	font-weight: 600;
	line-height: 24.52px;
	text-align: right;
	background-color: transparent;
	border: none;
	color: #00a377;
`;

const PatientListContainer = styled.div`
	width: 100%;
	height: 50vh;
	margin-bottom: 1.5rem;
	overflow-y: scroll;
	::-webkit-scrollbar {
		width: 10px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const PatientSection = styled.div`
	width: 100%;
	height: 4rem;
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 12px;
	margin-bottom: 1rem;
	padding-left: 0.5rem;
	padding-right: 1rem;
	justify-content: space-between;
	background-color: ${(props) => props.color || "transparent"};
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	object-fit: cover;
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
`;

const NameAndTagContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-left: 10px;
`;

const Name = styled.text`
	font-size: 1rem;
	font-family: "NotoSans-Regular";
	font-weight: 500;
	line-height: 25px;
	color: #323d6e;
`;

const DivTags = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
`;

const Tag = styled.div`
	background-color: #d0d9ff;
	border-radius: 30px;
	margin-right: 5px;
	height: 1.2rem;
	font-size: 0.8rem;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 7px;
	display: flex;
	align-items: center;
	color: #323d6e;
`;

export default ListagemPacientes;

