import {
	createStyles,
	Fade,
	makeStyles,
	Modal,
	Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";


import { TextsProps } from "../../../database/txt.interface";
import { Container, Close, Header, SearchBar, Content, PatientCard, ProfilePicture, NameAndTagContainer, PaginationAndAddButton, ContentPatients } from "./styles";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import buttonClose from "../../../assets/icons/buttonCloseBlack.svg";
import { Colors } from "../../../styles/colors";
import ModalConfirmMigrateOrDelete from "../ModalConfirMigrateOrDelete";
import Text from "../../Texts/Text";
import  {ReactComponent as SearchIcon } from ".././../../assets/kinologyIcons/svg/search.svg";
import Perfil from "../../../assets/icons/perfil.svg";

import Pagination from "@mui/material/Pagination";
import { MudarPagina } from "../../../utils/funcs";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { ExecucaoProps } from "../../../interfaces/execucao.interface";
import { InputSearch } from "../../../styles/styles_input_pesquisas";
import Loading from "../../../pages/DashPaciente/Loading";

interface ModalProps {
	isVisible: boolean
	setIsVisible: (status:boolean)=>void
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "0",
			boxShadow: theme.shadows[5],
		},
		root: {
			"& .MuiPagination-ul": {
				justifyContent: "left",
			},
			"& .MuiPaginationItem-root": {
				color: "black",
				"&:hover": {
					backgroundColor: "#000E4B",
					color: "white",
				},
			},
			"& .MuiPagination-outlined": {
				backgroundColor: "#000E4B",
			},
			"& .Mui-selected": {
				backgroundColor: "#000E4B",
				color: "#000E4B",
				"&:hover": {
					color: "black",
				},
			},
		},
	})
);

const ModalSelectPatientForMigrateExecutions: React.FC<ModalProps> = ({
	isVisible = false,
	setIsVisible,
}) => {
	const dispatch = useDispatch();
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const classes = useStyles();
	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);
	const language = useSelector((state: any) => state.configs.language);

	const [executionsSelected, setExecutionsSelected] = useState<any[]>([]);
	const [modalConfirmVisible, setModalConfirmVisible] =  useState(false);
	const [search , setSearch] =  useState('');
	const [userId , setUserId] =  useState<number>();
	const [user , setUser] =  useState<any>();
	const [inputValue, setInputValue] = useState("");
	console.log('sertch', search)

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const total_pacientes = useSelector(
		(state: any) => state.dashboard.total_pacientes
	);
	const data_selected_delete_or_migrate = useSelector((state:any)=> state.relatorios.data_selected_for_delete_or_migrate)

	const numero_de_paginas = Math.ceil(total_pacientes / 6);

	const pagina_atual = useSelector(
		(state: any) => state.dashboard.current_page_patient
	);
	const load_deleted_execucao: TextsProps = useSelector(
		(state: any) => state.avaliation.execucao_loading
	);
	const execucoes: ExecucaoProps[] = useSelector(
		(state: any) => state.dashboard.execucoes
	);
	const pacientes_loading = useSelector(
		(state: any) => state.dashboard.pacientes_loading
	);

	useEffect(()=>{
		if(!load_deleted_execucao )[
			setIsVisible(false)
		]
	},[load_deleted_execucao, execucoes])

	const handleInputChange = (event: any) => {
		setInputValue(event.target.value);
	};

	const handleOpenSearch = (e: any) => {
		const filtros_paginacao = {
			inicio: 0,
			fim: 6,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
			],
		};

		if (e.target.value != "") {
			filtros_paginacao.filtros.push({ tipo: "USUARIO_NOME", valor: e.target.value });
		}

		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));
		dispatch(DashBoardActions.paciente_request());
	};

	const resetSearch = () => {
		const filtros_paginacao = {
			inicio: 0,
			fim: 6,
			filtros: [
				{ tipo: "USUARIO_EXCLUIDO", valor: "N" },
				{ tipo: "USUARIO_NIVEL", valor: [3] },
			],
		};

		dispatch(DashBoardActions.set_current_page_patient(1));
		dispatch(DashBoardActions.set_filtros_pacintes_request(filtros_paginacao));
		dispatch(DashBoardActions.paciente_request());
	}

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				className={classes.modal}
				BackdropProps={{style: { backgroundColor: 'transparent' }}}
				open={isVisible}
			>
				<Fade in={isVisible}>
					<Container>
						<Close
							className="close"
							src={buttonClose}
							onClick={() => { 
								setIsVisible?.(false);
								setInputValue("");
								resetSearch();
							}}
							alt="Close"
							style={{width: '1.2rem', height: '1.2rem'}}
						/>
						<Header>
								<Text 
									title={current_language?.escolha_paciente_migrar_execucao}
									fontFamily="NotoSans-Regular"
									fontSize={1.15}
									fontWeight={600}
									textColor={Colors?.primary_blue_k_main}
								/>
								<SearchBar>
									<SearchIcon />
									<InputSearch
										onKeyUp={(e) => {
											if (e.key === "Enter") handleOpenSearch(e);
										}}
										value={inputValue}
										onChange={handleInputChange}
										type="text"
										placeholder={current_language?.dash_pesquisar_paciente}
									/>
								</SearchBar>
						</Header>

						<Content>
							<ContentPatients>
								{ pacientes_loading && (
									<Loading />
								) || (
										pacientes.filter((item:PacientesProps)=>item.USUARIO_NOME.toLowerCase().includes(search)).map((paciente: PacientesProps, index: number) => (
											<PatientCard
												onClick={() => {setUserId(paciente.ID_USUARIO), setModalConfirmVisible(true), setUser(paciente)}}
												color={ "transparent"	}
												key={index}
											>
												<ProfilePicture
													src={paciente?.USUARIO_FOTO ? paciente?.USUARIO_FOTO : Perfil}
												/>
												<NameAndTagContainer>
													<Text 
														title={paciente.USUARIO_NOME}
														fontFamily="NotoSans-Regular"
														fontSize={0.75}
														fontWeight={600}
														textAlign="left"
														textColor={Colors?.primary_blue_k_main}
														cursor="pointer"
													/>
												
												</NameAndTagContainer>
											</PatientCard>
										))
									)
								}
							</ContentPatients>
							<PaginationAndAddButton >
							<Pagination
								onChange={(event, pagina_desejada) => {
									MudarPagina(
										pagina_desejada,
										numero_de_paginas,
										dispatch,
										[3],
										search
									);
									dispatch(DashBoardActions.set_number_page(pagina_desejada));
								}}
								page={pagina_atual}
								className={classes.root}
								size="large"
								count={numero_de_paginas}
							/>
							</PaginationAndAddButton>
						</Content>
					</Container>
				</Fade>
			</Modal>
			<ModalConfirmMigrateOrDelete
				isVisible={modalConfirmVisible}
				setIsVisible={(visible:boolean)=>setModalConfirmVisible(visible)}
				itemList={data_selected_delete_or_migrate}
				userId={userId}
				typeModal="migrate"
				user={user}
			/>

		</div>
	);
};

export default ModalSelectPatientForMigrateExecutions;