import styled from "styled-components";
import { Colors } from "../../../styles/colors";

export const Container = styled.div`
	background-color: #ffffff;
	height: 508px;
	width: 565px;
	border-radius: 30px;
	display: flex;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
	position: relative;

	.close {
		width: 1.738rem;
		position: absolute;
		right: 2rem;
		top: 2rem;
		cursor: pointer;
	}

	.divText {
		display: flex;
		flex-direction: column;
		padding: 2rem;
	}
	
	.title {
		color: ${Colors.primary_blue_k_main};
		font-size: 20px;
		margin-top: 3rem;
		margin-bottom: 1.5rem;
		text-align: left;
	}
	.text {
		font-size: 20px;
		color: ${Colors.neutral_grey_k_70};
		line-height: 1.5;
		margin-bottom: 1.5rem;
	}
	.ContainerButtons {
		display: flex;
		width: 100%;
		height: 100px;
		margin-top: 1.5rem;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}
	.buttonDelete {
		display: flex;
		width: 206px;
		height: 55px;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		background-color: ${Colors.white};
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
		cursor: pointer;
		transition: all 0.3s linear;
		border: 2px solid ${Colors.danger}
	}
	.txtDelete {
		color: ${Colors.danger};
		font-weight: bold;
	}
	.buttonMigrate {
		display: flex;
		width: 206px;
		height: 55px;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		background-color: ${Colors.white};
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
		cursor: pointer;
		transition: all 0.3s linear;
		border: 2px solid ${Colors.primary_blue_k_main}
	}
	.txtMigrate {
		color: ${Colors.primary_blue_k_main};
		font-weight: bold;
	}

	.buttonDisabled {
		display: flex;
		width: 206px;
		height: 55px;
		justify-content: center;
		align-items: center;
		border-radius: 10px;
		background-color: ${Colors.white};
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
		cursor: pointer;
		transition: all 0.1s linear;
		border: 2px solid ${Colors.gray}
	}
	.txtDisabled {
		color: ${Colors.gray};
		font-weight: bold;
	}

`;

export const Close = styled.img`
	width: 1rem;
	position: absolute;
	right: 2.5rem;
	top: 2.5rem;
	cursor: pointer;
`;

export const Option = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;

	padding-top: 1rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid var(--Neutras-Grey-K-10, #dedfe3);
`;
export const Content = styled.div`
	width: 100%;
	height: 70%;
	display: flex;
	align-self: center;
	flex-direction: column;
	/* background-color: blue; */
`;
export const Footer = styled.div`
	display: flex;
	width: 100%;
	position: absolute;
	bottom: 0;
	justify-content: space-around;
	align-items: center;
	padding-bottom: 32px;
	/* background-color: blanchedalmond; */
`;

export const Button = styled.div<{borderColor: string, bgColor: string}>`
	width: 40%;
	display: flex;
	padding: 14px 32px;
	justify-content: center;
	align-items: center;
	border-radius:12px;
	border: 2px solid;
	cursor: pointer;
	border-color: ${({borderColor})=> borderColor ? borderColor : 'transparent' };
	background-color: ${({bgColor})=> bgColor ?  bgColor : 'transparent' };
`;

export const TextButton = styled.p<{color: string}>`
	font-family: 'Kanit-Regular';
	color: ${({color})=> color ?  color: 'white'};
	font-weight: 600;
	font-size: 20px;
`;

export const LoadComponent = styled.div`
	border: 2px solid rgba(0, 0, 0, 0.1);
  border-left-color: white;
  border-radius: 50%;
  width:30px; /* Tamanho do loader */
  height:30px;
  animation: spin 0.8s linear infinite;

	@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`;