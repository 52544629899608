import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { persistStorage } from "../../../utils/base_async_storage";

import ToastAlert from "../../../components/toast";
import Toogle from "../../../components/Toogle";
import { TextsProps } from "../../../database/txt.interface";
import { AppActions } from "../../../store/ducks/app";
import { ConfigsActions } from "../../../store/ducks/configs";
import { TypesLanguage } from "../../../utils/types";
import {
	CheckLanguage,
	ContainerExerciseOption,
	ContainerICon,
	ContainerIConExercise,
	ContainerLabel,
	ContainerLabels,
	ContainerLanguageOptions,
	ContainerTexts,
	ContainerToogle,
	ContainerToogles,
	Conteiner,
	ExerciseOptions,
	GeneralOptions,
	Header,
	StyledInput,
	LanguageOption,
	OptionConfigs,
	OptionExercise,
	OptionsGenereals,
	Round,
	TextExercise,
	TextGeneral,
	TextOption,
	ContainerInput,
	InputText,
	Content,
} from "./styles";
import { AmplitudeLog } from "../../../utils/amplitude";
import { ContainerGoBack } from "../Etiquetas/styles";

import history from "../../../routes/history";

import { ReactComponent as ArrowBack } from "../../../assets/kinologyIcons/svg/arrow_back.svg";
import Language from "../../../assets/kinologyIcons/svg/language.svg";
import { ReactComponent as Ampulheta } from "../../../assets/kinologyIcons/svg/hourglass_empty.svg";
import { ReactComponent as AlertaSonoro } from "../../../assets/kinologyIcons/svg/volume_up.svg";
import { ReactComponent as Fitness } from "../../../assets/kinologyIcons/svg/fitness_center.svg";
import { ReactComponent as TimerPause } from "../../../assets/kinologyIcons/svg/timer_pause.svg";
import { ReactComponent as Timer } from "../../../assets/kinologyIcons/svg/timer.svg";
import ReturnIcon from "../../../assets/icons/back_page.svg";

import { Colors } from "../../../styles/colors";
import Text from "../../../components/Texts/Text";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { H3Desk, P1Desk, P2Desk, P3Desk } from "../../../styles/styles_text";
import { ContainerDash, Icon, Return, Scroll } from "../../../styles/global";

const Configuracoes: React.FC = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(AppActions.set_open_barraSuperior("configurar"));
	}, []);

	const tempo_contracao_flag = useSelector(
		(state: any) => state.configs.tempo_contracao_flag
	);
	const contagem_regressiva = useSelector(
		(state: any) => state.configs.contagem_regressiva
	);
	const intervalo_descanso_flag = useSelector(
		(state: any) => state.configs.intervalo_descanso_flag
	);
	const tempo_contracao = useSelector(
		(state: any) => state.configs.tempo_contracao
	);
	const intervalo_descanso = useSelector(
		(state: any) => state.configs.intervalo_descanso
	);
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const alerta_sonoro = useSelector(
		(state: any) => state.configs.alerta_sonoro
	);
	const modo_demo = useSelector((state: any) => state.configs.modo_demo);
	const openMenu = useSelector((state: any) => state.app.openMenu);
	const language = useSelector((state: any) => state.configs.language);
	const config_funcional = useSelector(
		(state: any) => state.configs.config_funcional
	);

	const [selected, setSelected] = useState<string[]>(["gerais"]);
	const handleActiveClick = (active: string, listagem: boolean) => {
		const alreadySelected = selected.findIndex((item) => item === active);

		if (alreadySelected < 0) {
			setSelected([active]);
		}
	};

	const handleCheckLanguage = async (event: { target: { value: string } }) => {
		if (event.target.value === "pt-BR") {
			dispatch(ConfigsActions.set_language(TypesLanguage.PT));
			await persistStorage({
				res: TypesLanguage.PT,
				tag: "config_language",
			});
		} else if (event.target.value === "en-US") {
			dispatch(ConfigsActions.set_language(TypesLanguage.EN));
			await persistStorage({
				res: TypesLanguage.EN,
				tag: "config_language",
			});
		} else {
			dispatch(ConfigsActions.set_language(TypesLanguage.ES));
			await persistStorage({
				res: TypesLanguage.ES,
				tag: "config_language",
			});
		}
	};

	const isWritingContracao = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value !== "" && parseInt(e.target.value) > 0) {
			dispatch(ConfigsActions.set_tempo_contracao_timer(e.target.value));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_CONTRACAO_FLAG: tempo_contracao_flag,
					CONFIG_EXER_CONTRACAO: e.target.value,
				})
			);
		} else {
			dispatch(ConfigsActions.set_tempo_contracao_timer(20));
		}
	};

	const isWritingDescanso = (e: any) => {
		if (e.target.value !== "" && parseInt(e.target.value) > 0) {
			dispatch(ConfigsActions.set_intervalo_descanso_timer(e.target.value));
			dispatch(
				ConfigsActions.config_request_edit({
					CONFIG_EXER_DESCANSO_FLAG: intervalo_descanso_flag,
					CONFIG_EXER_DESCANSO: e.target.value,
				})
			);
		} else {
			dispatch(ConfigsActions.set_intervalo_descanso_timer(60));
		}
	};

	const is_open_toast = useSelector((state: any) => state.app.is_open_toast);

	const OptionsLanguage = [
		{
			title: "Português",
			value: "pt-BR",
			check: language == TypesLanguage.PT ? <CheckLanguage /> : "",
			Color:
				language == TypesLanguage.PT
					? Colors?.secondary_green_k_main
					: Colors?.primary_blue_k_main,
			fontWeigth: language == TypesLanguage.PT ? 700 : 500,
			action: async () => {
				dispatch(ConfigsActions.set_language(TypesLanguage.PT));
				await persistStorage({
					res: TypesLanguage.PT,
					tag: "config_language",
				});
			},
		},
		{
			title: "English",
			value: "en-US",
			check: language == TypesLanguage.EN ? <CheckLanguage /> : "",
			Color:
				language == TypesLanguage.EN
					? Colors?.secondary_green_k_main
					: Colors?.primary_blue_k_main,
			fontWeigth: language == TypesLanguage.EN ? 700 : 500,
			action: async () => {
				dispatch(ConfigsActions.set_language(TypesLanguage.EN));
				await persistStorage({
					res: TypesLanguage.EN,
					tag: "config_language",
				});
			},
		},
		{
			title: "Español",
			value: "es",
			check: language == TypesLanguage.ES ? <CheckLanguage /> : "",
			Color:
				language == TypesLanguage.ES
					? Colors?.secondary_green_k_main
					: Colors?.primary_blue_k_main,
			fontWeigth: language == TypesLanguage.ES ? 700 : 500,
			action: async () => {
				dispatch(ConfigsActions.set_language(TypesLanguage.ES));
				await persistStorage({
					res: TypesLanguage.ES,
					tag: "config_language",
				});
			},
		},
	];

	const OptionsExercise = [
		{
			title: current_language?.menu_lateral_exercicios_alerta,
			subTitle: current_language?.alerta_sonoro_legenda,
			icon: <AlertaSonoro />,
			toogle: () => <Toogle action={"ativarSonoro"} isCheck={alerta_sonoro} />,
		},
		{
			title: current_language?.menu_lateral_exercicio_contagem,
			subTitle: current_language?.menu_lateral_exercicio_contagem_legenda,
			icon: <Ampulheta />,
			toogle: () => (
				<Toogle action={"ativarContagem"} isCheck={contagem_regressiva} />
			),
		},
		{
			title: current_language?.config_exercise_functional,
			subTitle: current_language?.description_config_exercise_functional,
			icon: <Fitness />,
			toogle: () => (
				<Toogle action={"ativarFuncional"} isCheck={config_funcional} />
			),
		},
		{
			title: current_language?.menu_lateral_exercicios_intervalo,
			subTitle: current_language?.menu_lateral_exercicios_intervalo_legenda,
			icon: <TimerPause />,
			toogle: () => (
				<Toogle action={"ativarDescanco"} isCheck={intervalo_descanso_flag} />
			),
			inputAction: () => (
				<ContainerInput>
					<P3Desk style={{marginLeft: '3rem'}}>{current_language?.menu_tempo_segundos}</P3Desk>
					<StyledInput
						id="inputDescanco"
						disabled={intervalo_descanso_flag ? false : true}
						placeholder={intervalo_descanso.toString()}
						type="number"
						onBlur={(e) => isWritingDescanso(e)}
					/>
				</ContainerInput>
			),
		},
		{
			title: current_language?.contracao_tempo,
			subTitle: current_language?.menu_tempo_contracao_legenda,
			icon: <Timer />,
			toogle: () => (
				<Toogle action={"ativarContracao"} isCheck={tempo_contracao_flag} />
			),
			inputAction: () => (
				<ContainerInput>
					<P3Desk style={{marginLeft: '3rem'}}>{current_language?.menu_tempo_segundos}</P3Desk>
					<StyledInput
						id="inputContracao"
						disabled={tempo_contracao_flag ? false : true}
						placeholder={tempo_contracao.toString()}
						type="text"
						onBlur={(e) => isWritingContracao(e)}
					/>
				</ContainerInput>
			),
		},
	];

	return (
		<ContainerDash id="DivPrincipal">
			{is_open_toast && <ToastAlert />}

			<Return
				onClick={() => {
					dispatch(DashBoardActions.set_current_page("pacientes"));
					dispatch(
						AppActions.set_option_menu(
							false,
							current_language?.menu_lateral_minha_conta
						)
					);
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.menu_lateral_minha_conta}</P3Desk>
			</Return>

			<Scroll style={{ width: "60%" }}>
				<H3Desk style={{ marginTop: "1rem" }}>
					{" "}
					{current_language?.menu_lateral_config}
				</H3Desk>
				<OptionConfigs>
					<GeneralOptions
						onClick={() => {
							handleActiveClick("gerais", false);
							AmplitudeLog("configs_gerais");
						}}
						selected={selected[0]}
					>
						<TextGeneral>{current_language?.menu_lateral_geral}</TextGeneral>
					</GeneralOptions>
					<ExerciseOptions
						onClick={() => {
							handleActiveClick("exercicios", true);
							AmplitudeLog("configs_exercicios");
						}}
						selected={selected[0]}
					>
						<TextExercise>{current_language?.exercicios}</TextExercise>
					</ExerciseOptions>
				</OptionConfigs>

				{selected.includes("exercicios") && (
					<ContainerExerciseOption>
						{OptionsExercise?.map((item, index) => (
							<>
								<OptionExercise key={index}>
									<div
										style={{
											display: "flex",
											flexDirection: "row",
											alignItems: "center",
										}}
									>
										<ContainerIConExercise>{item?.icon}</ContainerIConExercise>
										<ContainerLabels>
											<P1Desk>
												<strong>{item?.title}</strong>
											</P1Desk>

											<P2Desk>{item?.subTitle}</P2Desk>
										</ContainerLabels>
									</div>
									<ContainerToogles>{item?.toogle()}</ContainerToogles>
								</OptionExercise>
								{item?.inputAction && item?.inputAction()}
							</>
						))}
					</ContainerExerciseOption>
				)}
				{selected.includes("gerais") && (
					<OptionsGenereals>
						<ContainerLabel>
							<ContainerTexts>
								<P1Desk>
									<Icon
										style={{
											width: "1.5rem",
											height: "1.5rem",
											marginRight: "0.5rem",
										}}
										src={Language}
									/>{" "}
									<strong>{current_language?.menu_lateral_language}</strong>
								</P1Desk>
								<br />
								<P2Desk>{current_language?.menu_linguagem_legenda}</P2Desk>
								<ContainerLanguageOptions>
									{OptionsLanguage?.map((item, index) => (
										<LanguageOption key={index} onClick={() => item?.action()}>
											<Round style={{ borderColor: item?.Color }}>
												{item?.check}
											</Round>
											<P2Desk style={{ fontWeight: item?.fontWeigth }}>
												{item?.title}
											</P2Desk>
										</LanguageOption>
									))}
								</ContainerLanguageOptions>
							</ContainerTexts>
						</ContainerLabel>
						<ContainerLabel>
							<ContainerTexts>
								<P1Desk>
									{" "}
									<Icon
										style={{
											width: "1.5rem",
											height: "1.5rem",
											marginRight: "0.5rem",
										}}
										src={Language}
									/>{" "}
									<strong>{current_language?.menu_lateral_demonstracao}</strong>
								</P1Desk>
								<br />
								<P2Desk>{current_language?.configs_demo_experiente}</P2Desk>

								<ContainerToogle>
									<Toogle action="demo" isCheck={modo_demo} />
								</ContainerToogle>
							</ContainerTexts>
						</ContainerLabel>
					</OptionsGenereals>
				)}
			</Scroll>
		</ContainerDash>
	);
};

export default memo(Configuracoes);
