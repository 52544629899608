/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DinoTriste from "../../../assets/icons/dinoTriste.svg";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";

import { TextsProps } from "../../../database/txt.interface";
import { ServicosProps } from "../../../interfaces/config.interface";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";

import { ConfigsActions } from "../../../store/ducks/configs";
import history from "../../../routes/history";
import {
	ContainerDash,
	HeaderProfile,
	Icon,
	ProfilePicture,
	Return,
} from "../../../styles/global";
import { P3Desk, S1Desk } from "../../../styles/styles_text";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import ExameFisicoAnamnese from "./ExameFisicoAnamnese";
import AvaliacaoAnamnese from "./AvaliacaoAnamnese";
import InformacoesPaciente from "../../Paciente/InformacoesPaciente/InformacoesPaciente";
import { RelatorioActions } from "../../../store/ducks/relatorios";
import PdfIcon from "../../../assets/kinologyIcons/svg/pdf.svg";
import { Colors } from "../../../styles/colors";

const RelatorioAnamnese: React.FC = () => {
	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const dispatch = useDispatch();
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);

	const patient: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const [permission, setPermission] = useState(true);

	const navigationUpgrade = () => {
		history.push("/upgrade");
	};

	useEffect(() => {
		const permissionAsymmetry: any = getPermission(
			config_servicos,
			TypesPermission.SERVICO_REL_ASSIMETRIA
		);
		if (!permissionAsymmetry) {
			setPermission(false);
			dispatch(ConfigsActions.set_title_modal_upgrade(true));
		} else {
			dispatch(ConfigsActions.set_title_modal_upgrade(false));
		}
	}, []);

	type EstadoPermitido = "exame_fisico" | "avaliacao";
	const [resultado_selecionado, set_resultado_selecionado] =
		useState<EstadoPermitido>("avaliacao");

	return (
		<ContainerDash>
			{permission ? (
				<>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("perfil_paciente"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.area_paciente}</P3Desk>
			</Return>
			<div
				style={{
					display: "flex",
					flexDirection: "row",
					alignItems: "center",
					marginTop: "1rem",
					marginBottom: "3rem",
					width: "60%",
					justifyContent: "space-between",
				}}
			>
				<div style={{ display: "flex", alignItems: "center" }}>
					<ProfilePicture
						src={patient?.USUARIO_FOTO ? patient?.USUARIO_FOTO : Perfil}
					/>
					<S1Desk style={{ marginLeft: "1rem" }}>
						{patient.USUARIO_NOME}
					</S1Desk>
				</div>
				<Icon
					style={{ width: "2rem", height: "2rem" }}
					onClick={() => {
						dispatch(DashBoardActions.set_current_page("emissao_relatorios"))
						dispatch(
							RelatorioActions.set_current_selected_reports(["ANAMNESE"])
						);
					}
					}
					src={PdfIcon}
				/>
			</div>

					<div style={{width: "60%"}}>
			
						<VerPerfil
							onClick={() =>
								dispatch(DashBoardActions.set_current_page("informacoes_paciente"))
							}
						>
							Ver perfil
						</VerPerfil>
					</div>
					<ContainerOptions>
						<Option
							onClick={() => set_resultado_selecionado("avaliacao")}
							style={
								resultado_selecionado == "avaliacao"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<div style={{ display: "flex", flexDirection: "column" }}>
								<text>Avaliação</text>
							</div>
						</Option>
						<Option
							onClick={() => set_resultado_selecionado("exame_fisico")}
							style={
								resultado_selecionado == "exame_fisico"
									? { borderBottom: "4px solid #00D084" }
									: {}
							}
						>
							<text>Exame Físico/Clínico</text>
						</Option>
					</ContainerOptions>

					<WhiteContainer>
						{resultado_selecionado == "exame_fisico" ? (
							<ExameFisicoAnamnese />
						) : resultado_selecionado == "avaliacao" ? (
							<AvaliacaoAnamnese />
						) : (
							<InformacoesPaciente />
						)}
					</WhiteContainer>
				</>
			) : (
				<div className="upgrade">
					<img src={DinoTriste} alt="" />
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel}</p>
					<p>{current_language?.modal_upgrade_funcionalidade_indisponivel2}</p>
					<button className="buttonUpgrade" onClick={navigationUpgrade}>
						{current_language?.menu_lateral_upgrade}
					</button>
				</div>
			)}
		</ContainerDash>
	);
};

const ContainerOptions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 60%;
`;

const Option = styled.text`
	color: var(--Azul-Azul-kinology, var(--Color, #000e4b));
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 1rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%;

	height: 4rem;
	border-bottom: 4px solid var(--Neutras-Grey-K-10, #dedfe3);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	width: 100%;
`;

const WhiteContainer = styled.div`
	background-color: white;
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem;
	position: relative;
	z-index: 2;

	overflow-y: scroll;
	overflow-x: hidden;

	::-webkit-scrollbar {
		width: 2px;
		height: 2px;
	}
	::-webkit-scrollbar-thumb {
		background: #e0e0e0;
		border-radius: 10px;
	}
	::-webkit-scrollbar-track {
		background: #ffffff;
		border-radius: 10px;
	}
`;

const VerPerfil = styled.text`
	color: var(--Neutras-Grey-K-80, #2a3048);
	text-align: center;
	font-family: "NotoSans-Regular";
	font-size: 0.8rem;
	font-style: normal;
	font-weight: 400;
	line-height: 150%; /* 1.5rem */
	text-decoration-line: underline;
`;

export default RelatorioAnamnese;

