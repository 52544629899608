import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import {
	ButtonSave,
	ContainerDash,
	DropDown,
	Icon,
	MenuOption,
} from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";
import Perfil from "../../../assets/icons/perfil.svg";
import { TextsProps } from "../../../database/txt.interface";
import { useForm } from "react-hook-form";
import { PacientesProps } from "../../../interfaces/pacientes.interface";
import { AmplitudeLog } from "../../../utils/amplitude";
import { AnotationActions } from "../../../store/ducks/anotacoes";
import { H2Desk, P3Desk, S1Desk } from "../../../styles/styles_text";
import MenuIcon from "../../../assets/icons/3points.svg";
import DeleteIcon from "../../../assets/icons/delete.svg";
import { AnotacaoProps } from "../../../interfaces/anotacoes.interface";
import { AppActions } from "../../../store/ducks/app";

const EditarAnotacao: React.FC = () => {
	const dispatch = useDispatch();
	const windowWidth = window.innerWidth;

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	const paciente: PacientesProps = useSelector(
		(state: any) => state.app.is_open_paciente
	);

	const anotacoes: AnotacaoProps = useSelector(
		(state: any) => state.anotations.current_anotation
	);

	const [isSearch, setIsSearch] = useState<boolean>(false);

	const [value, setvalue]: any = useState<any>(0);

	const [controler_menu, set_controler_menu] = useState(false);

	const isWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalue(e.target.value);
	};

	//Form
	const {
		register,
		handleSubmit,
		setValue,
		formState: { errors },
	} = useForm();

	const ModalCadastroClose = () => {
		setvalue(0);
		dispatch(DashBoardActions.set_current_page("listagem_anotacoes"));
	};

	const onSubmit = async (data: any) => {
		const edit_etiqueta = {
			ID_ANOTACAO: anotacoes.ID_ANOTACAO,
			ANOTACAO_TXT: data.text,
		};
		AmplitudeLog("anotacao_editada", { body: edit_etiqueta });
		dispatch(AnotationActions.edit_anotation_request(edit_etiqueta));
		ModalCadastroClose();
	};

	useEffect(() => {
		if (anotacoes.ANOTACAO_TXT) {
			setValue("text", anotacoes.ANOTACAO_TXT.replace(/\\n/gi, `${"\n"}`));
		}
	}, [paciente, anotacoes]);

	return (
		<ContainerDash>
			<Return
				onClick={() =>
					dispatch(DashBoardActions.set_current_page("listagem_anotacoes"))
				}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk>{current_language?.evolucao_paciente}</P3Desk>
			</Return>

			<Header>
				<DropDown active={controler_menu}>
					<div className="dropdown">
						<div
							style={{ display: "flex", alignItems: "center" }}
							onClick={(event) => {
								event.stopPropagation(),
									set_controler_menu(!controler_menu ? true : false);
							}}
						>
							<H2Desk style={{ marginRight: "1rem" }}>Editar Anotação</H2Desk>
							<Icon src={MenuIcon} />
						</div>

						<div className="dropdown-content" style={{ right: "82%" }}>
							<MenuOption
								onClick={() => {
									set_controler_menu(false);
									dispatch(AppActions.set_modal_apagar_anotacao(anotacoes));
								}}
							>
								<Icon style={{ marginRight: "1rem" }} src={DeleteIcon} />
								{current_language?.excluir}
							</MenuOption>
						</div>
					</div>
				</DropDown>

				<hr
					style={{
						border: "1px solid #DEDFE3",
						marginTop: "1rem",
						marginBottom: "1rem",
					}}
				/>
				<H2Desk>{current_language?.pag_inicial_avaliador}:</H2Desk>
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						marginTop: "1rem",
					}}
				>
					<ProfilePicture src={Perfil} />
					<S1Desk style={{ marginLeft: "1rem" }}>Lucas Mantovani</S1Desk>
				</div>
			</Header>
			<ContainerOptions onSubmit={handleSubmit(onSubmit)}>
				<H2Desk style={{ marginBottom: "1rem", marginTop: "1rem" }}>
					{current_language?.anote_evolucao_paciente}
				</H2Desk>
				<Textarea
					className="textArea"
					id="text"
					cols={30}
					rows={windowWidth < 1440 ? 5 : 10}
					onKeyUp={(e) => isWriting(e)}
					{...register("text", {
						required: `${current_language?.login_input_senha}`,
					})}
				></Textarea>

				<P3Desk style={{ textAlign: "end" }} className="limite">
					{value.length === undefined ? "0" : value.length}/1000
				</P3Desk>
				<ButtonSave active={true}>Salvar</ButtonSave>
			</ContainerOptions>
		</ContainerDash>
	);
};

export default EditarAnotacao;

const ContainerOptions = styled.form`
	display: flex;
	width: 60%;
	flex-direction: column;
`;

const Return = styled.div`
	display: flex;
	flex-direction: row;
	width: 60%;
	align-items: center;
	cursor: pointer;
`;

const ProfilePicture = styled.img`
	width: 2rem;
	height: 2rem;
	background-color: #ededed;
	border: 0;
	border-radius: 100%;
	box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.08);
	object-fit: cover;
`;

const Header = styled.div`
	display: flex;
	flex-direction: column;
	align-items: "flex-start";
	width: 60%;
	margin-top: 1rem;
`;

const Textarea = styled.textarea`
	border-radius: 0.75rem;
	border: 1px solid var(--Neutras-Grey-K-20, #c4c5cc);
	padding: 1rem;
	background-color: #fafafa;
	font-family: "NotoSans-Regular";
`;
