import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../../database/txt.interface";
import { FaturaProps } from "../../../interfaces/cartao.interface";
import { AppActions } from "../../../store/ducks/app";
import { FinancialActions } from "../../../store/ducks/financial";
import {
	Conteiner,
	Header,
	ContainerGoBack,
	CardInvoice,
	ContainerValueInvoice,
	ContainerIConValue,
	Content,
	BodyEdite,
	ButtonLogoutInadimplente,
	ContainerInput,
	Input,
	PatientsContainer,
	CardPatient,
	ContainerImage,
	UserName,
	ContentNameUserTag,
	Tag,
} from "./styles";
import { AuthActions } from "../../../store/ducks/auth";

import { ReactComponent as ArrowBack } from "../../../assets/kinologyIcons/svg/arrow_back.svg";
import { ReactComponent as Search } from "../../../assets/kinologyIcons/svg/search.svg";
import { ReactComponent as Patient } from "../../../assets/kinologyIcons/svg/patient.svg";

import Text from "../../../components/Texts/Text";
import { Colors } from "../../../styles/colors";
import history from "../../../routes/history";
import {
	PacientesProps,
	UserProps,
} from "../../../interfaces/pacientes.interface";
import { getPermission } from "../../../utils/funcs";
import { TypesPermission } from "../../../utils/types";
import moment from "moment";
import { ServicosProps } from "../../../interfaces/config.interface";
import { ExerciciosActions } from "../../../store/ducks/exer";
import { AmplitudeLog } from "../../../utils/amplitude";
import { DashBoardActions } from "../../../store/ducks/dashboard";
import { H3Desk, P3Desk } from "../../../styles/styles_text";
import { ContainerDash, Icon, Return, Scroll } from "../../../styles/global";
import ReturnIcon from "../../../assets/icons/back_page.svg";

enum TypeFatura {
	BOLETO = "Boleto",
	PENDING = "pending",
}

const ExportarDados: React.FC = () => {
	const dispatch = useDispatch();

	const [isSearch, setIsSearch] = useState<boolean>(false);
	const [value, setvalue] = useState<any>();
	const [palavra, setPalavra] = useState<any>();

	const pacientes = useSelector((state: any) => state.dashboard.pacientes);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);
	const title_export_data: boolean = useSelector(
		(status: any) => status.app.title_export_data
	);
	const config_servicos: ServicosProps[] = useSelector(
		(state: any) => state.configs.config_servicos
	);
	const execucao = useSelector(
		(state: any) => state.app.is_open_migracao.execucao
	);
	const language: any = useSelector((state: any) => state.configs.language);
	const user_logado: UserProps = useSelector((state: any) => state.auth.user);

	const IsWriting = (e: any) => {
		if (e.target.value !== "") {
			setIsSearch(true);
		} else {
			setIsSearch(false);
		}
		setvalue(e.target.value);
	};

	const MigrateOrExportData = (id_paciente: number) => {
		if (title_export_data) {
			if (
				getPermission(config_servicos, TypesPermission.SERVICO_CSV, dispatch)
			) {
				window.open(
					`${
						process.env.REACT_APP_BASE_URL
					}relatorios/exportar_csv.php?l=${language?.toLowerCase()}&t=${
						user_logado.token
					}&i=${id_paciente}`,
					"_blank"
				);
			}
		} else {
			const request = {
				txDataHora: moment(new Date()).format("YYYY-MM-DD HH:mm:ss").toString(),
				ID_EXECUCAO: [execucao],
				ID_USUARIO: id_paciente,
			};
			dispatch(ExerciciosActions.editar_execucao_request(request));
			dispatch(AppActions.set_modal_migracao(false, null));
		}
	};

	useEffect(() => {
		setPalavra(
			pacientes?.filter((paciente: PacientesProps) =>
				paciente?.USUARIO_NOME?.toLowerCase()?.includes(value?.toLowerCase())
			)
		);
	}, [value]);

	const data = [palavra, pacientes];

	return (
		<ContainerDash>
			<Return
				onClick={() => {
					dispatch(DashBoardActions.set_current_page("minha_conta"));
					dispatch(
						AppActions.set_option_menu(
							false,
							current_language?.menu_lateral_minha_conta
						)
					);
				}}
			>
				<Icon src={ReturnIcon} style={{ width: "0.875rem", height: "0.875rem" }} />
				<P3Desk style={{ color: Colors?.primary_blue_k_main }}>{current_language?.menu_lateral_minha_conta}</P3Desk>
			</Return>
			<div style={{ width: "60%" }}>
				<H3Desk>{current_language.menu_lateral_exportar}</H3Desk>
			</div>
			<ContainerInput style={{ width: "60%" }}>
				<Search color={Colors?.primary_blue_k_main} />
				<Input
					type="text"
					placeholder={current_language?.pag_inicial_busca}
					onKeyUp={(e) => IsWriting(e)}
				/>
			</ContainerInput>
			<Scroll style={{ width: "60%" }}>
				<PatientsContainer>
					{data[value ? 0 : 1]?.map((item: PacientesProps, index: number) => (
						<CardPatient
							key={index}
							onClick={() => {
								MigrateOrExportData(item?.ID_USUARIO),
									AmplitudeLog("download_dados");
							}}
						>
							<ContainerImage>
								<Patient color={Colors?.white} />
							</ContainerImage>
							<ContentNameUserTag>
								<UserName>{item.USUARIO_NOME}</UserName>
								<Tag>{current_language?.dash_paciente}</Tag>
							</ContentNameUserTag>
						</CardPatient>
					))}
				</PatientsContainer>
			</Scroll>
		</ContainerDash>
	);
};

export default memo(ExportarDados);
