import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TextsProps } from "../../database/txt.interface";
import { RelatorioActions } from "../../store/ducks/relatorios";
import { Container, ContainerAddSignature, ContainerIconEditSignature, ContainerSignature, EditSignatureContainer, Label, RemoveSignature } from "./styles";
import { AmplitudeLog } from "../../utils/amplitude";
import { AppActions } from "../../store/ducks/app";

import SetFoto from '../../assets/kinologyIcons/svg/setFoto.svg';
import HoverPhoto from '../../assets/kinologyIcons/svg/hoverSetFoto.svg';
import PressSetPhoto from '../../assets/kinologyIcons/svg/pressSetFoto.svg';
import {ReactComponent as SucessSetPhoto} from '../../assets/kinologyIcons/svg/SetFotoSucesso.svg';
import {ReactComponent as Trash} from '../../assets/kinologyIcons/svg/trash.svg';
import {ReactComponent as Edit} from '../../assets/kinologyIcons/svg/edit.svg';

import Text from '../../components/Texts/Text';
import { Colors } from "../../styles/colors";

const AdicionarLogo: React.FC = () => {
	const dispatch = useDispatch();
	const inputRef = useRef<HTMLInputElement>(null);
	const inputRefAddPhoto = useRef<HTMLInputElement>(null);
	
	const [img, setImg] = useState<any>();
	const [nomeImg, setNomeImg] = useState<any>();
	const [imageName, setImageName]= useState<string>('');
	const [image, setImage]= useState<string>(SetFoto);
	const [ clickEditPhoto, setClickEditPhoto] = useState(false);
	const [ clickAddPhoto, setClickAddPhoto] = useState(false);

	useEffect(() => {
        if (clickEditPhoto && inputRef.current) {
          inputRef.current.click();
          setClickEditPhoto(false); 
        }
    }, [clickEditPhoto]);

	useEffect(() => {
        if (clickAddPhoto && inputRefAddPhoto.current) {
			inputRefAddPhoto.current.click();
          setClickAddPhoto(false); 
        }
    }, [clickAddPhoto]);

	function getBase64(event: any) {
		const file = event.target.files[0];
		if (file !== undefined) {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = function () {
				setImg(reader.result);
				setNomeImg(event.target.files[0].name);
				dispatch(RelatorioActions.set_imagem_relatorio(reader.result));
			};
			reader.onerror = function (error) {
				console.error("Error: ", error);
			};
		}
	}

	const removePhoto = () => {
		setImg(undefined);
		dispatch(RelatorioActions.set_imagem_relatorio(""));
	};

	const image_relatorio = useSelector(
		(state: any) => state.relatorios.image_relatorio
	);

	const current_language: TextsProps = useSelector(
		(state: any) => state.configs.current_language
	);

	if (img == undefined) {
		setImg("");
	}
	const handleImageProfile = (imageName:string)=> {
		const typeImages: any = {
			SetPhoto: SetFoto,
			HoverPhoto: HoverPhoto,
			PressSetPhoto: PressSetPhoto,
		};
		setImage(typeImages[imageName])
		setImageName(imageName)
		
	};

	useEffect(() => {
		setImg(image_relatorio);
	}, [image_relatorio]);

	useEffect(() => {
		if (img === undefined) {
			setImg(undefined);
			dispatch(RelatorioActions.set_imagem_relatorio(""));
		}
	}, [img]);

	return (
		<Container className="DivInput">
			{img 
				?	
					<>
						<ContainerSignature>
							<img src={img} alt="" style={{width: '100%', height: '100%'}}  />
							<RemoveSignature onClick={removePhoto}>
								<Trash  color={Colors?.primary_blue_k_main} className="TrashSignature"/>
							</RemoveSignature>
							
						</ContainerSignature>
						<EditSignatureContainer
							onClick={()=>{ setClickEditPhoto(true), AmplitudeLog('logo_relatorio')}}
						>
							<input
								id="img"
								type="file"
								ref={inputRef}
								onChange={(event)=>getBase64(event)}
								style={{ display: "none" }}
								accept="image/*"
							/>
							<ContainerIconEditSignature>
								<Edit color={Colors?.primary_blue_k_main} className="EditIcon"/> 
							</ContainerIconEditSignature>
							<Text 
								title={current_language?.edit_image}
								fontFamily="NotoSans-Regular"
								fontSize={1.5}
								fontWeight={600}
								textColor={Colors?.primary_blue_k_main}
								marginLeft={12}
								cursor="pointer"
							/>
						</EditSignatureContainer>
					
					</>
				:
					<ContainerAddSignature
						imageName={imageName}
						img={img}
						onMouseEnter={()=>handleImageProfile('HoverPhoto')}
						onMouseLeave={()=>handleImageProfile('SetPhoto')}
						onMouseDown={()=> handleImageProfile('PressSetPhoto')}
						onClick={()=> setClickAddPhoto(true)}
					>
						<input
							id="img"
							type="file"
							ref={inputRefAddPhoto}
							onChange={(event)=>getBase64(event)}
							style={{ display: "none" }}
							accept="image/*"
						/>
						<Label className="labelForInput">
							<img src={image} className="imgForInput"/>
							<Text
								title={current_language?.adicionar_logomarca}
								fontFamily="NotoSans-Regular" 
								fontSize={1.25}
								textColor={Colors.primary_blue_k_main}
								marginTop={8}
							/>
						</Label>
					</ContainerAddSignature>
			}
		</Container>
	);
};

export default AdicionarLogo;
