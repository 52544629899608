import React, { useState } from 'react';
import { DropDown, MenuOption } from "../../styles/global";
import {
	Icon,
} from "../../styles/styles_input_pesquisas";
import MenuIcon from "../../assets/icons/3points.svg";
import { useDispatch, useSelector } from 'react-redux';
import { TextsProps } from '../../database/txt.interface';
import { P2Desk } from '../../styles/styles_text';
import { RelatorioActions } from '../../store/ducks/relatorios';

interface Props {
    text: string,
    icon: any,
    exercise?: any,
    setIsVisible?: (status:boolean)=>void
}

const DropDownButton: React.FC<Props> = ({
    text,
    icon,
    exercise,
    setIsVisible
}) => {
	const [controler_menu, set_controler_menu] = useState(false);
    
    const dispatch = useDispatch();

    return (
        <DropDown active={controler_menu} style={{ marginLeft: "1rem" }}>
            <div className="dropdown">
                <Icon
                    src={MenuIcon}
                    onClick={(event) => {
                        event.stopPropagation(),
                            set_controler_menu(!controler_menu ? true : false);
                    }}
                />
                <div className="dropdown-content">
                    <MenuOption
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}
                        onClick={() => {
                            if(exercise) dispatch(RelatorioActions.set_options_for_deleted_or_migrate(exercise))
                            setIsVisible?.(true);
                            set_controler_menu(false);
                        }}
                    >
                        <Icon style={{ width: "2rem", height: "2rem" }} src={icon} /> 
                        <P2Desk>
                        {text}
                    </P2Desk>
                    </MenuOption>
                </div>
            </div>
        </DropDown>
    )
}

export default DropDownButton;